// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { loadMFE, registeredMFEs } from '../../../utils/MFEUtils'
import { API_BASE_URL, ALLOW_WITH_CREDENTIALS } from '../../../constants/envConstants'
import { openGenericErrorModal } from '../../../utils/modalUtils'
import { dispatch } from '../../../store'
import { postPolling } from '../../../actions/postPolling'
import { setCalculationActive, setCalculationComplete, setActiveWidgetAsTopmost } from '../../../actions/app'
import {
  IMPORT_FROM_SPACE_SCHEDULE_WIDGET_MFENAME,
  IMPORT_FROM_SPACE_SCHEDULE_WIDGET_COMPONENTNAME
} from '../../../constants/MFEStateConstants'
import { setMFEStates } from '../../../actions/MFEStates'

export const importFromSpaceScheduleWidgetMFERootId = IMPORT_FROM_SPACE_SCHEDULE_WIDGET_MFENAME

const ImportFromAnotherEstimateWidgetMFEContainer = (): null => {
  const {
    realEstateId,
    accessToken,
    haahtelaApiUserToken,
    userId,
    languageCode,
    selectedAccountId,
    activeEstimate: activeSpacesEstimate,
    buildingId,
    widgetOrder,
    MFEStates
  } = useSelector(({
    activeEstimate,
    app,
    user,
    MFEStates: MFEStatesStore
  }: TVDReduxStore): {|
    languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
    selectedAccountId: $PropertyType<TVDApplicationStore, 'selectedAccountId'>,
    accessToken: $PropertyType<TVDUserAuthorization, 'accessToken'>,
    haahtelaApiUserToken: $PropertyType<TVDUserClaims, 'haahtelaApiUserToken'>,
    userId: $PropertyType<TVDUserClaims, 'userId'>,
    activeEstimate: TVDActiveEstimateStore,
    realEstateId: $PropertyType<TVDApplicationStore, 'realEstateId'>,
    buildingId: $PropertyType<TVDApplicationStore, 'buildingId'>,
    widgetOrder: $PropertyType<TVDApplicationStore, 'widgetOrder'>,
    MFEStates: TVDMFEStatesStore,
  |} => ({
    languageCode: app.languageCode,
    selectedAccountId: app.selectedAccountId,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    activeEstimate,
    realEstateId: app.realEstateId,
    buildingId: app.buildingId,
    widgetOrder: app.widgetOrder,
    MFEStates: MFEStatesStore,
  }))

  const estimateId = activeSpacesEstimate?.id
  const MFEState = MFEStates[IMPORT_FROM_SPACE_SCHEDULE_WIDGET_MFENAME] || {}

  useEffect(() => {
    loadMFE('spaces', 'spaces-api-module')
  }, [])

  const updatePostPollingTS = () => {
    dispatch(postPolling())
  }

  const dispatchSetCalculationActive = () => {
    dispatch(setCalculationActive())
  }

  const dispatchSetCalculationComplete = () => {
    dispatch(setCalculationComplete())
  }

  const setIsWidgetOpen = () => {
    dispatch(setMFEStates(
      IMPORT_FROM_SPACE_SCHEDULE_WIDGET_MFENAME,
      false,
      IMPORT_FROM_SPACE_SCHEDULE_WIDGET_COMPONENTNAME,
      null
    ))
  }

  const registeredSpacesAPIModule = registeredMFEs['spaces-api-module']
  useEffect(() => {
    if (
      languageCode &&
      accessToken &&
      haahtelaApiUserToken &&
      userId &&
      selectedAccountId &&
      estimateId &&
      realEstateId &&
      setIsWidgetOpen
    ) {
      if (
        registeredSpacesAPIModule &&
        registeredSpacesAPIModule.run &&
        !!document.getElementById(importFromSpaceScheduleWidgetMFERootId)
      ) {
        const setWidgetAsTopmost = () => {
          if (widgetOrder[widgetOrder.length - 1] !== importFromSpaceScheduleWidgetMFERootId) {
            dispatch(setActiveWidgetAsTopmost(importFromSpaceScheduleWidgetMFERootId))
          }
        }
        registeredSpacesAPIModule.run(
          'renderImportFromSpaceScheduleWidget',
          [
            importFromSpaceScheduleWidgetMFERootId,
            {
              APIBaseURL: API_BASE_URL,
              languageCode,
              accessToken,
              haahtelaApiUserToken,
              userId,
              selectedAccountId,
              openGenericErrorModal,
              ALLOW_WITH_CREDENTIALS,
              realEstateId,
              setIsWidgetOpen,
              isWidgetOpen: MFEState.isOpen,
              estimateId,
              updatePostPollingTS,
              buildingId,
              setCalculationActive: dispatchSetCalculationActive,
              setCalculationComplete: dispatchSetCalculationComplete,
              setWidgetAsTopmost,
              widgetOrder
            }
          ]
        )
      }
    }
  }, [
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    selectedAccountId,
    registeredSpacesAPIModule,
    estimateId,
    realEstateId,
    setIsWidgetOpen,
    buildingId,
    widgetOrder
  ])
  return null
}

export default ImportFromAnotherEstimateWidgetMFEContainer

