// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import * as colors from '../../node_modules/frontend-assets/src/theme/colors'

export const ELEMENTS = 'ELEMENTS'
export const SPACES = 'SPACES'
export const WOP = 'WOP'
export const LOCAN = 'LOCAN'
export const ACCOUNTS = 'ACCOUNTS'
export const REAL_ESTATES = 'REAL_ESTATES'
export const USERS = 'USERS'
export const ADD_BUILDING = 'ADD_BUILDING'
export const ADD_CALCULATION = 'ADD_CALCULATION'
export const FUNCTIONS = 'FUNCTIONS'
export const AREA = 'AREA'
export const PRODUCTION_DIVISION = 'PRODUCTION_DIVISION'
export const REAL_ESTATE_MANAGEMENT = 'REAL_ESTATE_MANAGEMENT'
export const INSTRUCTIONS = 'INSTRUCTIONS'
export const LICENSE = 'LICENSE'
export const BUILDING = 'BUILDING'
export const CALCULATION = 'CALCULATION'
export const PROFITABILITY = 'PROFITABILITY'
export const PROCUREMENT_ELEMENTS = 'PROCUREMENT_ELEMENTS'
export const PROCUREMENT_ELEMENTS_NEW = 'PROCUREMENT_ELEMENTS_NEW'
export const RENOVATION_PROGRAM = 'RENOVATION_PROGRAM'
export const USAGE_AND_MAINTENANCE = 'USAGE_AND_MAINTENANCE'
export const SCENARIO_USE = 'SCENARIO_USE'
export const COST_TRACKING = 'COST_TRACKING'

export const spacesDataChildKeys = [
  'functions',
  'spacegroups',
  'spaces',
  'properties'
]

export const designModelSliderColors = {
  LevelOfConstructedGroundSurfaceM: colors.designModelGreen,
  LevelOfLoadBearingSoilM: colors.dark80,
  LevelOfGroundwaterM: colors.designModelBlue,
  LevelOfOriginalGroundSurfaceM: colors.yellow
}

export const designModelSliderColorsDisabled = {
  LevelOfConstructedGroundSurfaceM: colors.gray80,
  LevelOfLoadBearingSoilM: colors.gray80,
  LevelOfGroundwaterM: colors.gray80,
  LevelOfOriginalGroundSurfaceM: colors.gray80
}

export const spacesUrl = '/tilat'
export const elementsUrl = '/rakennusosat'
export const wopUrl = '/wop'
export const accountUrl = '/account'
export const usersUrl = '/user'
export const licenseUrl = '/license'
export const buildingUrl = '/building'
export const calculationUrl = '/calculation'
export const locanUrl = '/location-analysis'
export const profitabilityUrl = '/profitability'
export const renovationProgramUrl = '/renovation-program'
export const summaryUrl = '/summary'
export const procurementElementsUrl = '/procurement-elements'
export const usageAndMaintenanceUrl = '/usage-and-maintenance'
export const scenarioNewConstructionUrl = '/scenario-use'
export const scenarioRepairConstructionUrl = '/scenario-use-renovation'
export const procurementElementsNewUrl = '/procurement-elements-new'
export const costTrackingUrl = '/cost-tracking'

export const PROPERTIES_TAB = 'properties'
export const EQUIPMENTS_TAB = 'equipments'
export const SURFACES_TAB = 'surfaces'

export const RENOVATION_SPACES_TAB = 'RENOVATION_SPACES'
export const RENOVATION_SPACEGROUPS_TAB = 'RENOVATION_SPACEGROUPS'
export const RENOVATION_BUILDING_TAB = 'RENOVATION_BUILDING'
export const RENOVATION_SITE_TAB = 'RENOVATION_SITE'

export const SETTINGS_USERS_TAB = 'SETTINGS_USERS'
export const SETTINGS_BUILDING_TAB = 'SETTINGS_BUILDING'
export const SETTINGS_ESTIMATES_TAB = 'SETTINGS_ESTIMATES'

export const moduleIcons = {
  [SPACES]: '/assets/images/spaces/spaces_icon_small.svg',
  [ELEMENTS]: '/assets/images/buildingElements/building_elements_icon_small.svg',
  [WOP]: '/assets/images/wop/wop_icon_small.svg',
  [LOCAN]: '/assets/images/landing/Maa_alue.svg'
  // TODO: get profitability svg
  // [PROFITABILITY]: ''
}

// Couldnt figure out better name, so this should be changed once common naming factor is found
export const firstLevelWidgets = [
  'FUNTIONALSECTOR',
  'FUNCTIONS',
  'SPACEGROUP',
  'SPACE',
  'PRICEITEM',
  'ASSEMBLY',
  'MULTI_EDIT_SPACE_GROUP_WIDGET',
  'MULTI_EDIT_SPACE_WIDGET',
  'IMPORT_FROM_WOP_CALCULATION',
  'CREATE_FROM_REGISTRY',
  'CREATE_OTHER_SPACES',
  'CREATE_CIRCULATION',
  'CREATE_FACILITY_SERVICES',
  'CREATE_TECHNICAL_BUILDING_SERVICES',
  'CREATE_PROTECTED_CIRCULATION',
  'CALCULATION_PROPERTIES',
  'PLANNING_MODEL',
  'BUILDING_ELEMENTS_SCHEDULE',
  'IMPORT_FROM_CALCULATION',
  'CREATE_FROM_PRICING',
  'BUILDING_ELEMENTS_TASK',
  'CREATE_ACTIVITY',
  'GROUPING_VIEW',
  'WOP_SPACE_SCHEDULE',
  'ACTIVITY_REGISTRY_WIDGET',
  'PROCESSES_AND_ACTIVITIES',
  'BUILDING_SETTINGS',
  'RENOVATION'
]

export const ESTIMATE_TYPE_SPACE = 'space'
export const ESTIMATE_TYPE_BUILDING_ELEMENTS = 'buildingElements'
export const ESTIMATE_TYPE_WOP = 'wop'
export const ESTIMATE_TYPE_LOCAN = 'locan'

export const WOP_ITEM_PREFIX = 'wop-item-'


export const appIds = {
  none: 0,
  [SPACES]: 1,
  [ELEMENTS]: 2,
  [WOP]: 3,
  [LOCAN]: 4,
  [PROFITABILITY]: 5,
  [PROCUREMENT_ELEMENTS]: 6,
  [RENOVATION_PROGRAM]: 7,
  [FUNCTIONS]: FUNCTIONS,
  [USERS]: USERS,
  [ACCOUNTS]: ACCOUNTS,
  [LICENSE]: LICENSE,
  [BUILDING]: BUILDING,
  [CALCULATION]: CALCULATION,
  [USAGE_AND_MAINTENANCE]: 8,
  [COST_TRACKING]: 10
}

export const applications = [
  {
    img: '/assets/images/landing/WOP®.svg',
    name: WOP,
    testid: 'link-WOP',
    url: wopUrl,
    id: appIds[WOP],
    translationKey: 'apps._WOP_'
  },
  {
    img: '/assets/images/landing/Rakennus.svg',
    name: ADD_BUILDING,
    testid: 'link-users',
    url: '',
  },
  {
    img: '/assets/images/landing/Laskelma.svg',
    name: ADD_CALCULATION,
    testid: 'link-users',
    url: '',
  },
  {
    img: '/assets/images/landing/Tilat.svg',
    name: SPACES,
    testid: 'link-spaces',
    url: spacesUrl,
    id: appIds[SPACES],
    translationKey: 'apps._SPACES_'
  },
  {
    img: '/assets/images/landing/Tili.svg',
    name: ACCOUNTS,
    testid: 'link-account',
    url: accountUrl,
    id: appIds[ACCOUNTS]
  },
  {
    img: '/assets/images/landing/Kayttajat.svg',
    name: USERS,
    testid: 'link-users',
    url: usersUrl,
    id: appIds[USERS]
  },
  {
    img: '/assets/images/landing/Rakennusosat.svg',
    name: ELEMENTS,
    testid: 'link-building-elements',
    url: elementsUrl,
    id: appIds[ELEMENTS],
    translationKey: 'apps._ELEMENTS_'
  },
  {
    img: '/assets/images/landing/Kayttotehtavat.svg',
    name: FUNCTIONS,
    testid: 'link-functions',
    url: '/no-url-yet',
    id: appIds[FUNCTIONS]
  },
  {
    img: '/assets/images/landing/Maa_alue.svg',
    name: AREA,
    testid: 'link-area',
    url: '/no-url-yet',
    id: appIds[AREA]
  },
  {
    img: '/assets/images/landing/Tuotantojako.svg',
    name: PRODUCTION_DIVISION,
    testid: 'link-production-division',
    url: '/no-url-yet',
    id: appIds[PRODUCTION_DIVISION]
  },
  {
    img: '/assets/images/landing/Kiinteiston_arviointi.svg',
    name: REAL_ESTATE_MANAGEMENT,
    testid: 'link-real-estate-management',
    url: '/no-url-yet',
    id: appIds[REAL_ESTATE_MANAGEMENT]
  },
  {
    img: '/assets/images/landing/Lisenssi.svg',
    name: LICENSE,
    testid: 'link-license',
    url: licenseUrl,
    id: appIds[LICENSE]
  },
  {
    img: '/assets/images/landing/Rakennus.svg',
    name: BUILDING,
    testid: 'link-building',
    url: buildingUrl,
    id: appIds[BUILDING]
  },
  {
    img: '/assets/images/landing/Laskelma.svg',
    name: CALCULATION,
    testid: 'link-calculation',
    url: calculationUrl,
    id: appIds[CALCULATION]
  },
  {
    img: '/assets/images/landing/locan.svg',
    name: LOCAN,
    url: locanUrl,
    id: appIds[LOCAN],
    translationKey: 'apps._LOCAN_'
  },
  {
    name: PROFITABILITY,
    testid: 'link-profitability',
    url: profitabilityUrl,
    id: appIds[PROFITABILITY],
    translationKey: 'apps._PROFITABILITY_'
  },
  {
    name: RENOVATION_PROGRAM,
    testid: 'link-renovation-program',
    url: renovationProgramUrl,
    id: appIds[RENOVATION_PROGRAM],
    translationKey: 'apps._RENOVATION_PROGRAM_'
  },
  {
    name: USAGE_AND_MAINTENANCE,
    testid: 'link-usage-and-maintenance-program',
    url: usageAndMaintenanceUrl,
    id: appIds[USAGE_AND_MAINTENANCE],
    translationKey: 'UMA', // TODO: add translation for UMA
  },
  {
    name: COST_TRACKING,
    testid: 'link-cost-tracking',
    url: costTrackingUrl,
    id: appIds[COST_TRACKING],
    translationKey: 'apps._COST_TRACKING_',
  }
]

export const matrixListHeaderHeight = 100
export const matrixListHeaderUnderlayHeight = matrixListHeaderHeight - 2
