// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { loadMFE, registeredMFEs } from '../../../utils/MFEUtils'
import { API_BASE_URL, ALLOW_WITH_CREDENTIALS, FEATURE_SET } from '../../../constants/envConstants'
import { openGenericErrorModal } from '../../../utils/modalUtils'
import { dispatch } from '../../../store'
import { setCalculationActive, setCalculationComplete, setWidgetActive, setActiveWidgetAsTopmost } from '../../../actions/app'
import { postPolling } from '../../../actions/postPolling'
import { enableAllWidgets } from '../../../actions/widgets'
import { defaultLocation } from '../../../middlewares/widgetLocationMiddleware'
import { setMFEStates } from '../../../actions/MFEStates'
import {
  IMPORT_FROM_BE_ESTIMATE_WIDGET_MFENAME,
  IMPORT_FROM_BE_ESTIMATE_WIDGET_COMPONENTNAME
} from '../../../constants/MFEStateConstants'

export const importFromBEEstimateMFERootId = IMPORT_FROM_BE_ESTIMATE_WIDGET_MFENAME

const ImportFromBEEstimateWidgetMFEContainer = (): null => {
  const moduleOnUnmountRef = useRef<$PropertyType<TVDMFEFunctionality, 'onUnmount'>>()
  const {
    accessToken,
    haahtelaApiUserToken,
    userId,
    languageCode,
    selectedAccountId,
    activeRealEstate,
    activeEstimate: activeBuildingElementsEstimate,
    postPollingTS,
    activeEdit,
    activeCalculation,
    isEstimateLockedToCurrentUser,
    widgetOrder,
    MFEStates,
    application,
    realEstateId
  } = useSelector(({
    activeEstimate,
    app,
    user,
    activeRealEstate: activeRealEstateStore,
    MFEStates: MFEStatesStore
  }: TVDReduxStore): {|
    application: $PropertyType<TVDApplicationStore, 'application'>,
    languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
    selectedAccountId: $PropertyType<TVDApplicationStore, 'selectedAccountId'>,
    accessToken: $PropertyType<TVDUserAuthorization, 'accessToken'>,
    haahtelaApiUserToken: $PropertyType<TVDUserClaims, 'haahtelaApiUserToken'>,
    userId: $PropertyType<TVDUserClaims, 'userId'>,
    activeEstimate: TVDActiveEstimateStore,
    postPollingTS: $PropertyType<TVDApplicationStore, 'postPollingTS'>,
    activeEdit: $PropertyType<TVDApplicationStore, 'activeEdit'>,
    activeCalculation: $PropertyType<TVDApplicationStore, 'activeCalculation'>,
    isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>,
    activeRealEstate: TVDActiveRealEstateStore,
    widgetOrder: $PropertyType<TVDApplicationStore, 'widgetOrder'>,
    MFEStates: TVDMFEStatesStore,
    realEstateId: $PropertyType<TVDApplicationStore, 'realEstateId'>,
  |} => ({
    application: app.application,
    languageCode: app.languageCode,
    selectedAccountId: app.selectedAccountId,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    activeEstimate,
    postPollingTS: app.postPollingTS,
    activeEdit: app.activeEdit,
    activeCalculation: app.activeCalculation,
    isEstimateLockedToCurrentUser: app.isEstimateLockedToCurrentUser,
    activeRealEstate: activeRealEstateStore,
    widgetOrder: app.widgetOrder,
    MFEStates: MFEStatesStore,
    realEstateId: app.realEstateId,
  }))

  useEffect(() => () => {
    if (moduleOnUnmountRef.current) {
      moduleOnUnmountRef.current()
    }
  }, [application])

  const MFEState = MFEStates[IMPORT_FROM_BE_ESTIMATE_WIDGET_MFENAME]
  const closeWidget = () => {
    dispatch(setMFEStates(
      IMPORT_FROM_BE_ESTIMATE_WIDGET_MFENAME,
      false,
      IMPORT_FROM_BE_ESTIMATE_WIDGET_COMPONENTNAME,
      null
    ))
  }

  const updatePostPollingTS = () => {
    dispatch(postPolling())
  }

  const dispatchSetCalculationActive = () => {
    dispatch(setCalculationActive())
  }

  const dispatchSetCalculationComplete = () => {
    dispatch(setCalculationComplete())
  }

  const dispatchSetWidgetActive = (active: boolean) => {
    if (active) {
      dispatch(setWidgetActive(importFromBEEstimateMFERootId))
    } else {
      dispatch(enableAllWidgets())
    }
  }

  useEffect(() => () => {
    if (moduleOnUnmountRef.current) {
      moduleOnUnmountRef.current()
    }
  }, [application])

  const registeredBuildingElementsAPIModule = registeredMFEs['building-elements-api-module']

  useEffect(() => {
    if (
      languageCode &&
      accessToken &&
      haahtelaApiUserToken &&
      userId &&
      selectedAccountId &&
      activeRealEstate &&
      activeBuildingElementsEstimate &&
      realEstateId
    ) {
      const render = (module: TVDMFEFunctionality): void => {
        const moduleRun = module.run || null
        if (moduleRun) {
          const setWidgetAsTopmost = () => {
            if (widgetOrder[widgetOrder.length - 1] !== importFromBEEstimateMFERootId) {
              dispatch(setActiveWidgetAsTopmost(importFromBEEstimateMFERootId))
            }
          }
          moduleOnUnmountRef.current = module.onUnmount
          moduleRun(
            'renderImportFromBEEstimateWidget',
            [
              importFromBEEstimateMFERootId,
              {
                APIBaseURL: API_BASE_URL,
                languageCode,
                accessToken,
                haahtelaApiUserToken,
                userId,
                selectedAccountId,
                ALLOW_WITH_CREDENTIALS,
                openGenericErrorModal,
                activeRealEstate,
                isWidgetOpen: MFEState.isOpen,
                closeWidget,
                setWidgetAsTopmost,
                widgetOrder,
                defaultLocation,
                isEstimateLockedToCurrentUser,
                estimateId: activeBuildingElementsEstimate.id,
                postPollingTS,
                updatePostPollingTS,
                activeEdit,
                activeCalculation,
                setCalculationActive: dispatchSetCalculationActive,
                setCalculationComplete: dispatchSetCalculationComplete,
                setIsWidgetActive: dispatchSetWidgetActive,
                activeEstimate: activeBuildingElementsEstimate,
                FEATURE_SET,
                realEstateId,
              }
            ]
          )
        }
      }
      if (registeredBuildingElementsAPIModule) {
        render(registeredBuildingElementsAPIModule)
      } else {
        loadMFE('building_elements', 'building-elements-api-module', (module: TVDMFEFunctionality): void => {
          render(module)
        })
      }
    }
  }, [
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    selectedAccountId,
    activeBuildingElementsEstimate,
    activeRealEstate,
    MFEState.isOpen,
    widgetOrder,
    postPollingTS,
    activeEdit,
    activeCalculation,
    registeredBuildingElementsAPIModule,
    isEstimateLockedToCurrentUser,
    realEstateId,
  ])
  return null
}

export default ImportFromBEEstimateWidgetMFEContainer
