// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import { isEmpty } from 'lodash'
import { typographyClasses, colors } from 'frontend-assets'
import { TextButton } from 'frontend-components'

import { type TextButtonProps } from '../../../common/TextButton/TextButton'
import HierarchicalListContainer from '../../HierarchicalListContainer/HierarchicalListContainer'
import ActivityLinkIcon from '../../../common/ActivityLinkIcon/ActivityLinkIcon'
import Message from '../../../common/Messages/Message'

import { WOP_SPACE_SCHEDULE, PROCESSES_AND_ACTIVITIES, SPACE,
  ACTIVITY_STRUCTURE, FUNCTION, FUNCTIONALSECTOR } from '../../../../constants/contentTypes'
import { combineStyleClassNames } from '../../../../utils/styleUtils'
import { openContentWidget } from '../../../../actions/widgets'
import { filterWopSpaceScheduleByActivities, clearActivityFilters, setActivityFilter } from '../../../../actions/list'
import DescriptionCell from '../../../common/lists/common/DescriptionCell/DescriptionCell'
import {
  getSpaceScheduleColumnsWithEstimateIdRequest,
  getSpaceScheduleWithEstimateIdRequest,
  getSpaceScheduleValuesWithEstimateIdRequest
} from '../../../../utils/generated-api-requests/wop'
import { getLatestListUpdateTime } from '../../../../utils/listUtils'
// $FlowFixMe
import { ReactComponent as SVGIconAlertSuccessFilled }
  from '../../../../../node_modules/frontend-assets/static/assets/images/icons/Alert Success filled.svg'
// $FlowFixMe
import { ReactComponent as SVGIconAlertInfoOutlined }
  from '../../../../../node_modules/frontend-assets/static/assets/images/icons/Alert Info outlined.svg'
  // $FlowFixMe
import { ReactComponent as SVGIconDataTableGraph } from '../../../../../node_modules/frontend-assets/static/assets/images/icons/Data Table Graph.svg'

const { h4, bodySmall, bodySmallSemiBoldUnderline } = typographyClasses
const { dark80, dark120, primary100 } = colors

const styles = ({ palette }: TVDTheme): Object => ({
  listContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  },
  updateButton: {
    display: 'flex',
  },
  wopScheduleHeaderText: {
    ...h4,
    color: dark80,
    padding: '32px 24px 24px 32px'
  },
  statusIndicator: {
    ...bodySmall,
    marginLeft: '28px',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    color: dark120
  },
  buttonContainer: {
    display: 'flex',
    padding: '20px 0 8px 32px'
  },
  wrappedCellDescription: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '5px'
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'auto',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 26,
    height: 26,
    color: palette.dark60,
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: palette.gray40,
      borderRadius: '50%',
    },
    '&:active': {
      background: palette.primary60,
      '& path': {
        fill: colors.white
      }
    }
  },
  errorIcon: {
    fontSize: '18px',
    marginRight: '8px'
  },
  messageWrapper: {
    display: 'flex',
    padding: '6px',
    height: '60px'
  },
  messagesContentWrapper: {
    display: 'flex',
    flexWrap: 'nowrap'
  },
  clearFiltersLink: {
    ...bodySmallSemiBoldUnderline,
    color: primary100,
    cursor: 'pointer',
    textDecoration: 'underline',
    paddingLeft: '5px'
  },
})

type DispatchProps = {|
  dispatchOpenContentWidget: (TVDOpenContentWidgetArguments) => void, // open widget action
  dispatchClearActivityFilters: () => void, // clear activityFilters
  dispatchFilterWopSpaceScheduleByActivities: () => void, // filter listItems by activity
  dispatchSetActivityFilter: (row: TVDWOPListItem) => void, // set activity filter
|}

type ReceivedProps = {|
  widgetId: string, // given by Widget to Content and then passed to this component - the Store id in widgets
  listStoreId: string, // unique id for the HLC instance in "list" ReduxStore
  grouping?: boolean, // flag to indicate if spaceSchedule is displayed in context of GROUPING_VIEW widget to have different button layout
  watchListStoreIds: Array<string>, // ids of lists we are watching for changes from listLastUpdated info
  showTitleText: boolean // flag to indicate if title text is displayed or not
|}

type MappedProps = {|
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // if the current wop estimate is locked to the user
  activeFilterSource: Object, // filter and marker for wopActivitySchedule/wopSpaceSchedule filtering source list
  activityFilter: Object, // filter and marker for wopActivitySchedule/wopSpaceSchedule filtered list
  latestWatchListUpdateTime: number | typeof undefined, // the time in milliseconds when one of the lists being watched has changed
  languageCode: string, // current language code
|}

type HOCProps = {|
  classes: Object, // withStyles classes object
  t: Function, // translate function
|}

type Props = {|
  ...DispatchProps,
  ...MappedProps,
  ...ReceivedProps,
  ...HOCProps
|}

type State = {|
  isListOutdated: boolean, // if list is up to date with the latest changes of activity structure
  isLoading: boolean, // if the component has a HTTP request pending
|}

export class WopSpaceSchedule extends Component<Props, State> {
  static defaultProps = {
    grouping: false,
    activityFilter: {},
    activeFilterSource: {},
    showTitleText: false
  }

  state = {
    isLoading: false,
    isListOutdated: false,
  }
  componentDidMount() {
    this.getSpaceSchedule()
  }

  componentDidUpdate(prevProps: Props) {
    const {
      dispatchFilterWopSpaceScheduleByActivities,
      activityFilter,
      latestWatchListUpdateTime
    } = this.props

    if (
      typeof prevProps.latestWatchListUpdateTime !== 'undefined' &&
     (prevProps.latestWatchListUpdateTime !== latestWatchListUpdateTime)
    ) {
      this.setState({ isListOutdated: true })
    }

    if (this.listHasActivityFilter() && (prevProps.activityFilter.id !== activityFilter.id)) {
      dispatchFilterWopSpaceScheduleByActivities()
    }

    if (prevProps.languageCode !== this.props.languageCode) {
      this.getSpaceSchedule()
    }
  }

  getSpaceSchedule = () => {
    const { listStoreId, dispatchFilterWopSpaceScheduleByActivities, activityFilter } = this.props
    this.setState({ isLoading: true }, () => {
      Promise.all([
        getSpaceScheduleColumnsWithEstimateIdRequest({ listStoreId }),
        getSpaceScheduleWithEstimateIdRequest(
          { query: { listType: 'flat' } },
          { listStoreId, mergeOptions: { initialListItems: {} } }
        ),
        getSpaceScheduleValuesWithEstimateIdRequest(
          { listStoreId },
          { resultBarKey: WOP_SPACE_SCHEDULE }
        )
      ]).then(() => {
        this.setState({ isLoading: false, isListOutdated: false })
        if (!isEmpty(activityFilter)) dispatchFilterWopSpaceScheduleByActivities()
      })
    })
  }

  updateButton = (): React$Element<TextButton> => {
    const { t, classes, grouping } = this.props
    const { isLoading } = this.state
    const id = grouping ? 'wop-grouping-space-schedule-button' : 'wop-space-schedule-button'
    const props: $Shape<TextButtonProps & { testId: string }> = {
      disabled: this.isDisabled(),
      onClick: () => { this.getSpaceSchedule() },
      id,
      testId: id,
      text: t('wopSpaceSchedule._UPDATE_'),
      showSpinner: isLoading
    }

    return (
      <div className={classes.updateButton}>
        <TextButton {...props} />
      </div>
    )
  }

  openProcessesAndActivitiesWidget = (row: TVDListItem) => {
    const { dispatchOpenContentWidget } = this.props
    const { Description } = row.columnData || {}

    dispatchOpenContentWidget({
      widgetId: PROCESSES_AND_ACTIVITIES,
      widgetType: PROCESSES_AND_ACTIVITIES,
      widgetTitle: Description,
      contentProps: {
        itemId: row.id,
        filterIds: row.filterIds || [],
        widgetTitle: Description,
      }
    })
  }
  getSpaceScheduleTitle = (): React$Element<Typography> => {
    const { classes, t } = this.props
    return <Typography className={classes.wopScheduleHeaderText}>{ t('widgets._WOP_SPACE_SCHEDULE_') }</Typography>
  }

  isActiveSource = (row: Object): boolean => Boolean(this.props.activeFilterSource.id === row.id)

  getActivityLinkIcon = (row: Object): ActivityLinkIcon | null => {
    const {
      dispatchSetActivityFilter,
      dispatchClearActivityFilters,
      activityFilter,
      widgetId
    } = this.props
    if (!isEmpty(activityFilter) || widgetId !== WOP_SPACE_SCHEDULE) return null

    const onClick = !this.isActiveSource(row)
      ? () => dispatchSetActivityFilter(row)
      : () => dispatchClearActivityFilters()

    return <ActivityLinkIcon testid='activityLinkIcon' onClick={onClick} selected={this.isActiveSource(row)} />
  }

  iconsContainer = (row: TVDListItem): React$Element<'div'> => {
    const { classes } = this.props

    return (
      <div className={classes.iconsContainer}>
        {
          this.isActiveSource(row) ? this.getActivityLinkIcon(row) : <div data-visible_on_hover>{this.getActivityLinkIcon(row)}</div>
        }
        {
          (row.type === SPACE.toLowerCase()) &&
          <div className={classes.iconWrapper} data-visible_on_hover>
            <Icon
              testid='openProcessesAndActivitiesIcon'
              onClick={() => { this.openProcessesAndActivitiesWidget(row) }}
              className={classes.icon}
              style={{ fontSize: 15 }}>
              <SVGIconDataTableGraph />
            </Icon>
          </div>
        }
      </div>
    )
  }

  getListStatusIndicator = () => {
    const { classes, t } = this.props
    const { isListOutdated } = this.state
    return (
      <div className={combineStyleClassNames(classes.statusIndicator)}>
        {isListOutdated ? <SVGIconAlertInfoOutlined /> : <SVGIconAlertSuccessFilled /> }
        {isListOutdated ? t('wopSpaceSchedule._SPACE_SCHEDULE_OUTDATED_') : t('wopSpaceSchedule._SPACE_SCHEDULE_UP_TO_DATE_')}
      </div>
    )
  }

  getMessage = (source: Object): Message => {
    const {
      t,
      classes,
      dispatchClearActivityFilters
    } = this.props

    const messageTranslation = () => {
      const { type, columnData } = source
      const { Description } = columnData || {}

      if (this.listHasActivityFilter()) return t(`wopFilteringMessages._CLEAR_${type.toUpperCase()}_FILTERS_`, { Description })
      return t(`wopFilteringMessages._CLEAR_ACTIVITY_STRUCTURE_${type.toUpperCase()}_FILTERS_`, { Description })
    }

    const content = (
      <div className={classes.messagesContentWrapper}>
        <p>
          {messageTranslation()}
        </p>
        <p
          data-testid='clearActivityFiltersLink'
          role='presentation'
          className={combineStyleClassNames(classes.message, classes.clearFiltersLink)}
          onClick={dispatchClearActivityFilters}>
          { t('wopFilteringMessages._CLEAR_FILTERS_') }
        </p>
      </div>
    )

    return (
      <Message
        snackbar
        type='info'
        icon={<SVGIconAlertInfoOutlined />}
        customContent={content} />
    )
  }

  wrappedCellContents = (): TVDWrappedCells => {
    const { classes } = this.props
    return {
      Description: ({ row }: TVDWrappedCellCallbackParameters) => (
        <div className={classes.wrappedCellDescription} >
          <DescriptionCell
            noPointer
            highlighted={!(row.type === FUNCTION.toLowerCase() || row.type === FUNCTIONALSECTOR.toLowerCase())}
            tooltipText={row.columnData.Description}
            text={row.columnData.Description} />
          {(!row.filterIds || row.filterIds.length === 0) ? null : this.iconsContainer(row)}
        </div>

      ),
    }
  }

  listHasActivityFilter = (): boolean => !isEmpty(this.props.activityFilter)
  hasActiveFilterSource = (): boolean => !isEmpty(this.props.activeFilterSource)

  isDisabled = (): boolean => {
    const { isEstimateLockedToCurrentUser } = this.props
    const { isLoading } = this.state
    return isLoading || !isEstimateLockedToCurrentUser
  }

  render(): React$Element<typeof React.Fragment> {
    const {
      classes,
      listStoreId,
      grouping,
      activityFilter,
      activeFilterSource,
      showTitleText
    } = this.props

    return (
      <>
        { this.listHasActivityFilter() && this.getMessage(activityFilter) }
        { this.hasActiveFilterSource() && this.getMessage(activeFilterSource) }
        <div className={classes.buttonContainer}>
          { this.updateButton()} {!grouping && this.getListStatusIndicator()}
        </div>
        { showTitleText && this.getSpaceScheduleTitle() }
        <div className={classes.listContainer}>
          <HierarchicalListContainer
            wrappedCellContents={this.wrappedCellContents()}
            disabled={this.isDisabled()}
            listType={WOP_SPACE_SCHEDULE}
            listId={listStoreId}
            data-testid='test-wop-space-schedule'
            allowOnRowClick />
        </div>
      </>
    )
  }
}

const mapStateToProps = ({
  app:
  {
    isEstimateLockedToCurrentUser,
    languageCode
  },
  list
}: TVDReduxStore, { watchListStoreIds }: ReceivedProps): MappedProps => ({
  isEstimateLockedToCurrentUser,
  activityFilter: list[WOP_SPACE_SCHEDULE] ? list[WOP_SPACE_SCHEDULE].activityFilter : {},
  activeFilterSource: list[WOP_SPACE_SCHEDULE] ? list[WOP_SPACE_SCHEDULE].activeFilterSource : {},
  latestWatchListUpdateTime: getLatestListUpdateTime(list, watchListStoreIds),
  languageCode
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  dispatchOpenContentWidget: (openContentWidgetArgs: TVDOpenContentWidgetArguments) => { dispatch(openContentWidget(openContentWidgetArgs)) },
  dispatchSetActivityFilter: (row: TVDWOPListItem) => { dispatch(setActivityFilter(row, WOP_SPACE_SCHEDULE, ACTIVITY_STRUCTURE)) },
  dispatchClearActivityFilters: () => { dispatch(clearActivityFilters()) },
  dispatchFilterWopSpaceScheduleByActivities: () => { dispatch(filterWopSpaceScheduleByActivities()) },
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('translations'),
  withStyles(styles),
)(WopSpaceSchedule)
