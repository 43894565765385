// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { colors, typographyClasses } from 'frontend-assets'

import HierarchicalListContainer from '../../HierarchicalListContainer/HierarchicalListContainer'
import OverflowTooltip from '../../../../components/common/OverflowTooltip/OverflowTooltip'

import { setProcessActivityItems, clearList } from '../../../../actions/list/index'
import {
  getSpaceScheduleSpacesProcessesWithItemIdRequest,
  getSpaceScheduleSpacesActivitiesWithItemIdRequest
} from '../../../../utils/generated-api-requests/wop'
import { DESCRIPTION } from '../../../../constants/attributes'
import { PROCESSACTIVITY, PROCESSES, ACTIVITIES, PROCESSES_AND_ACTIVITIES, WOP_GROUPING_SPACESCHEDULE } from '../../../../constants/contentTypes'
import ViewHeaderText from '../../../common/ViewHeaderText/ViewHeaderText'
import { updateWidgetTitle } from '../../../../actions/widgets'

const TEXT_SIZE = 140
const WIDGET_LEFT_PADDING = 53

const styles = ({ palette }: TVDTheme) => ({
  processesAndActivitiesWrapper: {
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  processesWrapper: {
    ...typographyClasses.bodyDefault,
    padding: `5px 75px 20px ${WIDGET_LEFT_PADDING}px`,
  },
  processWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '24px',
    marginBottom: '7px',
    fontSize: '14px',
    '&:first-child': {
      fontWeight: 'bold'
    }
  },
  processText: {
    color: palette.dark80,
    display: 'flex',
    minWidth: TEXT_SIZE,
    maxWidth: TEXT_SIZE,
    paddingRight: '5px',
    overflow: 'hidden'
  },
  processBar: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  floatingValue: {
    position: 'absolute',
    color: palette.dark80,
    whiteSpace: 'nowrap',
    paddingLeft: '5px',
    fontSize: '14px'
  }
})
type DispatchProps = {|
  dispatchProcessActivity: (listId: string, listItems: Object, columns?: Array<Object>) => void, // action to dispatch list
  dispatchClearList: (listId: string) => void, // function to clear widget content data from store on unmount
  dispatchUpdateWidgetTitle: (widgetId: string, contentProps: Object) => void // function to update widget title
|}

type MappedProps = {|
  processList: {listItems: TVDListItems}, // list of processes
  spacesList: {listItems: TVDListItems}, // list of spaces
  languageCode: string, // current language code
|}

type ReceivedProps = {|
  itemId: string, // id of item that Processes and Activies was opened from
  filterIds: Array<number>, // filter id in listItems
|}

type Props = {|
  ...DispatchProps,
  ...MappedProps,
  ...ReceivedProps,
  t: Function, // translate function
  classes: Object, // withStyles classes object
|}

type State = {
  itemIndex: number // index of item in list
}

export class ProcessesAndActivities extends Component<Props, State> {
  static defaultProps = {
    filterIds: []
  }

  state = { itemIndex: -1 }

  componentDidMount() {
    this.getProcesses()
    this.getActivities()
    if (this.props.spacesList) {
      const keys = Object.keys(this.props.spacesList.listItems)
      const itemIndex = keys.indexOf(this.props.itemId)
      this.setState({ itemIndex })
    }
  }

  componentDidUpdate(prevProps: Object) {
    const { itemId, spacesList } = this.props
    if (prevProps.itemId !== this.props.itemId) {
      this.getProcesses()
      this.getActivities()
    }
    if (prevProps.languageCode !== this.props.languageCode) {
      this.getProcesses()
      this.getActivities()
    }
    if (prevProps.spacesList !== spacesList) {
      const selectedRow = spacesList.listItems[itemId]
      let rowDescription
      // the itemId changes after fetching the rows with different translations
      // so we need to get the description according to index
      if (selectedRow) {
        rowDescription = selectedRow.columnData.Description
      } else {
        const idWithIndex = Object.keys(spacesList.listItems)[this.state.itemIndex]
        rowDescription = spacesList.listItems[idWithIndex].columnData.Description
      }
      this.props.dispatchUpdateWidgetTitle(
        PROCESSES_AND_ACTIVITIES,
        { widgetTitle: rowDescription }
      )
    }
    if (prevProps.itemId !== itemId) {
      const keys = Object.keys(this.props.spacesList.listItems)
      const itemIndex = keys.indexOf(this.props.itemId)
      this.setState({ itemIndex })
    }
  }

  componentWillUnmount() {
    this.props.dispatchClearList(`${PROCESSES_AND_ACTIVITIES}-${PROCESSES}`)
  }

  getProcesses = () => {
    const {
      dispatchProcessActivity,
      itemId
    } = this.props

    getSpaceScheduleSpacesProcessesWithItemIdRequest(
      { path: { itemId }, query: { activityGroupIds: this.activityGroupIds() } },
      {},
      (processItems: Array<Object>) => {
        const processList = processItems.map(((item: Object) => {
          const processItem = {
            description: item.valueLocalization,
            usage: item.value
          }
          return processItem
        }))

        const listItemsObject = {}
        processList.forEach((process: Object, index: number) => {
          listItemsObject[index] = process
        })

        dispatchProcessActivity(`${PROCESSES_AND_ACTIVITIES}-${PROCESSES}`, listItemsObject)
      }
    )
  }

  getActivities = () => {
    const {
      dispatchProcessActivity,
      itemId
    } = this.props

    getSpaceScheduleSpacesActivitiesWithItemIdRequest(
      { path: { itemId }, query: { activityGroupIds: this.activityGroupIds() } },
      {},
      (activityItems: Object) => {
        const sortedActivities = Object.values(activityItems)
          .sort((a: Object, b: Object) => b.columnData.Area - a.columnData.Area)
          // hackway until backend remove parentId for filterIds
          .map((activity: Object) => ({
            ...activity,
            parentId: null
          }))

        dispatchProcessActivity(`${PROCESSES_AND_ACTIVITIES}-${ACTIVITIES}`, sortedActivities, this.columns())
      }
    )
  }

  activityGroupIds = () => {
    const { filterIds } = this.props

    if (Array.isArray(filterIds)) {
      const activityGroupIds = filterIds.join(',')
      return activityGroupIds
    }

    return ''
  }

  process = (description: string, value: number, index: number): React$Element<'div'> => {
    const { classes } = this.props

    const firstProcess = index === 0
    const textStyles = firstProcess ? { fontWeight: 600 } : {}
    const barColor = firstProcess ? colors.lochmara : colors.malibu

    const formattedValue = isNaN(value) ? value : value.toFixed(1)

    return (
      <div className={classes.processWrapper} key={description}>
        <span className={classes.processText} style={textStyles}>
          <OverflowTooltip tooltipText={description}>{description}</OverflowTooltip>
        </span>
        <div className={classes.processBar}>
          <svg width='100%' height='100%'>
            <rect width='100%' height='100%' style={{ fill: colors.porcelain }} />
            <rect width={`${value}%`} height='100%' style={{ fill: barColor }} />
          </svg>
          <p className={`${classes.floatingValue}`} style={{ left: `${formattedValue}%`, top: '-12px' }}>{`${formattedValue} %`}</p>
        </div>
      </div>
    )
  }

  processes = (): React$Element<'div'> | null => {
    const {
      t, classes, processList
    } = this.props

    if (!processList) return null

    const processListItems = Object.values(processList.listItems)

    return (
      <div>
        <ViewHeaderText inWidget>{t('processesAndActivities._SPACE_CONSUMING_PROCESSES_')}</ViewHeaderText>
        <div className={classes.processesWrapper}>
          { processListItems.map((process: Object, index: number) => this.process(process.description, process.usage, index + 1)) }
        </div>
      </div>
    )
  }

  activities = (): React$Element<'div'> => {
    const { t } = this.props

    return (
      <div>
        <ViewHeaderText inWidget>{t('processesAndActivities._ACTIVITIES_')}</ViewHeaderText>
        <HierarchicalListContainer
          initialColumnWidths={{
          [DESCRIPTION]: 180,
        }}
          wrappedCellContents={{
              Description: ({ row }: TVDWOPWrappedCellCallbackParameters) => {
                const content = row.columnData.Description
                return <OverflowTooltip tooltipText={content} placement='top'>{content}</OverflowTooltip>
          }
        }}
          listId={`${PROCESSES_AND_ACTIVITIES}-${ACTIVITIES}`}
          listType={PROCESSACTIVITY} />
      </div>
    )
  }

  columns(): Array<Object> {
    const { t } = this.props

    const columns = [
      { propertyName: 'Description', localizedName: t('processesAndActivities._ACTIVITY_') },
      { propertyName: 'Driver', localizedName: t('widgets._AMOUNT_'), dataType: 'number' },
      {
        propertyName: 'Area', localizedName: t('processesAndActivities._AREA_'), dataType: 'number'
      }
    ]
    return columns
  }

  render(): React$Element<any> {
    const { classes } = this.props

    return (
      <div className={classes.processesAndActivitiesWrapper}>
        {this.processes()}
        {this.activities()}
      </div>
    )
  }
}


const mapStateToProps = ({ list, app: { languageCode } }: TVDReduxStore): MappedProps =>
  ({
    processList: list[`${PROCESSES_AND_ACTIVITIES}-${PROCESSES}`],
    spacesList: list[WOP_GROUPING_SPACESCHEDULE],
    languageCode
  })


const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  dispatchProcessActivity: (listId: string, listItems: Object, columns?: Array<Object>) => {
    dispatch(setProcessActivityItems(listId, listItems, columns))
  },
  dispatchClearList: (listId: string) => { dispatch(clearList(listId)) },
  dispatchUpdateWidgetTitle: (widgetId: string, contentProps: Object) => { dispatch(updateWidgetTitle(widgetId, contentProps)) }

})

export default withTranslation('translations')(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ProcessesAndActivities)))
