// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
// LIBRARIES
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { map } from 'lodash'
import { TextButton } from 'frontend-components'

import { type TextButtonProps } from '../../common/TextButton/TextButton'
import Menu from '../../common/menus/Menu/Menu'
import FeaturesHOC from '../../hocs/FeaturesHOC/FeaturesHOC'

import { ELEMENTS, WOP, RENOVATION_SITE_TAB } from '../../../constants/moduleConstants'
import { romanize } from '../../../utils/commonUtils'
import { getIsFeatureEnabledInSet } from '../../../utils/features'
import {
  FEATURE_BUILDING_ELEMENTS_TASKS_WIDGET_MFE,
  FEATURE_BUILDING_ELEMENTS_PRICING,
  FEATURE_BUILDING_ELEMENTS_IMPORT_FROM_BE_ESTIMATE
} from '../../../constants/features'
import {
  USAGEMAINTENANCE
} from '../../../constants/viewModeConstants'
import {
  CALCULATION_PROPERTIES,
  BUILDING_ELEMENTS_TASK,
  BUILDING_ELEMENTS_SCHEDULE,
  RENOVATION,
  WOP_SPACE_SCHEDULE,
  WOP_GROUPING_SPACESCHEDULE,
  WOP_GROUPING_GROUPINGSCHEDULE,
  SHOW_RESULTS,
  CREATE_BUILDING_ELEMENTS,
  CREATE_FROM_PRICING,
  IMPORT_FROM_CALCULATION
} from '../../../constants/contentTypes'
import {
  targetProperties,
  elementTask,
  generalFeatures,
  generalFeaturesWOP,
  type WidgetKeys,
  CREATE_ACTIVITY,
  GROUPING_VIEW,
  INSPECT_RESULT,
  GENERAL_FEATURES,
  GENERAL_FEATURES_WOP,
  IMPORT_FROM_WOP_CALCULATION,
  NEW_CREATE_FROM_PRICING,
  IMPORT_FROM_BE_ESTIMATE
} from '../../../constants/widgetKeys'
import { openContentWidget } from '../../../actions/widgets'
import { openReportModal } from '../../../actions/modals'
import BuildingElementsTasksWidgetMFEContainer from '../BuildingElementsTasksWidgetMFEContainer/BuildingElementsTasksWidgetMFEContainer'
import SiteEquipmentProductAssemblyMFEContainer from '../SiteEquipmentProductAssemblyMFEContainer/SiteEquipmentProductAssemblyMFEContainer'
import { setMFEStates } from '../../../actions/MFEStates'
import {
  BUILDING_ELEMENTS_ASSEMBLY_PRICE_LIST_MFENAME,
  BUILDING_ELEMENTS_ASSEMBLY_PRICE_LIST_COMPONENTNAME,
  IMPORT_FROM_BE_ESTIMATE_WIDGET_MFENAME,
  IMPORT_FROM_BE_ESTIMATE_WIDGET_COMPONENTNAME,
  SPACES_LIFECYCLE_CO2_WIDGET_MFENAME,
  SPACES_LIFECYCLE_CO2_WIDGET_COMPONENTNAME,
  SITE_EQUIPMENT_PRODUCT_ASSEMBLY_MFENAME,
  SITE_EQUIPMENT_PRODUCT_ASSEMBLY_COMPONENTNAME
} from '../../../constants/MFEStateConstants'
import { dispatch as dispatchMFEStates } from '../../../store'


const styles = {
  toolsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: '1 1 auto',
    flexWrap: 'wrap',
    rowGap: '4px',
    columnGap: '8px',
  },
}

const capitalize = (input: string): string => {
  if (typeof input !== 'string') return ''
  const inputLowerCase = input.toLowerCase()
  return inputLowerCase.charAt(0).toUpperCase() + inputLowerCase.slice(1)
}


type HOCProps = {|
  t: Function, // translate function
  classes: Object, // classes-object generated by withStyles function
  features: TVDFeatureHOCProps, // features from Store and helper functions from the HOC
|}

type MappedProps = {|
  application: string, // current application
  spacesListId: string, // the unique id for the main Spaces list component
  activityStructureId: string, // id of activityStructure
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // if the user owns the lock for the estimate
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>, // if estimate is frozen
  disabled: $PropertyType<TVDApplicationStore, 'activeEdit'>, // if save pattern is active
  languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
  spacesEstimateType: $PropertyType<TVDApplicationStore, 'spacesEstimateType'>, // spaces estimate type
  licenseType: $PropertyType<TVDApplicationStore, 'licenseType'>, // type of TVD License, project, legacyProject or scenario
  activeEstimate: TVDActiveEstimateStore, // active estimate
|}


type DispatchProps = {|
  dispatchOpenContentWidgetStrict: Function, // Open widget function with restricted arguments
  dispatchOpenContentWidget: (TVDOpenContentWidgetArguments) => void, // Open widget function
  dispatchOpenReportModal: (key: string) => void, // Opens ReportModal
  dispatchOpenBuildingElementsAssemblyPriceList: () => void, // Opens BuildingElementsAssemblyPriceList
  dispatchOpenImportFromBEEstimateWidget: () => void, // Opens ImportFromBEEstimateWidget
|}

type Props = {
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
}

type State = {
  isTaskWidgetOpen: boolean,
  selectedSiteEquipmentAssemblyId: string,
}

export class ToolsContainer extends Component<Props, State> {
  state = {
    isTaskWidgetOpen: false,
    selectedSiteEquipmentAssemblyId: '',
  }

  componentDidUpdate = (prevProps: Props) => {
    const { spacesEstimateType } = this.props
    if (prevProps.spacesEstimateType !== spacesEstimateType) {
      if (spacesEstimateType === USAGEMAINTENANCE) {
        dispatchMFEStates(setMFEStates(
          SPACES_LIFECYCLE_CO2_WIDGET_MFENAME,
          false,
          SPACES_LIFECYCLE_CO2_WIDGET_COMPONENTNAME,
          null
        ))
      }
    }
  }

  setIsTaskWidgetOpen = (isTaskWidgetOpen: boolean): void => {
    this.setState({
      isTaskWidgetOpen
    })
  }

  setSelectedSiteEquipmentAssemblyId = (selectedSiteEquipmentAssemblyId: string): void => {
    this.setState({
      selectedSiteEquipmentAssemblyId
    })
  }

  /**
   * Builds a single menu with a TextButton (variant: toolButton) as the root component
   * @param {WidgetKeys} keys - Keys for the widgets, used for the handleOpenWidget method to open a widget
   * @param {string} text - the text that goes in the TextButton
   * @param {boolean} hasTitleItem - whether the menu should have a divider under the first item
   * @param {boolean} romanized - will put roman numerals in front of the item label
   */
  buildMenu = (
    keys: WidgetKeys,
    text: string,
    number?: number,
    hasTitleItem: boolean = false,
    romanized: boolean = false,
    customOnClickHandlers?: { [key: string]: () => void }
  ) => {
    const {
      t,
      disabled,
      features,
      dispatchOpenReportModal,
      application,
      activeEstimate,
      licenseType,
    } = this.props
    const isIconButton = text === GENERAL_FEATURES || text === GENERAL_FEATURES_WOP

    /*
     * Parses roman numerals to all the list items except the first.
     * @param (number) index - the index number of the item, used to get the numeral
     * @param (string) text - the keyword passed to the translation function
    */
    const parseRomanizedTitle = (index: number, label: string) => {
      if (index !== 0) {
        return `${romanize(index)} ${t(`widgets._${label}_`)}`
      }
      return `${t(`widgets._${label}_`)} (I-IV)`
    }

    type FeatureMenuItemProps = {|
      ...TVDMenuItem,
      onFeatureDisabled: Function, // returns props used when feature is disabled. This fn is filtered out from the props via getEnabledFeatures
    |}

    const items: Array<TVDMenuItem> = features.getEnabledFeatures(map(
      keys.widgets,
      (widget: string, index: number): FeatureMenuItemProps => {
        const handleClick = () => {
          if (isIconButton) {
            dispatchOpenReportModal(application)
          } else if (customOnClickHandlers?.[widget]) {
            customOnClickHandlers?.[widget]()
          } else {
            this.handleOpenWidget(widget)
          }
        }

        const props = {
          testId: widget,
          value: widget,
          localizedName: romanized ? parseRomanizedTitle(index, widget) : t(`widgets._${widget}_`),
          onClick: handleClick
        }
        return { ...props, onFeatureDisabled: () => ({ ...props, disabled: true }) }
      }
    ), 'value')
      // filter menuItems based on licenseType
      .filter((item: TVDMenuItem) => (licenseType === 'scenario' ? item.value !== IMPORT_FROM_WOP_CALCULATION : item))

    if (hasTitleItem) items.splice(1, 0, { divider: true })

    const isDisabledDueFreezing = 
      keys.groupLabel === targetProperties.groupLabel || 
      keys.groupLabel === SHOW_RESULTS 
        ? false 
        : !!activeEstimate?.frozen

    const buttonProps: TextButtonProps = {
      text: capitalize(text),
      variant: 'toolButton',
      iconRight: 'arrow_drop_down',
      disabled: features.getIsFeatureDisabled(keys.groupLabel) || disabled || isDisabledDueFreezing,
      id: keys.groupLabel,
      number
    }
    const iconButtonProps: TextButtonProps = {
      ...buttonProps,
      disabled: false,
      text: '',
      variant: 'icon',
      iconRight: 'more_horiz'
    }

    const props = isIconButton ? iconButtonProps : buttonProps

    return (
      <Menu
        items={items}
        data-testid='ToolMenu'
        key={keys.groupLabel}
        disablePortal={false}
        toolMenu>
        <TextButton {...props} />
      </Menu>
    )
  }

  handleOpenWidget = (key: string) => {
    const { dispatchOpenContentWidget, dispatchOpenContentWidgetStrict } = this.props
    switch (key) {
      case BUILDING_ELEMENTS_TASK: {
        if (this.shouldUseBuildingTasksWidgetMFE()) {
          this.setIsTaskWidgetOpen(true)
        } else {
          dispatchOpenContentWidget({
            widgetId: BUILDING_ELEMENTS_TASK,
            widgetType: BUILDING_ELEMENTS_TASK,
            widgetEnableAllWidgetsOnClose: true,
            contentProps: {
              selectedTab: 'properties',
              propertiesStoreId: BUILDING_ELEMENTS_TASK,
              listStoreId: BUILDING_ELEMENTS_TASK,
            },
          })
        }
        break
      }
      case CALCULATION_PROPERTIES: {
        dispatchMFEStates(setMFEStates(
          SITE_EQUIPMENT_PRODUCT_ASSEMBLY_MFENAME,
          true,
          SITE_EQUIPMENT_PRODUCT_ASSEMBLY_COMPONENTNAME,
          {
            siteEquipmentAssemblyId: this.state.selectedSiteEquipmentAssemblyId
          }
        ))
        break
      }
      case BUILDING_ELEMENTS_SCHEDULE: {
        dispatchOpenContentWidget({
          widgetId: BUILDING_ELEMENTS_SCHEDULE,
          widgetType: BUILDING_ELEMENTS_SCHEDULE,
          contentProps: {
            listStoreId: BUILDING_ELEMENTS_SCHEDULE,
          },
        })
        break
      }
      case RENOVATION: {
        dispatchOpenContentWidget({
          widgetId: RENOVATION,
          widgetType: RENOVATION,
          contentProps: {
            selectedTab: RENOVATION_SITE_TAB,
          }
        })
        break
      }
      case WOP_SPACE_SCHEDULE: {
        const { activityStructureId } = this.props
        dispatchOpenContentWidget({
          widgetId: WOP_SPACE_SCHEDULE,
          widgetType: WOP_SPACE_SCHEDULE,
          contentProps: {
            listStoreId: WOP_SPACE_SCHEDULE,
            activityStructureId,
            watchListStoreIds: [WOP_GROUPING_GROUPINGSCHEDULE, activityStructureId]
          }
        })
        break
      }
      case GROUPING_VIEW: {
        dispatchOpenContentWidget({
          widgetId: GROUPING_VIEW,
          widgetType: GROUPING_VIEW,
          contentProps: {
            groupingListStoreId: WOP_GROUPING_GROUPINGSCHEDULE,
            listStoreId: WOP_GROUPING_SPACESCHEDULE
          }
        })
        break
      }
      default:
        dispatchOpenContentWidgetStrict(key, '', key, key)
        break
    }
  }

  shouldUseBuildingTasksWidgetMFE = (): boolean =>
    getIsFeatureEnabledInSet(FEATURE_BUILDING_ELEMENTS_TASKS_WIDGET_MFE) &&
    // TODO: remove localStorage item check when API has been confirmed to work
    !!localStorage.getItem(FEATURE_BUILDING_ELEMENTS_TASKS_WIDGET_MFE)

  toolMenus = (): Array<any> => {
    const {
      t,
      application,
      disabled,
      isEstimateFrozen,
      dispatchOpenBuildingElementsAssemblyPriceList,
      dispatchOpenImportFromBEEstimateWidget,
      activeEstimate
    } = this.props

    switch (application) {
      case ELEMENTS: {
        const elementTaskButton: TextButtonProps = {
          text: capitalize(`${t(`widgets._${elementTask.widget}_`)}`),
          onClick: () => {
            this.handleOpenWidget(elementTask.widget)
          },
          variant: 'toolButton',
          disabled: disabled || !!activeEstimate?.frozen,
          id: elementTask.widget,
          number: 2
        }

        const newWidgetItems = this.buildMenu(
          {
            groupLabel: CREATE_BUILDING_ELEMENTS,
            widgets: [
              IMPORT_FROM_CALCULATION,
              getIsFeatureEnabledInSet(FEATURE_BUILDING_ELEMENTS_IMPORT_FROM_BE_ESTIMATE) ? IMPORT_FROM_BE_ESTIMATE : undefined,
              getIsFeatureEnabledInSet(FEATURE_BUILDING_ELEMENTS_PRICING) ?
                NEW_CREATE_FROM_PRICING :
                CREATE_FROM_PRICING,
            ].filter(Boolean)
          },
          `${t(`widgets._${(CREATE_BUILDING_ELEMENTS)}_`)}`,
          1,
          false,
          false,
          {
            NEW_CREATE_FROM_PRICING: () => {
              dispatchOpenBuildingElementsAssemblyPriceList()
            },
            IMPORT_FROM_BE_ESTIMATE: () => {
              dispatchOpenImportFromBEEstimateWidget()
            }
          }
        )

        return [
          newWidgetItems,
          <TextButton {...elementTaskButton} key={elementTask.widget} disabled={!!activeEstimate?.frozen} />,
          this.buildMenu(generalFeatures, generalFeatures.groupLabel)
        ]
      }
      case WOP: {
        const createActivityButton: TextButtonProps = {
          text: capitalize(`${t(`widgets._${CREATE_ACTIVITY}_`)}`),
          onClick: () => { this.handleOpenWidget(CREATE_ACTIVITY) },
          variant: 'toolButton',
          disabled: disabled || !!isEstimateFrozen,
          id: CREATE_ACTIVITY,
          number: 1
        }
        const groupingViewButton: TextButtonProps = {
          text: capitalize(`${t(`widgets._${GROUPING_VIEW}_`)}`),
          onClick: () => { this.handleOpenWidget(GROUPING_VIEW) },
          variant: 'toolButton',
          disabled: disabled || !!isEstimateFrozen,
          id: GROUPING_VIEW,
          number: 2
        }
        const spaceSchedule: TextButtonProps = {
          text: capitalize(`${t(`widgets._${INSPECT_RESULT}_`)}`),
          onClick: () => { this.handleOpenWidget(WOP_SPACE_SCHEDULE) },
          variant: 'toolButton',
          disabled: disabled || !!isEstimateFrozen,
          id: WOP_SPACE_SCHEDULE,
          number: 3
        }
        return [
          <TextButton key={CREATE_ACTIVITY} {...createActivityButton} />,
          <TextButton key={GROUPING_VIEW} {...groupingViewButton} />,
          <TextButton key={WOP_SPACE_SCHEDULE} {...spaceSchedule} />,
          this.buildMenu(generalFeaturesWOP, generalFeaturesWOP.groupLabel)
        ]
      }
      default:
        return []
    }
  }

  render(): React$Element<any> {
    const { classes } = this.props
    const contentProps = { siteEquipmentAssemblyId: this.state.selectedSiteEquipmentAssemblyId }
    return (
      <div className={classes.toolsContainer}>
        <>
          {
            this.shouldUseBuildingTasksWidgetMFE() && (
              <BuildingElementsTasksWidgetMFEContainer
                setIsTasksWidgetOpen={(isOpen: boolean): void => {
                  this.setIsTaskWidgetOpen(isOpen)
                }}
                isTasksWidgetOpen={this.state.isTaskWidgetOpen} />
            )
          }
          <SiteEquipmentProductAssemblyMFEContainer contentProps={contentProps} />
        </>
        {this.toolMenus()}
      </div>
    )
  }
}

function mapStateToProps({ app, activeEstimate }: TVDReduxStore): MappedProps {
  const {
    application,
    activeEdit,
    spacesListId,
    activityStructureId,
    isEstimateFrozen,
    isEstimateLockedToCurrentUser,
    languageCode,
    spacesEstimateType,
    licenseType
  } = app

  return {
    application,
    disabled: activeEdit,
    spacesListId,
    activityStructureId,
    isEstimateFrozen,
    isEstimateLockedToCurrentUser,
    languageCode,
    spacesEstimateType,
    licenseType,
    activeEstimate
  }
}

function mapDispatchToProps(dispatch: Function): Object {
  return {
    dispatchOpenContentWidget: (openContentWidgetArgs: TVDOpenContentWidgetArguments) => { dispatch(openContentWidget(openContentWidgetArgs)) },
    dispatchOpenReportModal: (key: string) => { dispatch(openReportModal(key)) },
    dispatchOpenContentWidgetStrict: (widgetType: string, description: string, resourceId: string, resourceListId?: string) => {
      dispatch(openContentWidget({
        widgetType,
        widgetId: resourceId,
        contentProps: {
          description,
          resourceId,
          resourceListId
        }
      }))
    },
    dispatchOpenBuildingElementsAssemblyPriceList: () => {
      dispatch(setMFEStates(
        BUILDING_ELEMENTS_ASSEMBLY_PRICE_LIST_MFENAME,
        true,
        BUILDING_ELEMENTS_ASSEMBLY_PRICE_LIST_COMPONENTNAME,
        null
      ))
    },
    dispatchOpenImportFromBEEstimateWidget: () => {
      dispatch(setMFEStates(
        IMPORT_FROM_BE_ESTIMATE_WIDGET_MFENAME,
        true,
        IMPORT_FROM_BE_ESTIMATE_WIDGET_COMPONENTNAME,
        null
      ))
    }
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('translations'),
  withStyles(styles),
  FeaturesHOC
)(ToolsContainer)
