// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { isEmpty, reduce } from 'lodash'
import { withTranslation } from 'react-i18next'

import Stepper from '../../../common/Stepper/Stepper'
import DropdownMultipleSelectMenu from '../../../common/menus/DropdownMultipleSelectMenu/DropdownMultipleSelectMenu'
import BuildingTaskPropertyDataContainer from './BuildingTaskPropertyDataContainer'
import BuildingTaskDataTableContainer from './BuildingElementTaskDataTableContainer/BuildingElementTaskDataTableContainer'
import FooterButtons from '../FooterButtons/FooterButtons'
import type { TextButtonProps } from '../../../common/TextButton/TextButton'

import { removeBuildingTaskSchedule } from '../../../../actions/elements'
import { closeWidget, enableAllWidgets } from '../../../../actions/widgets'
import { BUILDING_ELEMENTS_TASK } from '../../../../constants/contentTypes'
import {
  getEnumWithEnumRequest,
  putBuildingTasksWithEstimateIdRequest
} from '../../../../utils/generated-api-requests/buildingelements'
import { setWidgetActive } from '../../../../actions/app'
import { postPolling } from '../../../../actions/postPolling'
import { combineStyleClassNames } from '../../../../utils/styleUtils'
import ViewHeaderText from '../../../common/ViewHeaderText/ViewHeaderText'

const styles = ({ palette }: TVDTheme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  stepper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '3',
    overflow: 'auto'
  },
  dropDown: {
    paddingLeft: '33px',
  },
  buttonContainer: {
    paddingLeft: '31px',
    paddingRight: '33px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '19px 0 24px 0',
  },
  spaceBetween: {
    justifyContent: 'space-between'
  },
  replaceTip: {
    color: palette.nevada,
    fontSize: '12px',
    display: 'flex',
    alignItems: 'flex-end'
  }
})

type HOCProps = {|
  t: Function, // translate function from i18next
  classes: Object, // withStyles classes object
|}

type MappedProps = {|
  data: Object, // data of to be imported tasks list in step two
  properties: {[propertyName: string]: TVDPropertiesListItem}, // properties with a given listId from store's "properties"
  steps: Array<string>, // string array of steps that are used to guide user to make required actions
  activeCalculation: boolean, // store's value from "app" to tell if the application is in the middle of calculating
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>,
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>
|}

type DispatchProps = {|
  dispatchPostPolling: () => void, // initiate post polling process
  dispatchRemoveBuildingTaskSchedule: (string) => void, // function to remove buildingtask schedule from state when user navigates away from schedule view
  dispatchCloseWidgetAndEnableOtherWidgets: Function, // close modal function
  dispatchSetWidgetActive: () => void // sets current widget as active
|}

type ReceivedProps = {|
  disabled: boolean, // flag to disable widget content
  listStoreId: string, // a list id for the HierarchicalListContainer that is rendered further down the children
  propertiesStoreId: string, // key to the properties Store object where the properties is stored
  widgetId: string, // id used to work as the key in Store to access specific widget's content
|}

type Props = {|
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
  ...ReceivedProps
|}

type State = {|
  tasks: Array<string>, // string array of selected values in DropDown
  activeStep: number, // index of active step in stepper component
  options: Array<Object>, // array of raw Dropdown options, these are NOT displayed in dropdown
  buildingTaskScheduleId: string,
  selectedTasks: Array<Object>,
|}

export class BuildingElementsTask extends Component<Props, State> {
  state = {
    tasks: [],
    activeStep: 0,
    options: [],
    buildingTaskScheduleId: '',
    selectedTasks: [],
  }

  componentDidMount() {
    const { dispatchSetWidgetActive } = this.props
    dispatchSetWidgetActive()
    if (isEmpty(this.state.options)) {
      getEnumWithEnumRequest({ path: { enumParam: 'buildingtasks' } }, {}, (options: Array<Object>) => {
        const tasks = options.map(({ value }: Object) => value)
        this.setState({ options, tasks, selectedTasks: tasks })
      })
    }
  }

  get stepper(): React$Element<typeof Stepper> {
    const { classes, steps } = this.props
    const { activeStep } = this.state

    return (
      <div className={classes.stepper}>
        <Stepper
          getStepContent={this.getStepContent}
          steps={steps}
          activeStep={activeStep} />
      </div>
    )
  }

  get taskEditView(): React$Element<BuildingTaskDataTableContainer> {
    const {
      t,
      listStoreId
    } = this.props
    const { tasks, options } = this.state

    const selectedTaskIds = reduce(options, (result: Array<String>, item: Object) => {
      if (tasks.includes(item.value)) {
        result.push(item.value)
      }
      return result
    }, [])

    return (
      <div>
        <ViewHeaderText inWidget>
          {t('tasks._IMPORTED_TASKS_')}
        </ViewHeaderText>
        <BuildingTaskDataTableContainer
          listId={listStoreId}
          taskIds={selectedTaskIds}
          disabled={this.isDisabled()} />
      </div>
    )
  }

  get taskSelectView(): React$Element<any> {
    const {
      classes,
      t,
      propertiesStoreId,
      widgetId
    } = this.props
    const { options, tasks, selectedTasks } = this.state
    const selectedTaskIds = reduce(options, (result: Array<String>, item: Object) => {
      if (tasks.includes(item.value)) {
        result.push(item.value)
      }
      return result
    }, [])
    return (
      <div>
        <div className={classes.dropDown}>
          <DropdownMultipleSelectMenu
            disabled={this.isDisabled()}
            items={options}
            defaultValues={selectedTasks}
            onChange={this.handleChange}
            selectAllItemLabel={t('tasks._ALL_')} />
        </div>
        <BuildingTaskPropertyDataContainer
          widgetId={widgetId}
          actionText={t('widgets._BUILDING_ELEMENTS_TASKS_RESET_DEFAULT_VALUE_')}
          propertiesStoreId={propertiesStoreId}
          disabled={this.isDisabled()}
          taskIds={selectedTaskIds} />
      </div>
    )
  }

  get content(): React$Element<any> {
    const { steps } = this.props
    const { activeStep } = this.state
    return activeStep === steps.length - 1 ? this.taskEditView : this.taskSelectView
  }

  get footerButtons(): React$Element<any> {
    const {
      classes,
      steps,
      t,
      dispatchCloseWidgetAndEnableOtherWidgets
    } = this.props
    const { activeStep } = this.state
    const isLastStep = activeStep === steps.length - 1
    const footerButtonItems: Array<TextButtonProps> = []

    if (activeStep !== 0) {
      footerButtonItems.push({
        id: 'stepperButtonPrevious',
        onClick: this.handleBack,
        text: t('stepper._PREVIOUS_'),
        variant: 'text'
      })
    }
    if (isLastStep) {
      footerButtonItems.push({
        id: 'stepperButtonAdd',
        onClick: () => this.replaceTasks(),
        text: t('stepper._ADD_AND_REPLACE_TASKS_'),
      })
    } else {
      footerButtonItems.push({
        disabled: this.isDisabled(),
        id: 'stepperButtonNext',
        onClick: this.handleNext,
        text: t('stepper._NEXT_'),
        variant: 'outlined'
      })
    }
    footerButtonItems.push({
      id: 'stepperButtonCancel',
      onClick: dispatchCloseWidgetAndEnableOtherWidgets,
      text: t('stepper._CLOSE_'),
      variant: 'text',
    })

    return (
      <div className={isLastStep ? combineStyleClassNames(classes.buttonContainer, classes.spaceBetween) : classes.buttonContainer}>
        {isLastStep && <div className={classes.replaceTip}>{t('widgets._BUILDING_ELEMENTS_TASKS_REPLACE_TIP_')}</div>}
        <FooterButtons items={footerButtonItems} />
      </div>
    )
  }
  // returned ids consist of the immediate child row ids of the root list items
  get selectedIds(): Array<string> {
    const { data } = this.props
    const rootIds = Object.keys(data).filter((listItemId: string) => {
      const { [listItemId]: { parentId } } = data
      return !parentId
    })
    return Object.keys(data).filter((listItemId: string) => {
      const { [listItemId]: { parentId } } = data
      return rootIds.includes(parentId)
    })
  }

  handleNext = (): void => {
    this.setState((state: State): $Shape<State> => ({
      activeStep: state.activeStep + 1,
    }))
  }

  handleBack = (): void => {
    this.props.dispatchRemoveBuildingTaskSchedule(this.state.buildingTaskScheduleId)
    this.setState((state: Object) => ({
      activeStep: state.activeStep - 1,
      buildingTaskScheduleId: '',
    }))
  }

  handleCancel = (): void => {
    this.setState({
      activeStep: 0,
    })
  }

  handleChange = (selectedItems: Array<string>) => {
    this.setState({ tasks: selectedItems, selectedTasks: selectedItems })
  }

  getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return '_CHOOSE_TASKS_'
      case 1:
        return '_IMPORT_TASKS_'
      default:
        return 'Unknown step'
    }
  }

  replaceTasks = () => {
    const { dispatchPostPolling } = this.props
    putBuildingTasksWithEstimateIdRequest({
      body: {
        buildingTaskIds: this.state.selectedTasks,
        selectedIds: this.selectedIds
      }
    }, {}, () => dispatchPostPolling())
  }

  isDisabled(): boolean {
    const {
      activeCalculation,
      disabled,
      isEstimateLockedToCurrentUser,
      isEstimateFrozen
    } = this.props
    return activeCalculation || disabled || !isEstimateLockedToCurrentUser || !!isEstimateFrozen
  }

  render(): React$Element<any> {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        {this.stepper}
        <div className={classes.content}>
          {this.content}
        </div>
        <div>
          {this.footerButtons}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  // dispatching the widgetModifiedFalse as "true", due to our setWidgetActive always assuming that we have a modified = true status when setting widget active
  // modified "true" essentially makes widget prompt a confirm modal - in this case that is not wanted
  dispatchSetWidgetActive: () => { dispatch(setWidgetActive(BUILDING_ELEMENTS_TASK, undefined, true)) },
  dispatchPostPolling: () => { dispatch(postPolling({ preventEnableAllWidgets: true })) },
  dispatchRemoveBuildingTaskSchedule: (id: string) => { dispatch(removeBuildingTaskSchedule(id)) },
  dispatchCloseWidgetAndEnableOtherWidgets: () => {
    dispatch(enableAllWidgets())
    dispatch(closeWidget(BUILDING_ELEMENTS_TASK))
  },
})

type ReceivedAndHOCProps = {
  ...HOCProps,
  ...ReceivedProps
}

const mapStateToProps = (
  {
    app: {
      activeCalculation,
      isEstimateLockedToCurrentUser,
    },
    list,
    properties,
    activeEstimate
  }: TVDReduxStore,
  { t, listStoreId }: ReceivedAndHOCProps,
): MappedProps => ({
  data: list[listStoreId] && list[listStoreId].listItems,
  steps: [t('tasks._TASK_SELECT'), t('tasks._TASK_IMPORT')],
  properties: properties[listStoreId],
  activeCalculation,
  isEstimateLockedToCurrentUser,
  isEstimateFrozen: !!activeEstimate?.frozen
})

export default compose(
  withTranslation('translations'),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(BuildingElementsTask)
