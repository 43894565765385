// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import {
  SET_COMPONENT_PREFERENCES_SELECTED_TAB,
  REMOVE_COMPONENT_PREFERENCES,
  REMOVE_ALL_COMPONENT_PREFERENCES,
  SET_CATEGORY_ROW_OPEN_COMPONENT_PREFERENCE,
  SET_SCROLL_POSITION_COMPONENT_PREFERENCE,
  SET_X_Y_COMPONENT_PREFERENCE,
  SET_SIZE_COMPONENT_PREFERENCE,
  SET_OPEN_ROW_IDS_COMPONENT_PREFERENCE,
  SET_SHOW_SPACE_LIST_PROPERTIES_COMPONENT_PREFERENCE
} from '../actions/componentPreferences'

const initialState: ComponentPreferencesStore = {}

export default function componentPreferences(state: Object = initialState, action: Object = {}): Object {
  // we use lower case due to the way constants are used in front-end repository which is not consistent across the repo
  // i.e sometimes constant for same thing is used with the upper casing e.g SPACE vs space
  const componentId = action.payload?.componentId?.toLowerCase()
  const previousComponentPreferences = state[componentId] || {}

  switch (action.type) {
    case SET_COMPONENT_PREFERENCES_SELECTED_TAB: {
      const {
        payload: {
          selectedTab
        }
      } = action
      return {
        ...state,
        [componentId]: {
          ...previousComponentPreferences,
          selectedTab
        }
      }
    }
    case REMOVE_COMPONENT_PREFERENCES: {
      const { [componentId]: removedComponentPreferences, ...restOfState } = state
      return restOfState
    }
    case REMOVE_ALL_COMPONENT_PREFERENCES: {
      return Object.keys(state)
        .reduce((filteredPreferences: Object, id: string): Object => {
          const componentPreference = state[id]
          if (componentPreference.meta?.isPersisted) {
            return {
              ...filteredPreferences,
              [id]: componentPreference
            }
          }
          return filteredPreferences
        }, {})
    }
    case SET_CATEGORY_ROW_OPEN_COMPONENT_PREFERENCE: {
      const {
        payload: {
          rowId,
          isOpen
        }
      } = action

      return {
        ...state,
        [componentId]: {
          ...previousComponentPreferences,
          categoryRows: {
            ...(previousComponentPreferences.categoryRows || {}),
            [rowId]: isOpen
          }
        }
      }
    }
    case SET_SCROLL_POSITION_COMPONENT_PREFERENCE: {
      const {
        payload: {
          scrollPosition
        }
      } = action
      return {
        ...state,
        [componentId]: {
          ...previousComponentPreferences,
          scrollPosition,
        },
      }
    }
    case SET_X_Y_COMPONENT_PREFERENCE: {
      const {
        payload: {
          xy
        }
      } = action
      return {
        ...state,
        [componentId]: {
          ...previousComponentPreferences,
          xy
        },
      }
    }

    case SET_SIZE_COMPONENT_PREFERENCE: {
      const {
        payload: {
          size
        }
      } = action
      return {
        ...state,
        [componentId]: {
          ...previousComponentPreferences,
          size
        },
      }
    }

    case SET_OPEN_ROW_IDS_COMPONENT_PREFERENCE: {
      const {
        payload: {
          openRowIds
        }
      } = action
      return {
        ...state,
        [componentId]: {
          ...previousComponentPreferences,
          openRowIds
        },
      }
    }

    case SET_SHOW_SPACE_LIST_PROPERTIES_COMPONENT_PREFERENCE: {
      const {
        payload: {
          showSpaceProperties,
          showSpaceGroupProperties,
          showLabels
        }
      } = action
      return {
        ...state,
        [componentId]: {
          ...previousComponentPreferences,
          meta: {
            ...previousComponentPreferences.meta,
            isPersisted: true
          },
          preferences: {
            ...previousComponentPreferences.preferences,
            showSpaceProperties,
            showSpaceGroupProperties,
            showLabels
          }
        },
      }
    }

    default: return state
  }
}
