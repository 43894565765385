// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { unmountComponentAtNode } from 'react-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import SpaceEquipmentProductAssemblyMFEContainer, {
  spaceEquipmentProductAssemblyMFERootId
} from '../SpaceEquipmentProductAssemblyMFEContainer/SpaceEquipmentProductAssemblyMFEContainer'
import SpaceSurfaceProductAssemblyMFEContainer, {
  spaceSurfaceProductAssemblyMFERootId
} from '../SpaceSurfaceProductAssemblyMFEContainer/SpaceSurfaceProductAssemblyMFEContainer'
import BuildingElementsProductAssemblyMFEContainer, {
  buildingElementsProductAssemblyMFERootId
} from '../BuildingElementsProductAssemblyMFEContainer/BuildingElementsProductAssemblyMFEContainer'
import BuildingElementsAssemblyPriceListMFEContainer, {
  buildingElementsAssemblyPriceListMFERootId
} from '../BuildingElementsAssemblyPriceListMFEContainer/BuildingElementsAssemblyPriceListMFEContainer'
import CustomBuildingElementAssemblyModalMFEContainer, {
  customBuildingElementAssemblyModalMFERootId
} from '../CustomBuildingElementAssemblyModalMFEContainer/CustomBuildingElementAssemblyModalMFEContainer'
import ImportFromBEEstimateWidgetMFEContainer, {
  importFromBEEstimateMFERootId
} from '../ImportFromBEEstimateWidgetMFEContainer/ImportFromBEEstimateWidgetMFEContainer'
import EstimateNotesMFEContainer, {
  estimateNotesMFERootId
} from '../EstimateNotesMFEContainer/EstimateNotesMFEContainer'
import ImportFromAnotherEstimateWidgetMFEContainer, {
  importFromSpaceScheduleWidgetMFERootId
} from '../ImportFromAnotherEstimateWidgetMFEContainer/ImportFromAnotherEstimateWidgetMFEContainer'
import BuildingElementsScheduleWidgetMFEContainer, {
  buildingElementsScheduleWidgetMFERootId
} from '../BuildingElementsScheduleWidgetMFEContainer/BuildingElementsScheduleWidgetMFEContainer'
import SpacesLifecycleCO2WidgetMFEContainer, {
  spacesLifecycleCO2WidgetMFERootId
} from '../SpacesLifecycleCO2WidgetMFEContainer/SpacesLifecycleCO2WidgetMFEContainer'
import SiteEquipmentProductAssemblyMFEContainer, {
  siteEquipmentProductAssemblyMFERootId
} from '../SiteEquipmentProductAssemblyMFEContainer/SiteEquipmentProductAssemblyMFEContainer'

type MappedProps = {|
  MFEStates: $PropertyType<TVDReduxStore, 'MFEStates'>
|}

type Props = {|
  ...MappedProps
|}

const MFEContainerComponents = {
  SpaceEquipmentProductAssemblyMFEContainer: {
    component: SpaceEquipmentProductAssemblyMFEContainer,
    rootId: spaceEquipmentProductAssemblyMFERootId
  },
  SpaceSurfaceProductAssemblyMFEContainer: {
    component: SpaceSurfaceProductAssemblyMFEContainer,
    rootId: spaceSurfaceProductAssemblyMFERootId
  },
  BuildingElementsProductAssemblyMFEContainer: {
    component: BuildingElementsProductAssemblyMFEContainer,
    rootId: buildingElementsProductAssemblyMFERootId
  },
  BuildingElementsAssemblyPriceListMFEContainer: {
    component: BuildingElementsAssemblyPriceListMFEContainer,
    rootId: buildingElementsAssemblyPriceListMFERootId
  },
  CustomBuildingElementAssemblyModalMFEContainer: {
    component: CustomBuildingElementAssemblyModalMFEContainer,
    rootId: customBuildingElementAssemblyModalMFERootId
  },
  ImportFromBEEstimateWidgetMFEContainer: {
    component: ImportFromBEEstimateWidgetMFEContainer,
    rootId: importFromBEEstimateMFERootId
  },
  EstimateNotesMFEContainer: {
    component: EstimateNotesMFEContainer,
    rootId: estimateNotesMFERootId
  },
  ImportFromAnotherEstimateWidgetMFEContainer: {
    component: ImportFromAnotherEstimateWidgetMFEContainer,
    rootId: importFromSpaceScheduleWidgetMFERootId
  },
  BuildingElementsScheduleWidgetMFEContainer: {
    component: BuildingElementsScheduleWidgetMFEContainer,
    rootId: buildingElementsScheduleWidgetMFERootId
  },
  SpacesLifecycleCO2WidgetMFEContainer: {
    component: SpacesLifecycleCO2WidgetMFEContainer,
    rootId: spacesLifecycleCO2WidgetMFERootId
  },
  SiteEquipmentProductAssemblyMFEContainer: {
    component: SiteEquipmentProductAssemblyMFEContainer,
    rootId: siteEquipmentProductAssemblyMFERootId
  }
}

class FloatingWindowContainer extends Component<Props> {
  render(): React$Element<any> {
    const { MFEStates } = this.props
    return (
      <div className='floating-window-container'>
        { MFEStates !== null &&
          Object.keys(MFEStates).map((MFEKey: string) => {
            const {
              componentName,
              isOpen,
              contentProps
            } = MFEStates[MFEKey]
            const MFEContainerComponent = MFEContainerComponents[componentName].component
            if (MFEContainerComponent && isOpen) {
              return <MFEContainerComponent key={`${MFEKey}${componentName}`} contentProps={contentProps} />
            } else if (MFEContainerComponent && !isOpen) {
              const element = document.getElementById(MFEContainerComponents[componentName].rootId)
              if (element) {
                unmountComponentAtNode(element)
              }
              return null
            }
            return null
          })
        }
      </div>
    )
  }
}

const mapStateToProps = ({ MFEStates }: TVDReduxStore): MappedProps => ({
  MFEStates
})

export default compose(connect(mapStateToProps))(FloatingWindowContainer)

