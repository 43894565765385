// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
// @flow
import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Icon } from '@material-ui/core'
import { map, isEqual, isEmpty, filter as FilterFn } from 'lodash'
import { borderRadiuses } from 'frontend-assets'
import RelationalItem from './RelationalItem'
import LabeledInput from '../../LabeledInput/LabeledInput'
import Tooltip from '../../Tooltip/Tooltip'
import theme from '../../../../styles/theme'
// $FlowFixMe
import { ReactComponent as SVGIconOtherSearch } from '../../../../../node_modules/frontend-assets/static/assets/images/icons/Other Search.svg'
// $FlowFixMe
import { ReactComponent as SVGIconOtherClose } from '../../../../../node_modules/frontend-assets/static/assets/images/icons/Other Close.svg'

const { borderRadiusNormal } = borderRadiuses

const styles = ({ palette }: Object): Object => ({
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '274px',
    width: '100%',
    margin: '0px 10px'
  },
  columnHeader: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '78px',
    maxHeight: '78px',
    alignItems: 'center',
    marginRight: '16px'
  },
  titleText: {
    ...theme.typography.classes.h4,
    display: 'flex',
    color: palette.dark80,
    alignItems: 'center',
  },
  searchField: {
    paddingBottom: '5px',
    marginTop: '-16px'
  },
  dropdown: {
    position: 'relative',
    top: '-11px',
    right: '0'
  },
  iconContainer: {
    cursor: 'pointer',
    paddingRight: '4px',
    '&:hover': {
      cursor: 'pointer',
      '& path': {
        fill: palette.primary100
      }
    }
  },
  icon: {
    cursor: 'pointer',
    paddingLeft: '5px',
    '&:hover path': {
      fill: palette.primary100,
    },
  },
  singleRelationalItem: {
    borderRadius: borderRadiusNormal
  },
  firstRealationalItem: {
    borderRadius: `${borderRadiusNormal}px ${borderRadiusNormal}px 0px 0px`
  },
  lastRealationalItem: {
    borderRadius: `0px 0px ${borderRadiusNormal}px ${borderRadiusNormal}px`
  }
})

export type ColumnStates = 'basic' | 'focused' | 'focusing' | 'active'
export type ColumnMode = {|
  mode: ColumnStates,
  userSelected: boolean,
|}

export type RelationalColumnProps = {|
  cells: Array<Object>, // function that should return an array of Cells for the column
  columnName: string, // unique name for the column for identification purposes in the container
  disabled: boolean, // if the column is disabled
  focusingTooltip: string, // tooltip text for a 'focusing' state column with more than one item
|}

type Props = {|
  ...RelationalColumnProps,
  classes: Object, // classes-object created by withstyles function
  dropdown: Array<Object>, // a dropdowncontainer element if the column should have one
  id: string, // for e2e testing purposes,
  onSingleItemFocus: (columnName: string, cellID: number | typeof undefined) => void, // called in componentDidMount to send a single cell id or undefined if the column is not at max focus
  onUserSelect: (columnName: string, cellId: string, index: number) => void, // function that fires on cell select
  onUnselect: (columnName: string, index: number) => void, // function that fires on cell unselect
  title: string, // localized title of column
  searchFieldLabel: string, // localized title of the search field,
  disabled: boolean, // flag for disabling the column and its RelationalItems
  children: any, // should be a FloatingForm, mapped and cloned in this component
  focusedId: string | typeof undefined, // used to prevent edge case neverending loops in componentDidUpdate
  mode: ColumnStates, // current state for the column,
  userSelected: boolean, // whether this column is in a userSelected state (= show closing icon)
  isPathClear: (index: number) => boolean,
  index: number, // the position index of the column in the selector
|}

type State = {|
  showSearchIcon: boolean, // if true, searchIcon is visible
  showSearchField: boolean, // if true, searchField is visible
  filter: string, // column results filtering string
  selected: Object, // the selected cell currently in the column
|}

export class RelationalColumn extends PureComponent<Props, State> {
  static defaultProps = {
    selected: {},
    cells: [],
    mode: 'basic',
    userSelected: false,
    dropdown: null,
    focusedId: undefined,
    onSingleItemFocus: () => {},
    onUserSelect: () => {},
    onUnselect: () => {},
    disabled: false,
    focusingTooltip: '',
  }

  state = {
    showSearchIcon: false,
    showSearchField: false,
    filter: '',
    selected: {},
  }

  componentDidUpdate(prevProps: Object) {
    const { cells: prevCells } = prevProps
    const {
      cells: currentCells,
      focusedId,
      userSelected,
      onSingleItemFocus,
      columnName,
      mode,
    } = this.props

    // these checks are to acertain whether a column that the user has not interacted with but exists in the selection path has ended up with a single item.
    // that information is required for determining whether the current path is unambiguous and if a column should render
    // its children (most likely a Floating Form)
    // therefore these checks are not necessary for:
    // active (user selected)
    // basic(the RelationalSelector is clear of selections) or
    // focused(they either are user focused, or exist after a selection and are not a concern for unambiguous path considerations)
    if (mode === 'focusing') {
      // column has received only a single item twice in a row
      if (currentCells.length === 1 && prevCells.length === 1) {
        // check if the item is different - possible if focusing action has happened in previous columns
        // if it's the same item no action is necessary, it was saved the last time around -> prevent a forever loop
        if (!isEqual(currentCells[0], prevCells[0])) {
          onSingleItemFocus(columnName, currentCells[0].value)
        }
      }
      // a previously multi-item column now received only one item - means the column is at max focus
      if (currentCells.length === 1 && prevCells.length !== 1) {
        onSingleItemFocus(columnName, currentCells[0].value)
      }

      // recieved more than one cell but had a focusedID -> remove the focused id, we're not in Max Focus anymore
      if (currentCells.length !== 1 && focusedId) {
        onSingleItemFocus(columnName, undefined)
      }
    }

    if (userSelected) {
      if (currentCells.length === 1 && prevCells.length === 1) {
        // if a user selected column has received a single item in an update twice in a row we need to check
        // if the item is the same. It might have updated due to a focusing choice in another column
        // ie. Aula in Spaces column as the first selection might start out as a hotel lobby. A focusing choice in a previous
        // column could change it to a High School lobby instead - it still remains userSelected throughout.
        if (!isEqual(currentCells[0], prevCells[0])) {
          onSingleItemFocus(columnName, currentCells[0].value)
          // setting selected state with the right data, including localizedName
          this.setState({
            selected: currentCells[0]
          })
        }
      }
    }
  }

  get header(): React$Element<any> {
    const {
      classes,
      title,
      dropdown,
    } = this.props
    const toggleSearchIcon = (bool: boolean) => this.setState({ showSearchIcon: bool })

    return (
      <div className={classes.columnHeader} onMouseEnter={() => toggleSearchIcon(true)} onMouseLeave={() => toggleSearchIcon(false)}>
        <span className={classes.titleText}>
          { title }
          { this.state.showSearchIcon &&
          <Icon id='searchIcon' onClick={() => this.toggleSearchField()} className={classes.icon}>
            <SVGIconOtherSearch />
          </Icon> }
        </span>
        <div className={classes.dropdown}>
          { dropdown }
        </div>
      </div>
    )
  }

  get searchField(): React$Element<LabeledInput> {
    const { classes, searchFieldLabel } = this.props

    const updateField = (e: SyntheticInputEvent<any>) => this.setState({ filter: e.target.value })

    const closeField = () => {
      this.toggleSearchField()
    }

    const inputProps = {
      value: this.state.filter,
      handleChange: updateField,
      size: 'FULL',
      isValid: true
    }

    return (
      <div className={classes.searchField}>
        <LabeledInput
          {...inputProps}
          focused
          id='searchField'
          label={searchFieldLabel}
          disableValidation
          adornment={
            <div onClick={closeField} className={classes.iconContainer} >
              <SVGIconOtherClose className={classes.icon} />
            </div>
          } />
      </div>
    )
  }

  handleCellClick = (cellID: string, cellName: string) => {
    const { columnName, index } = this.props
    this.setState({ selected: { value: cellID, localizedName: cellName } }, () => {
      this.props.onUserSelect(columnName, cellID, index)
    })
  }

  handleCellClose = () => this.props.onUnselect(this.props.columnName, this.props.index)

  toggleSearchField = () => this.setState((state: State) => ({ showSearchField: !state.showSearchField, filter: '' }))

  renderChildren = () => {
    const {
      children,
      title,
      cells,
      columnName,
    } = this.props

    // cells are done separately, this is mostly for Floating Forms.
    if (cells.length === 1) {
      return (
        React.Children.map(children, (child: Object) => React.cloneElement(child, {
          title,
          selectedCell: cells[0],
          columnName
        }))
      )
    }
    return null
  }

  cells(): any {
    const {
      mode,
      disabled,
      userSelected,
      cells: propCells,
      classes
    } = this.props
    const { filter, selected } = this.state

    const cells = !isEmpty(selected) && userSelected ? [selected] : propCells

    const filteredItems = () => {
      if (filter === '') return cells
      return FilterFn(cells, (cell: Object) => cell.localizedName.toUpperCase().includes(filter.toUpperCase()))
    }

    const items = filteredItems()

    return (
      map(items, (item: Object, index: number) => {
        let className = null
        switch (true) {
          case items.length === 1: {
            className = classes.singleRelationalItem
            break
          }
          case index === 0: {
            className = classes.firstRealationalItem
            break
          }
          case index === (items.length - 1): {
            className = classes.lastRealationalItem
            break
          }
          default: break
        }
        return ((
          <RelationalItem
            className={className}
            onClick={this.handleCellClick}
            onCellClose={this.handleCellClose}
            value={item.value}
            mode={mode}
            selected={userSelected}
            key={index}
            disabled={disabled}
            localizedName={item.localizedName} />
        ))
      })
    )
  }

  render(): React$Element<any> {
    const {
      classes,
      mode,
      isPathClear,
      disabled,
      id,
    } = this.props

    const { showSearchField } = this.state
    const cells = this.cells()
    const showTooltip = cells.length > 1 && mode === 'focusing' && !disabled
    return (
      <div className={classes.columnContainer}>
        { this.header }
        { showSearchField && this.searchField }
        <Tooltip
          disablePortal
          text={this.props.focusingTooltip}
          placement='top'
          open={showTooltip}>
          <div style={{ height: '100%', overflowX: 'hidden' }} data-testid={id}>
            {cells}
            { mode === 'active' && isPathClear ? this.renderChildren() : null }
          </div>
        </Tooltip>
      </div>
    )
  }
}

export default withStyles(styles)(RelationalColumn)
