// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

export const SPACE_EQUIPMENT_PRODUCT_ASSEMBLY_MFENAME = 'space-equipment-product-assembly-widget'
export const SPACE_EQUIPMENT_PRODUCT_ASSEMBLY_COMPONENTNAME = 'SpaceEquipmentProductAssemblyMFEContainer'

export const SPACE_SURFACE_PRODUCT_ASSEMBLY_MFENAME = 'space-surface-product-assembly-widget'
export const SPACE_SURFACE_PRODUCT_ASSEMBLY_COMPONENTNAME = 'SpaceSurfaceProductAssemblyMFEContainer'

export const BUILDING_ELEMENTS_PRODUCT_ASSEMBLY_MFENAME = 'building-elements-product-assembly-widget'
export const BUILDING_ELEMENTS_PRODUCT_ASSEMBLY_COMPONENTNAME = 'BuildingElementsProductAssemblyMFEContainer'

export const BUILDING_ELEMENTS_ASSEMBLY_PRICE_LIST_MFENAME = 'building-elements-assembly-price-list-widget'
export const BUILDING_ELEMENTS_ASSEMBLY_PRICE_LIST_COMPONENTNAME = 'BuildingElementsAssemblyPriceListMFEContainer'

export const CUSTOM_BUILDING_ELEMENT_ASSEMBLY_MODAL_MFENAME = 'custom-building-element-assembly-modal'
export const CUSTOM_BUILDING_ELEMENT_ASSEMBLY_MODAL_COMPONENTNAME = 'CustomBuildingElementAssemblyModalMFEContainer'

export const IMPORT_FROM_BE_ESTIMATE_WIDGET_MFENAME = 'import-from-be-estimate-widget'
export const IMPORT_FROM_BE_ESTIMATE_WIDGET_COMPONENTNAME = 'ImportFromBEEstimateWidgetMFEContainer'

export const ESTIMATE_NOTES_WIDGET_MFENAME = 'containers-estimate-notes-widget'
export const ESTIMATE_NOTES_WIDGET_COMPONENTNAME = 'EstimateNotesMFEContainer'

export const IMPORT_FROM_SPACE_SCHEDULE_WIDGET_MFENAME = 'import-from-space-schedule-widget'
export const IMPORT_FROM_SPACE_SCHEDULE_WIDGET_COMPONENTNAME = 'ImportFromAnotherEstimateWidgetMFEContainer'

export const BUILDING_ELEMENTS_SCHEDULE_WIDGET_MFENAME = 'building-elements-schedule-widget'
export const BUILDING_ELEMENTS_SCHEDULE_WIDGET_COMPONENTNAME = 'BuildingElementsScheduleWidgetMFEContainer'

export const SPACES_LIFECYCLE_CO2_WIDGET_MFENAME = 'spaces-lifecycle-co2-widget'
export const SPACES_LIFECYCLE_CO2_WIDGET_COMPONENTNAME = 'SpacesLifecycleCO2WidgetMFEContainer'

export const SITE_EQUIPMENT_PRODUCT_ASSEMBLY_MFENAME = 'site-equipment-product-assembly-widget'
export const SITE_EQUIPMENT_PRODUCT_ASSEMBLY_COMPONENTNAME = 'SiteEquipmentProductAssemblyMFEContainer'
