// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { filter } from 'lodash'
import { typographyClasses, colors } from 'frontend-assets'

import { filterListItemsWithFilterFn, clearListItemsFilter } from '../../../../actions/list'
import { openModal, closeModal, buildWopCreateModal } from '../../../../actions/modals'
import { type MenuItemProps } from '../../../common/menus/components/MenuItems/MenuItem/MenuItem'
import HamburgerMenu from '../../../common/menus/HamburgerMenu/HamburgerMenu'
import DescriptionCell from '../../../common/lists/common/DescriptionCell/DescriptionCell'
import WopSpaceSchedule from '../WopSpaceSchedule/WopSpaceSchedule'
import ResultBarContainer from '../../ResultBar/ResultBarContainer'
import HierarchicalListContainer from '../../HierarchicalListContainer/HierarchicalListContainer'
import ControlBar from '../../../common/ControlBar/ControlBar'

import { getNextArrayItem, removeHighlight } from '../../../../utils/commonUtils'
import { parseHierarchyTooltip } from '../../../../utils/parseUtil'
import { getSelectedListItemsByType } from '../../../../utils/listUtils'
import {
  WOP_GROUPING_RENAME_MODAL,
  FUNCTIONALSECTORGROUP,
  FUNCTIONGROUP,
  PROCESSGROUP,
  PROCESS,
  GROUPING_VIEW,
  WOP_GROUPING_MOVE_MODAL,
  WOP_SPACE_SCHEDULE,
  CONFIRMATION_MODAL,
  ACTIVITY_STRUCTURE,
  GROUPINGFUNCTIONALSECTORGROUP,
  GROUPINGFUNCTIONGROUP,
  GROUPINGPROCESSGROUP
} from '../../../../constants/contentTypes'
import { CHANGE_ORDER_FIRST, CHANGE_ORDER_UP, CHANGE_ORDER_DOWN, CHANGE_ORDER_LAST } from '../../../../constants/requestConstants'
import { DESCRIPTION } from '../../../../constants/attributes'
import { postPolling } from '../../../../actions/postPolling'

import {
  patchGroupingScheduleFunctionalSectorGroupsWithItemIdRequest,
  patchGroupingScheduleFunctionGroupsWithItemIdRequest,
  patchGroupingScheduleProcessGroupsWithItemIdRequest,
  getGroupingScheduleColumnsWithEstimateIdRequest,
  getGroupingScheduleWithEstimateIdRequest,
  deleteGroupingScheduleFunctionalSectorGroupsWithItemIdRequest,
  deleteGroupingScheduleFunctionGroupsWithItemIdRequest,
  deleteGroupingScheduleProcessGroupsWithItemIdRequest,
} from '../../../../utils/generated-api-requests/wop'

const { h4 } = typographyClasses
const { dark80 } = colors

const styles = ({ palette, typography }: TVDTheme): Object => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1',
    overflowY: 'auto'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1'
  },
  groupingScheduleHeaderText: {
    ...h4,
    color: dark80,
    padding: '48px 24px 24px'
  },
  footer: {
    display: 'flex',
    font: `12px ${typography.fontFamilyBase}`,
    color: palette.nevada,
    paddingLeft: '30px',
    marginBottom: '25px',
    whiteSpace: 'pre-wrap', // to wrap text on line breaks
  },
  listContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  },
  spaceScheduleWrapper: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    borderLeft: `1px solid ${palette.geyser}`,
    overflow: 'hidden'
  },
  descriptionCell: {
    alignItems: 'center',
    display: 'flex',
  },
  wrappedCellDescription: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  highlightedMessage: {
    justifyContent: 'left',
    color: palette.primary100,
    fontFamily: typography.fontFamily,
    fontSize: 16,
    width: 500
  }
})

type HOCProps = {|
  classes: Object, // withStyles classes object
  t: Function, // translate function
|}

type ReceivedProps = {|
  widgetId: string, // the Store id in "widgets" ReduxStore
  listStoreId: string, // unique id for the HLC instance in "list" ReduxStore - in this context id of WopSpaceSchedule
  groupingListStoreId: string, // unique id for the HLC instance in "list" ReduxStore - in this context id of GroupingSchedule
  isEstimateLockedToCurrentUser: boolean // if the user owns the lock for the estimate
|}

type MappedProps = {|
  groupingListItems: TVDWOPListItems, // listItems of groupingSchedule
  activeEdit: boolean, // boolean to tell if app edit mode is active
  languageCode: string, // current language
  list: Object, // reduxstore list reducer
|}

type DispatchProps = {|
  dispatchOpenModal: (content: Object, id: string) => void, // open a modal with desired content
  dispatchCloseWopRow: Function, // closing modal action
  dispatchBuildWopCreateModal: (type: string, id: number, listId: string, onSaveCb: Function) => void, // open createItem modal
  dispatchDeleteWopRow: (content: Object) => void, // delete wop row action
  dispatchFilterListItemsWithFilterFn: ((listItem: TVDWOPListItem) => boolean) => void, // toggle list filter based on if we show or not the passive rows
  dispatchClearListFilter: () => void, // clear list filter from list that has matching store id of groupingListStoreId
  dispatchPostPolling: () => void, // initiates post polling
|}

type Props = {|
  ...HOCProps,
  ...ReceivedProps,
  ...MappedProps,
  ...DispatchProps
|}

type State = {|
    showPassiveProcess: boolean,
    showEmptyHeadings: boolean
|}

export class Grouping extends Component<Props, State> {
  state = {
    showPassiveProcess: false,
    showEmptyHeadings: false
  }

  componentDidMount() {
    this.getGroupingScheduleData()
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.languageCode !== this.props.languageCode) {
      this.getGroupingScheduleItems(true)
      this.getGroupingSchedule()
      this.getSpaceSchedule()
    }
  }

  isPassiveFilter = (listItem: TVDWOPListItem): boolean => !listItem.isPassive

  applyPassiveProcessSettings = () => {
    if (this.state.showPassiveProcess) {
      this.props.dispatchClearListFilter()
    } else {
      this.props.dispatchFilterListItemsWithFilterFn(this.isPassiveFilter)
    }
  }

  getGroupingScheduleItems = (shouldNotUpdateListLastUpdated?: boolean) => {
    const { groupingListStoreId } = this.props
    getGroupingScheduleWithEstimateIdRequest(
      { query: { listType: 'flat', includeEmpty: this.state.showEmptyHeadings } },
      { listStoreId: groupingListStoreId, shouldNotUpdateListLastUpdated, mergeOptions: { unselectAllListItems: true } },
      this.applyPassiveProcessSettings
    )
  }

  getGroupingScheduleData = () => {
    const { groupingListStoreId } = this.props
    Promise.all([
      getGroupingScheduleColumnsWithEstimateIdRequest({ listStoreId: groupingListStoreId, shouldNotUpdateListLastUpdated: true }),
      this.getGroupingScheduleItems()
    ])
  }

  getGroupingCheckBoxes = (): Array<TVDCheckBoxProps> => {
    const { t } = this.props
    return [
      {
        name: 'toggleProcesses',
        label: t('groupingWidget._SHOW_PASSIVE_PROCESSES_'),
        checked: this.state.showPassiveProcess,
        onChange: () => {
          this.setState((state: State) => ({ showPassiveProcess: !state.showPassiveProcess }), this.applyPassiveProcessSettings)
        }
      },
      {
        name: 'toggleHeadings',
        label: t('groupingWidget._SHOW_EMPTY_HEADING_ROWS_'),
        checked: this.state.showEmptyHeadings,
        onChange: () => {
          this.setState((state: State) => ({ showEmptyHeadings: !state.showEmptyHeadings }), this.getGroupingScheduleItems)
        }
      }
    ]
  }

  getGroupingCheckboxBar = (): React$Element<ControlBar> => (<ControlBar checkboxes={this.getGroupingCheckBoxes()} />)

  getGroupingTitle = (): React$Element<'div'> => {
    const { classes, t } = this.props
    return <div className={classes.groupingScheduleHeaderText}>{ t('widgets._GROUPING_TITLE_') }</div>
  }


  getIsPassiveIndicator = (row: TVDWOPListItem): string => {
    const { t } = this.props
    const { type } = row
    switch (type.toUpperCase()) {
      case PROCESS: return `(${t('groupingWidget._PASSIVE_')})`
      case PROCESSGROUP: return '*'
      default: {
        console.error(`No passive indicator for type ${type}`)
        return ''
      }
    }
  }

  getDescriptionCellContent = ({
    content, row, rowRef, highlightedRow
  }: TVDWOPWrappedCellCallbackParameters): React$Element<'div'> => {
    const { classes } = this.props
    const parsedHierarchyText = parseHierarchyTooltip(row.hierarchy)
    const checktRowType = !(row.type === FUNCTIONGROUP.toLowerCase() || row.type === FUNCTIONALSECTORGROUP.toLowerCase())
    return (
      <div className={classes.descriptionCell}>
        <DescriptionCell
          noPointer
          highlighted={checktRowType}
          tooltipText={parsedHierarchyText}
          text={row.isPassive ? `${content} ${this.getIsPassiveIndicator(row)}` : content} />
        {this.getIconsContainer(row, rowRef, highlightedRow)}
      </div>
    )
  }

  openMoveModal = (listItem: TVDWOPListItem) => {
    const {
      t,
      dispatchOpenModal,
      groupingListStoreId,
      groupingListItems
    } = this.props
    const { type } = listItem

    const selectedItems = getSelectedListItemsByType(groupingListItems, type)
    const multiple = selectedItems.length > 1
    const typeUpperCase = type.toUpperCase()
    const translatedType = multiple ? t(`groupingWidget._${typeUpperCase}_MULTIPLE_`) : t(`groupingWidget._${typeUpperCase}_`)

    dispatchOpenModal({
      title: t('groupingWidget._MOVE_', {
        // when multiple, use string template to add colon to title e.g Siirrä prosessit: 2kpl
        type: multiple ? `${translatedType}:` : translatedType,
        amount: multiple ? selectedItems.length : '',
        unit: multiple ? t('groupingWidget._MOVE_AMOUNT_UNIT_') : ''
      }),
      type: WOP_GROUPING_MOVE_MODAL,
      contentProps: {
        listItem,
        listStoreId: groupingListStoreId,
        showEmptyHeadings: this.state.showEmptyHeadings,
        message: t('groupingWidget._CHOOSE_WHERE_TO_MOVE_TO', { type: translatedType.toLowerCase() }),
        successCb: this.applyPassiveProcessSettings
      }
    }, WOP_GROUPING_MOVE_MODAL)
  }

  getMoveMenuItem = (listItem: TVDWOPListItem, rowRef?: TVDRef, highlightedRow?: string): TVDMenuItem => {
    const { t, isEstimateLockedToCurrentUser } = this.props
    return {
      localizedName: t('groupingWidget._MOVE_'),
      disabled: !isEstimateLockedToCurrentUser,
      onClick: () => {
        this.openMoveModal(listItem)
        removeHighlight(rowRef, highlightedRow)
      }
    }
  }

  getRenameMenuItem = (listItem: TVDWOPListItem, rowRef?: TVDRef, highlightedRow?: string): TVDMenuItem => {
    const {
      t, dispatchOpenModal, groupingListStoreId, isEstimateLockedToCurrentUser
    } = this.props
    return {
      localizedName: t('buttons._RENAME_'),
      disabled: !isEstimateLockedToCurrentUser,
      onClick: () => {
        dispatchOpenModal({
          type: WOP_GROUPING_RENAME_MODAL,
          title: t('buttons._RENAME_'),
          contentProps: {
            listItem,
            listStoreId: groupingListStoreId
          },
        }, WOP_GROUPING_RENAME_MODAL)
        removeHighlight(rowRef, highlightedRow)
      },
      testId: 'ContextMenuItem-rename',
    }
  }

  getDeleteMenuItem = (listItem: TVDWOPListItem, rowRef?: TVDRef, highlightedRow?: string) => {
    const {
      classes, t, dispatchDeleteWopRow, dispatchCloseWopRow, dispatchPostPolling, isEstimateLockedToCurrentUser
    } = this.props

    const { type } = listItem
    const typeUpperCase = type.toUpperCase()
    const translatedType = t(`groupingWidget._${typeUpperCase}_`)

    return {
      localizedName: t('buttons._DELETE_'),
      disabled: !isEstimateLockedToCurrentUser,
      onClick: () => {
        if (listItem.canHaveChildren) {
          const content = {
            type: CONFIRMATION_MODAL,
            saveButtonText: 'groupingWidget._GROUPING_WARNING_DELETE_BUTTON_',
            hideCancel: true,
            onSave: () => dispatchCloseWopRow(),
            title: 'groupingWidget._GROUPING_WARNING_DELETE_HEADER_',
            message: [
              t('groupingWidget._GROUPING_FIRST_WARNING_DELETE_MESSAGE_', { type: translatedType }),
              t('groupingWidget._GROUPING_SECOND_WARNING_DELETE_MESSAGE_', { type: translatedType.toLowerCase() })
            ]
          }
          dispatchDeleteWopRow(content)
          removeHighlight(rowRef, highlightedRow)
          return
        }

        const postDeleteActions = () => {
          dispatchPostPolling()
          this.getGroupingScheduleItems()
          dispatchCloseWopRow()
        }

        const content = {
          type: CONFIRMATION_MODAL,
          saveButtonText: 'groupingWidget._GROUPING_DELETE_BUTTON_',
          title: `groupingWidget._GROUPING_DELETE_${listItem.type.toUpperCase()}_HEADER_`,
          message: [
            t(`groupingWidget._GROUPING_DELETE_${listItem.type.toUpperCase()}_MESSAGE_`),
            <span className={classes.highlightedMessage}>{listItem.columnData.Description}</span>
          ],
          onClose: () => dispatchCloseWopRow(),
          onSave: () => {
            switch (listItem.type) {
              case GROUPINGFUNCTIONALSECTORGROUP: {
                deleteGroupingScheduleFunctionalSectorGroupsWithItemIdRequest(
                  { path: { itemId: listItem.wopItemId } },
                  {},
                  postDeleteActions
                )
                break
              }
              case GROUPINGFUNCTIONGROUP: {
                deleteGroupingScheduleFunctionGroupsWithItemIdRequest(
                  { path: { itemId: listItem.wopItemId } },
                  {},
                  postDeleteActions
                )
                break
              }
              case GROUPINGPROCESSGROUP: {
                deleteGroupingScheduleProcessGroupsWithItemIdRequest(
                  { path: { itemId: listItem.wopItemId } },
                  {},
                  postDeleteActions
                )
                break
              }
              default:
                break
            }
          },
        }
        dispatchDeleteWopRow(content)
        removeHighlight(rowRef, highlightedRow)
      },
      testId: 'ContextMenuItem-delete'
    }
  }

  getOpenCreateModalRowMenuItem = (
    rowType: string,
    parentRowId: number = 0,
    row: TVDWOPListItem,
    rowRef?: TVDRef, highlightedRow?: string
  ): TVDMenuItem => {
    const {
      t, dispatchBuildWopCreateModal, groupingListStoreId, isEstimateLockedToCurrentUser
    } = this.props

    return {
      localizedName: t(`singleInputModal._NEW_${rowType.toUpperCase()}_`),
      disabled: !isEstimateLockedToCurrentUser,
      onClick: () => {
        dispatchBuildWopCreateModal(rowType, parentRowId, groupingListStoreId, this.getGroupingScheduleData)
        removeHighlight(rowRef, highlightedRow)
      },
      testId: `ContextMenuItem-create-new-${rowType}`,
    }
  }

  getReArrangeMenuItem = (listItem: TVDWOPListItem, rowRef?: TVDRef, highlightedRow?: string): TVDMenuItem => {
    const { t, isEstimateLockedToCurrentUser } = this.props

    const subMenuIconStyle = { marginRight: 10 }
    return {
      localizedName: t('buttons._MOVE_IN_LIST_'),
      testId: 'ContextMenuItem-move-in-list',
      disabled: !isEstimateLockedToCurrentUser,
      subMenuItems: [
        {
          localizedName: t('buttons._MOVE_TO_FIRST_'),
          onClick: () => {
            this.patchChangeOrderBySelectedRowType(listItem, CHANGE_ORDER_FIRST)
            removeHighlight(rowRef, highlightedRow)
          },
          testId: 'ContextMenuItem-move-to-first',
          icon: {
            alignLeft: true,
            name: 'first_page',
            style: { ...subMenuIconStyle, transform: 'rotate(90deg)' },
          }
        },
        {
          localizedName: t('buttons._MOVE_UP_'),
          onClick: () => {
            this.patchChangeOrderBySelectedRowType(listItem, CHANGE_ORDER_UP)
            removeHighlight(rowRef, highlightedRow)
          },
          testId: 'ContextMenuItem-move-up',
          icon: {
            alignLeft: true,
            name: 'keyboard_arrow_up',
            style: { ...subMenuIconStyle }
          }
        },
        {
          localizedName: t('buttons._MOVE_DOWN_'),
          onClick: () => {
            this.patchChangeOrderBySelectedRowType(listItem, CHANGE_ORDER_DOWN)
            removeHighlight(rowRef, highlightedRow)
          },
          testId: 'ContextMenuItem-move-down',
          icon: {
            alignLeft: true,
            name: 'keyboard_arrow_down',
            style: { ...subMenuIconStyle }
          }
        },
        {
          localizedName: t('buttons._MOVE_TO_LAST_'),
          onClick: () => {
            this.patchChangeOrderBySelectedRowType(listItem, CHANGE_ORDER_LAST)
            removeHighlight(rowRef, highlightedRow)
          },
          testId: 'ContextMenuItem-move-to-last',
          icon: {
            alignLeft: true,
            name: 'last_page',
            style: { ...subMenuIconStyle, transform: 'rotate(90deg)' }
          }
        },
      ]
    }
  }
  getContextMenuItems = (row: TVDWOPListItem, rowRef?: TVDRef, highlightedRow?: string): Array<MenuItemProps> => {
    const { type, wopItemId, parentWopItemId } = row
    const hierarchy = [GROUPINGFUNCTIONALSECTORGROUP, GROUPINGFUNCTIONGROUP, GROUPINGPROCESSGROUP]

    switch (type.toUpperCase()) {
      case FUNCTIONALSECTORGROUP: return [
        this.getReArrangeMenuItem(row, rowRef, highlightedRow),
        this.getOpenCreateModalRowMenuItem(type, 0, row, rowRef, highlightedRow),
        this.getOpenCreateModalRowMenuItem(getNextArrayItem(hierarchy, type), wopItemId, row, rowRef, highlightedRow),
        this.getRenameMenuItem(row, rowRef, highlightedRow),
        this.getDeleteMenuItem(row, rowRef, highlightedRow)
      ]
      case FUNCTIONGROUP: return [
        this.getReArrangeMenuItem(row, rowRef, highlightedRow),
        this.getOpenCreateModalRowMenuItem(type, parentWopItemId, row, rowRef, highlightedRow),
        this.getOpenCreateModalRowMenuItem(getNextArrayItem(hierarchy, type), wopItemId, row, rowRef, highlightedRow),
        this.getMoveMenuItem(row, rowRef, highlightedRow),
        this.getRenameMenuItem(row, rowRef, highlightedRow),
        this.getDeleteMenuItem(row, rowRef, highlightedRow)
      ]
      case PROCESSGROUP: return [
        this.getReArrangeMenuItem(row, rowRef, highlightedRow),
        this.getOpenCreateModalRowMenuItem(type, parentWopItemId, row, rowRef, highlightedRow),
        this.getMoveMenuItem(row, rowRef, highlightedRow),
        this.getRenameMenuItem(row, rowRef, highlightedRow),
        this.getDeleteMenuItem(row, rowRef, highlightedRow)
      ]
      case PROCESS: return [
        this.getMoveMenuItem(row, rowRef, highlightedRow),
      ]
      default: return []
    }
  }

  getIconsContainer = (listItem: TVDWOPListItem, rowRef: TVDRef, highlightedRow: string): React$Element<'div'> => {
    const { classes } = this.props
    const items = this.getContextMenuItems(listItem, rowRef, highlightedRow)
    return (
      <div className={classes.iconsContainer} data-visible_on_hover>
        { items.length > 0 &&
          <HamburgerMenu
            id='WOPGrouping'
            items={items}
            onToggleChange={() => rowRef.current && rowRef.current.classList.add(highlightedRow)}
            onToggleClose={() => rowRef.current && rowRef.current.classList.remove(highlightedRow)} /> }
      </div>
    )
  }

  getGroupingSchedule = (): React$Element<any> => {
    const {
      classes,
      groupingListStoreId,
      activeEdit
    } = this.props

    return (
      <div className={classes.listContainer}>
        {this.getGroupingCheckboxBar()}
        {this.getGroupingTitle()}
        <div className={classes.groupingPadding} />
        <HierarchicalListContainer
          contextMenuItems={this.getContextMenuItems}
          wrappedCellContents={{ [DESCRIPTION]: this.getDescriptionCellContent }}
          displayCheckBoxes
          checkboxDisabled={activeEdit}
          disabled={false}
          listType={GROUPING_VIEW}
          listId={groupingListStoreId}
          data-testid='group' />

      </div>
    )
  }

  getSpaceSchedule = (): React$Element<any> => {
    const {
      classes,
      widgetId,
      listStoreId,
      groupingListStoreId
    } = this.props
    return (
      <div className={classes.spaceScheduleWrapper}>
        <WopSpaceSchedule
          widgetId={widgetId}
          listStoreId={listStoreId}
          showTitleText
          watchListStoreIds={[groupingListStoreId, ACTIVITY_STRUCTURE]} />
        <ResultBarContainer resultBarKey={WOP_SPACE_SCHEDULE} widgetType={WOP_SPACE_SCHEDULE} />
      </div>
    )
  }

  async patchChangeOrderBySelectedRowType(row: TVDWOPListItem, changeOrder: TVDChangeRowOrder): Promise<any> {
    const { groupingListStoreId, list, dispatchPostPolling } = this.props

    let selectedItems = filter(list[groupingListStoreId].listItems, (listItem: Object) => listItem.selected)
    if (selectedItems.length === 0) selectedItems = [{ wopItemId: row.wopItemId, type: row.type }]
    if (changeOrder === CHANGE_ORDER_FIRST || changeOrder === CHANGE_ORDER_DOWN) selectedItems = selectedItems.reverse()

    try {
      for (const selectedItem of selectedItems) {
        const requestParameters = [
          {
            path: { itemId: selectedItem.wopItemId },
            query: { changeOrder },
            body: {}
          },
          {},
          () => { dispatchPostPolling() },
          null,
          { disableRefreshEstimateLock: true }
        ]
        switch (selectedItem.type.toUpperCase()) {
          case FUNCTIONALSECTORGROUP:
            // eslint-disable-next-line no-await-in-loop
            await patchGroupingScheduleFunctionalSectorGroupsWithItemIdRequest(...requestParameters)
            break
          case FUNCTIONGROUP:
            // eslint-disable-next-line no-await-in-loop
            await patchGroupingScheduleFunctionGroupsWithItemIdRequest(...requestParameters)
            break
          case PROCESSGROUP:
            // eslint-disable-next-line no-await-in-loop
            await patchGroupingScheduleProcessGroupsWithItemIdRequest(...requestParameters)
            break
          default:
            console.error(`patchChangeOrderBySelectedRowType: No switch case found for item of type: ${selectedItem.type}`)
        }
      }
    } catch (err) {
      console.error('Patch changeOrder failed', err)
    } finally {
      this.getGroupingScheduleItems()
    }
  }


  render(): React$Element<any> {
    const { classes, t } = this.props

    return (
      <div className={classes.wrapper}>
        <div className={classes.root}>
          {this.getGroupingSchedule()}
          <div className={classes.footer} >{t('groupingWidget._GROUPING_FOOTER_TEXT_')}</div>
        </div>
        {this.getSpaceSchedule()}
      </div>
    )
  }
}

type ReduxState = {
  ...TVDReduxStore,
  +list: Object, // list reducer object
}


const mapStateToProps = ({ list, app: { activeEdit, languageCode } }: ReduxState, { groupingListStoreId }: ReceivedProps): MappedProps => ({
  groupingListItems: list[groupingListStoreId]?.filteredListItems || list[groupingListStoreId]?.listItems,
  activeEdit,
  languageCode,
  list
})

const mapDispatchToProps = (dispatch: Function, { groupingListStoreId }: ReceivedProps): DispatchProps => ({
  dispatchOpenModal: (content: Object, id: string) => { dispatch(openModal(content, id)) },
  dispatchDeleteWopRow: (content: Object) => { dispatch(openModal(content, CONFIRMATION_MODAL)) },
  dispatchCloseWopRow: () => { dispatch(closeModal(CONFIRMATION_MODAL)) },
  dispatchClearListFilter: () => { dispatch(clearListItemsFilter(groupingListStoreId)) },
  dispatchPostPolling: () => { dispatch(postPolling()) },
  dispatchFilterListItemsWithFilterFn: (filterFn: (listItem: TVDWOPListItem) => boolean) => {
    dispatch(filterListItemsWithFilterFn(groupingListStoreId, filterFn))
  },
  dispatchBuildWopCreateModal: (type: string, id: number, listId: string, onSaveCb: Function) => {
    dispatch(buildWopCreateModal(type, id, listId, onSaveCb))
  },
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('translations'),
  withStyles(styles),
)(Grouping)
