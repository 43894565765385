// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { loadMFE, registeredMFEs } from '../../../utils/MFEUtils'
import { API_BASE_URL, ALLOW_WITH_CREDENTIALS, FEATURE_SET } from '../../../constants/envConstants'
import { openGenericErrorModal } from '../../../utils/modalUtils'
import { dispatch } from '../../../store'
import { setActiveWidgetAsTopmost } from '../../../actions/app'
import { defaultLocation } from '../../../middlewares/widgetLocationMiddleware'
import {
  SPACES_LIFECYCLE_CO2_WIDGET_MFENAME,
  SPACES_LIFECYCLE_CO2_WIDGET_COMPONENTNAME
} from '../../../constants/MFEStateConstants'
import { setMFEStates } from '../../../actions/MFEStates'

export const spacesLifecycleCO2WidgetMFERootId = SPACES_LIFECYCLE_CO2_WIDGET_MFENAME

const SpacesLifecycleCO2WidgetMFEContainer = (): null => {
  const {
    accessToken,
    haahtelaApiUserToken,
    userId,
    languageCode,
    selectedAccountId,
    userPrivileges,
    activeEstimate: activeSpacesEstimate,
    postPollingTS,
    activeCalculation,
    estimateType,
    resultViewType,
    activeRealEstate: activeRealEstateStore,
    widgetOrder,
    MFEStates
  } = useSelector(({
    activeEstimate,
    app,
    user,
    activeRealEstate,
    MFEStates: MFEStatesStore
  }: TVDReduxStore): {|
    widgetOrder: $PropertyType<TVDApplicationStore, 'widgetOrder'>,
    activeRealEstate: TVDActiveRealEstateStore,
    languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
    selectedAccountId: $PropertyType<TVDApplicationStore, 'selectedAccountId'>,
    accessToken: $PropertyType<TVDUserAuthorization, 'accessToken'>,
    haahtelaApiUserToken: $PropertyType<TVDUserClaims, 'haahtelaApiUserToken'>,
    userId: $PropertyType<TVDUserClaims, 'userId'>,
    userPrivileges: $PropertyType<TVDUserStore, 'userPrivileges'>,
    activeEstimate: TVDActiveEstimateStore,
    postPollingTS: $PropertyType<TVDApplicationStore, 'postPollingTS'>,
    activeCalculation: $PropertyType<TVDApplicationStore, 'activeCalculation'>,
    estimateType: $PropertyType<TVDApplicationStore, 'spacesEstimateType'>,
    resultViewType: $PropertyType<TVDApplicationStore, 'spacesResultView'>,
    activeRealEstate: TVDActiveRealEstateStore,
    MFEStates: TVDMFEStatesStore,
  |} => ({
    widgetOrder: app.widgetOrder,
    activeRealEstate,
    languageCode: app.languageCode,
    selectedAccountId: app.selectedAccountId,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    userPrivileges: user.userPrivileges,
    activeEstimate,
    postPollingTS: app.postPollingTS,
    activeCalculation: app.activeCalculation,
    estimateType: app.spacesEstimateType,
    resultViewType: app.spacesResultView,
    MFEStates: MFEStatesStore,
  }))

  const registeredLifecycleCO2SpacesModule = registeredMFEs['containers-lifecycle-co2-spaces-module']
  const MFEState = MFEStates[SPACES_LIFECYCLE_CO2_WIDGET_MFENAME] || {}

  const closeWidget = () => {
    dispatch(setMFEStates(
      SPACES_LIFECYCLE_CO2_WIDGET_MFENAME,
      false,
      SPACES_LIFECYCLE_CO2_WIDGET_COMPONENTNAME,
      null
    ))
  }

  useEffect(() => {
    if (
      languageCode &&
      accessToken &&
      haahtelaApiUserToken &&
      userId &&
      selectedAccountId &&
      userPrivileges &&
      activeSpacesEstimate &&
      estimateType &&
      resultViewType &&
      widgetOrder &&
      activeRealEstateStore
    ) {
      const render = (module: TVDMFEFunctionality): void => {
        const setWidgetAsTopmost = () => {
          if (widgetOrder[widgetOrder.length - 1] !== spacesLifecycleCO2WidgetMFERootId) {
            dispatch(setActiveWidgetAsTopmost(spacesLifecycleCO2WidgetMFERootId))
          }
        }

        const moduleRender = module.render || null
        if (moduleRender) {
          moduleRender(
            spacesLifecycleCO2WidgetMFERootId,
            {
              APIBaseURL: API_BASE_URL,
              languageCode,
              accessToken,
              haahtelaApiUserToken,
              userId,
              selectedAccountId,
              openGenericErrorModal,
              ALLOW_WITH_CREDENTIALS,
              activeEstimate: activeSpacesEstimate,
              postPollingTS,
              activeCalculation,
              estimateType,
              resultViewType,
              activeRealEstate: activeRealEstateStore,
              closeWidget,
              setWidgetAsTopmost,
              widgetId: spacesLifecycleCO2WidgetMFERootId,
              widgetOrder,
              defaultLocation,
              isWidgetOpen: MFEState.isOpen,
              FEATURE_SET
            }
          )
        }
      }
      if (registeredLifecycleCO2SpacesModule && document.getElementById(spacesLifecycleCO2WidgetMFERootId)) {
        render(registeredLifecycleCO2SpacesModule)
      } else {
        loadMFE('containers_lifecycle_co2', 'containers-lifecycle-co2-spaces-module', (module: TVDMFEFunctionality): void => {
          render(module)
        })
      }
    }
  }, [
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    selectedAccountId,
    userPrivileges,
    activeSpacesEstimate,
    postPollingTS,
    activeCalculation,
    registeredLifecycleCO2SpacesModule,
    estimateType,
    resultViewType,
    activeRealEstateStore,
    closeWidget,
    widgetOrder,
  ])
  return null
}

export default SpacesLifecycleCO2WidgetMFEContainer

