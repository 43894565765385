// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { useEffect, useState } from 'react'
import { useStore, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import { Chip, TextButton } from 'frontend-components'
import { typographyClasses, borderRadiuses, boxShadows } from 'frontend-assets'
import {
  setBuildingName,
  setBuildingId,
  setPrimaryEstimateForModule,
} from '../../../actions/app'
import { drawerWidth } from '../../common/components/ActionBar/ActionBar'
import { followEstimatesLink, getEstimateLocationById } from '../../common/queries/estimatesQueries'
import { useAppSelection } from '../../common/hooks/appSelection'
import { followRealEstateLink, getRealEstateById } from '../../common/queries/realEstateQueries'
import {
  SPACES,
  LOCAN,
  ELEMENTS,
  WOP,
  PROFITABILITY,
  PROCUREMENT_ELEMENTS,
  RENOVATION_PROGRAM,
  USAGE_AND_MAINTENANCE,
  appIds
} from '../../../constants/moduleConstants'
import { getLastPathPart } from '../../../utils/urlUtil'
import { showNDecimals, dotsToCommas } from '../../../utils/commonUtils'
import { thousandSeparator, formatResultBoxContent, formatCellContent } from '../../../utils/listUtils'
import SummaryBlock from '../../common/components/SummaryBlock/SummaryBlock'
import { blockMinWidth } from '../../../constants/summarySizes'
import {
  AREATOTALM2,
  DESCRIPTION,
  PRICE_TOTAL_NEW_BUILD_IN_CURRENCY,
  TOTAL_PRICE_IN_CURRENCY,
  DRIVER,
  PERMITTED_BUILDING_AREA_M2,
  PERMITTED_BUILDING_AREA_PRICE,
  AREA_TOTAL_ALONG_EXTERIOR_WALL_OUTSIDES_NO_LARGE_OPENINGS_M2,
  PRICE_TOTAL_IN_CURRENCY,
  QUANTITY_SPACES_PCS,
  LOT_AREA_M2,
  TOTAL_PERMITTED_BUILDING_AREA_PRICE
} from '../../../constants/attributes'

import {
  getProfitabilitySpaceResultsScheduleFunctionalSectorsFunctionsWithEstimateId,
  getProfitabilitySpaceScheduleKeyFiguresWithEstimateId,
  getProfitabilitySpaceResultsScheduleColumnsWithEstimateId
} from '../../../utils/ungenerated-api-requests/profitability'
import {
  getSpaceScheduleWithEstimateIdRequest,
  getSpaceScheduleValuesWithEstimateIdRequest,
  getSpaceScheduleColumnsWithEstimateIdRequest
} from '../../../utils/generated-api-requests/spaces'
import {
  getSpaceScheduleValuesWithEstimateIdRequest as getWOPSpaceScheduleValuesWithEstimateIdRequest,
  getActivityScheduleWithEstimateIdRequest,
  getActivityScheduleColumnsWithEstimateIdRequest
} from '../../../utils/generated-api-requests/wop'
import {
  getEstimateFunctionalSectorsWithEstimateIdRequest,
  getEstimateSummaryWithEstimateIdRequest,
  getEstimatePropertiesWithEstimateIdRequest
} from '../../../utils/generated-api-requests/locan'
import {
  getScheduleWithEstimateIdRequest,
  getScheduleValuesWithEstimateIdRequest,
  getScheduleColumnsWithEstimateIdRequest
} from '../../../utils/generated-api-requests/buildingelements'
import {
  getEstimateSummaryWithEstimateIdRequest as getRenovationSummary
} from '../../../utils/generated-api-requests/renovation-program'
import { getProcurementsSummary } from '../../../utils/ungenerated-api-requests/procurementElements'
import { type ResultBoxProps } from '../../../components/containers/ResultBar/ResultBox/ResultBox'
import { type TVDParserParameters } from '../../../utils/parserMapper'
import { ALL_KEYS } from '../../../utils/HALParser'
import {
  PROF_INVESTMENT_VALUE_IN_CURRENCY,
  PROF_DESCRIPTION,
  PROF_TOTAL_AREA,
  PROF_RATE_OF_RETURN,
  PROF_TARGET_RENT,
  PROF_LEASABLE_AREA_M2
} from '../../../constants/profitabilityPropertyNames'
import {
  openModal, closeModal
} from '../../../actions/modals'
import { MODAL_TYPE_PRIMARY_SELECTION } from '../../../constants/modalConstants'
import theme from '../../../styles/theme'
import { headerHeight } from '../../../components/common/Header/Header'
import { NEWCONSTRUCTION, PRICE } from '../../../constants/viewModeConstants'
import UsageMaintenanceSummary from '../../../components/containers/UsageMaintenanceSummary/UsageMaintenanceSummary'
import SummaryBlockContainer from '../../../components/containers/SummaryBlockContainer/SummaryBlockContainer'
import { getEstimateWithEstimateIdRequest } from '../../../utils/generated-api-requests/usage-maintenance'
import { getIsFeatureEnabledInSet } from '../../../utils/features'
import { FEATURE_LICENCE_TYPE_CHIP } from '../../../constants/features'


const imgSrcs = {
  WOP: `${process.env.PUBLIC_URL || ''}/assets/images/modules/WOP.svg`,
  SPACES: `${process.env.PUBLIC_URL || ''}/assets/images/modules/Spaces.svg`,
  buildingElements: `${process.env.PUBLIC_URL || ''}/assets/images/modules/Building_elements.svg`,
  Kayttotehtavat: `${process.env.PUBLIC_URL || ''}/assets/images/landing/Kayttotehtavat.svg`,
  'Maa Alue': `${process.env.PUBLIC_URL || ''}/assets/images/landing/Maa_alue.svg`,
  Tuotantojako: `${process.env.PUBLIC_URL || ''}/assets/images/landing/Tuotantojako.svg`,
  KiinteistonArviointi: `${process.env.PUBLIC_URL || ''}/assets/images/landing/Kiinteiston_arviointi.svg`,
  LOCAN: `${process.env.PUBLIC_URL || ''}/assets/images/modules/Location.svg`,
  PROFITABILITY: `${process.env.PUBLIC_URL || ''}/assets/images/modules/Profitability.svg`,
  PROCUREMENT_ELEMENTS: `${process.env.PUBLIC_URL || ''}/assets/images/modules/Procurement_elements.svg`,
  RENOVATION_PROGRAM: `${process.env.PUBLIC_URL || ''}/assets/images/modules/Renovation.svg`,
  USAGE_AND_MAINTENANCE: `${process.env.PUBLIC_URL || ''}/assets/images/modules/Maintenance.svg`,
}

const { borderRadiusLarge } = borderRadiuses
const { boxShadowComponent } = boxShadows

const useStyles = makeStyles(({ palette }: TVDTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    height: '100%',
    background: palette.gray20,
    overflow: 'auto',
    paddingTop: headerHeight
  },
  summaryViewRoot: {
    width: '100%',
    height: '100%',
    display: 'inline-block',
    '&>div': {
      float: 'left',
      marginTop: '8px',
      marginRight: '8px'
    }
  },
  sections: {
    flex: 6,
    padding: `0 20px 20px ${drawerWidth.side + 20}px`
  },
  resultbar: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    border: `1px solid ${palette.gray80}`,
    background: palette.white
  },
  viewButtonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    margin: '16px 0',
    '& > button': {
      padding: '8px 48px',
      borderColor: 'rgba(220, 227, 231, 1)',
      '&:focus, &:active': {
        backgroundColor: '#e5f3fa',
        borderWidth: '2px',
        borderColor: 'rgba(0, 150, 214, 1)'
      },
    }
  },
  estimateWapper: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    '& > button': {
      marginLeft: '10px',
      alignSelf: 'center',
    }
  },
  infoBox: {
    marginBottom: '30px',
    minHeight: '165px',
    maxWidth: '430px'
  },
  locationOnIconWrapper: {
    height: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px'
  },
  subtitleWrapper: {
    ...typographyClasses.bodySmall,
    display: 'flex',
    alignItems: 'center',
    color: palette.dark80,
    background: palette.white,
    paddingLeft: '21px',
    marginTop: '27px',
    minHeight: '22px'
  },
  locationOnIcon: {
    color: palette.dark80,
    fontSize: '16px'
  },
  profitabilityBlock: {
    '& > table > tbody > tr > td > div': {
      marginLeft: 0,
      '& > div': {
        marginRight: -24
      }
    }
  },
  headerBlock: {
    background: palette.white,
    float: 'none',
    height: '63px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '8px',
    borderRadius: borderRadiusLarge,
    boxShadow: boxShadowComponent
  },
  editButton: {
    margin: '5px 6px',
    '& > button > span': {
      textTransform: 'none'
    }
  },
  realEstateName: {
    marginLeft: 32,
    ...theme.typography.classes.bodyBigBold,
    color: palette.dark80,
  },
  [`@media (max-width: ${blockMinWidth}px)`]: {
    summaryViewRoot: {
      paddingRight: '8px'
    }
  },
  subLeft: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    gap: 16
  },
  bodyNumbers: {
    ...typographyClasses.bodyNumbers
  }
}))


type SummaryLoadingStatusState = {
  [module: string]: boolean
}

const SummaryView = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [setSelectedApp] = useAppSelection()
  const {
    realEstateId, moduleIds, primaryEstimates, licenseType
  } = useSelector(({ app }: TVDReduxStore): TVDApplicationStore => app)
  const { dispatch } = useStore()
  const [primarySpaceEstimateScheduleItems, setPrimarySpaceEstimateScheduleItems] = useState([])
  const [primarySpaceEstimateValues, setPrimarySpaceEstimateValues] = useState([])
  const [primarySpaceEstimateColumns, setPrimarySpaceEstimateColumns] = useState([])
  const [primaryWOPEstimateScheduleItems, setPrimaryWOPEstimateScheduleItems] = useState([])
  const [primaryWOPEstimateValues, setPrimaryWOPEstimateValues] = useState([])
  const [primaryWOPEstimateColumns, setPrimaryWOPEstimateColumns] = useState([])
  const [primaryLOCANEstimateFunctionalSectors, setPrimaryLOCANEstimateFunctionalSectors] = useState([])
  const [primaryLOCANEstimateFunctionalSectorsUnits, setPrimaryLOCANEstimateFunctionalSectorsUnits] = useState({})
  const [primaryLOCANEstimateSummaryValues, setPrimaryLOCANEstimateSummaryValues] = useState([])
  const [primaryBuildingElementsEstimateScheduleItems, setPrimaryBuildingElementsEstimateScheduleItems] = useState([])
  const [primaryBuildingElementsEstimateColumns, setPrimaryBuildingElementsEstimateColumns] = useState([])
  const [primaryBuildingElementsEstimateValues, setPrimaryBuildingElementsEstimateValues] = useState([])
  const [primaryProfitabilityEstimateItems, setPrimaryProfitabilityEstimateItems] = useState([])
  const [primaryProfitabilityEstimateColumns, setPrimaryProfitabilityEstimateColumns] = useState([])
  const [primaryProfitabilityEstimateValues, setPrimaryProfitabilityEstimateValues] = useState([])
  const [profitabilityResultBarFormatOptions, setProfitabilityResultBarFormatOptions] = useState({})
  const [primaryProcurementsEstimateItems, setPrimaryProcurementsEstimateItems] = useState([])
  const [primaryProcurementsEstimateColumns, setPrimaryProcurementsEstimateColumns] = useState([])
  const [primaryProcurementsEstimateValues, setPrimaryProcurementsEstimateValues] = useState([])
  const [primaryRenovationEstimateItems, setPrimaryRenovationEstimateItems] = useState([])
  const [primaryRenovationEstimateColumns, setPrimaryRenovationEstimateColumns] = useState([])
  const [primaryRenovationEstimateValues, setPrimaryRenovationEstimateValues] = useState([])
  const [permittedBuildingAreaSum, setPermittedBuildingAreaSum] = useState(null)
  const [permittedAreaUnit, setPermittedAreaUnit] = useState(null)
  const [primaryUsageMaintenanceData, setPrimaryUsageMaintenanceData] = useState<TVDCalculation | null>(null)

  const [loadingStatuses, setLoadingStatusState] = useState<SummaryLoadingStatusState>({
    [LOCAN]: true,
    [WOP]: true,
    [ELEMENTS]: true,
    [SPACES]: true,
    [PROFITABILITY]: true,
    [PROCUREMENT_ELEMENTS]: false,
    [RENOVATION_PROGRAM]: true
  })

  const [
    allowedModuleStatuses,
    setAllowedModuleStatuses
  ] = useState<{ [moduleConstant: string]: boolean }>({
    [LOCAN]: false,
    [WOP]: false,
    [ELEMENTS]: false,
    [SPACES]: false,
    [PROFITABILITY]: false,
    [PROCUREMENT_ELEMENTS]: false,
    [RENOVATION_PROGRAM]: false,
    [USAGE_AND_MAINTENANCE]: false
  })

  const [primaryLOCANEstimateAddressStr, setPrimaryLOCANEstimateAddressStr] = useState('')

  useEffect(() => {
    const allowedModules = Object.keys(appIds).reduce((
      prev: { [moduleName: string]: boolean },
      moduleName: string
    ) => ({
      ...prev,
      [moduleName]: moduleIds.includes(appIds[moduleName])
    }), {})
    setAllowedModuleStatuses(allowedModules)
  }, [moduleIds])

  const setLoadingStatus = (module: string, loadingStatus: boolean) => {
    setLoadingStatusState((oldStatus: SummaryLoadingStatusState) => ({
      ...oldStatus,
      [module]: loadingStatus,
    }))
  }

  const {
    isLoading: estatesLoading,
    error: estatesError,
    data: realEstateData
  } = useQuery(['realEstateGet', realEstateId], () => getRealEstateById(realEstateId))

  const {
    data: buildingData
  } = useQuery(
    ['buildingGet', realEstateId, realEstateData?._links?.building?.href],
    () => followRealEstateLink(realEstateData._links.building.href),
    { enabled: !estatesLoading && !estatesError, retry: 100 }
  )

  useEffect(() => {
    if (buildingData?.buildingId && buildingData?.description) {
      dispatch(setBuildingId(buildingData.buildingId))
      dispatch(setBuildingName(buildingData.description))
    }
  }, [dispatch, buildingData])

  const {
    data: siteData
  } = useQuery(
    ['siteGet', realEstateId, realEstateData?._links?.site?.href],
    () => followRealEstateLink(realEstateData._links.site.href),
    { enabled: !estatesLoading && !estatesError, retry: 100 }
  )

  const {
    data: primarySpacesEstimateByIdData
  } = useQuery(
    ['primarySpacesEstimateByIdGet', realEstateId, buildingData?._links?.primarySpacesEstimate?.href],
    () => followEstimatesLink(buildingData._links.primarySpacesEstimate.href),
    { enabled: !!buildingData, retry: 100 }
  )

  const { id: primarySpacesEstimateByIdDataId } = primarySpacesEstimateByIdData || {}

  const {
    data: primaryLocanEstimateByIdData
  } = useQuery(
    ['primaryLocanEstimateByIdGet', realEstateId, siteData?._links?.primaryLocanEstimate?.href],
    () => followEstimatesLink(siteData?._links?.primaryLocanEstimate?.href),
    { enabled: !!siteData, retry: 100 }
  )

  const { id: primaryLocanEstimateByIdDataId } = primaryLocanEstimateByIdData || {}


  const {
    data: primaryLocanLocationEstimateData
  } = useQuery(
    ['primaryLocanLocationEstimateDataGet', primaryLocanEstimateByIdData?.id],
    () => getEstimateLocationById(primaryLocanEstimateByIdData.id),
    { enabled: !!primaryLocanEstimateByIdData, retry: 100 }
  )

  const {
    data: primaryBuildingElementsEstimateByIdData
  } = useQuery(
    ['primaryBuildingElementsEstimateByIdGet', realEstateId, buildingData?._links?.primaryBuildingElementsEstimate?.href],
    () => followEstimatesLink(buildingData._links.primaryBuildingElementsEstimate.href),
    { enabled: !!buildingData, retry: 100 }
  )

  const { id: primaryBuildingElementsEstimateByIdDataId } = primaryBuildingElementsEstimateByIdData || {}

  const {
    data: primaryWOPEstimateByIdData
  } = useQuery(
    ['primaryWOPEstimateByIdGet', realEstateId, buildingData?._links?.primaryAbeEstimate?.href],
    () => followEstimatesLink(buildingData._links.primaryAbeEstimate.href),
    { enabled: !!buildingData, retry: 100 }
  )

  const { id: primaryWOPEstimateByIdDataId } = primaryWOPEstimateByIdData || {}

  const {
    data: primaryProfitabilityEstimateByIdData
  } = useQuery(
    ['primaryProfitabilityEstimateGet', realEstateId, buildingData?._links?.primaryProfitabilityEstimate?.href],
    () => followEstimatesLink(buildingData._links.primaryProfitabilityEstimate.href),
    { enabled: !!buildingData, retry: 100 }
  )

  const {
    data: primaryProcurementElementsEstimateByIdData
  } = useQuery(
    ['primaryProcurementElementsEstimateGet', realEstateId, buildingData?._links?.primaryProcurementElementsEstimate?.href],
    () => followEstimatesLink(buildingData._links.primaryProcurementElementsEstimate.href),
    { enabled: !!buildingData, retry: 100 }
  )

  const {
    data: primaryRenovationEstimateByIdData
  } = useQuery(
    ['primaryRenovationEstimateGet', realEstateId, buildingData?._links?.primaryRenovationProgramEstimate?.href],
    () => followEstimatesLink(buildingData._links.primaryRenovationProgramEstimate.href),
    { enabled: !!buildingData, retry: 100 }
  )

  const { id: primaryProfitabilityEstimateByIdDataId } = primaryProfitabilityEstimateByIdData || {}
  const { id: primaryProcurementElementsEstimateByIdDataId } = primaryProcurementElementsEstimateByIdData || {}
  const { id: primaryRenovationEstimateByIdDataId } = primaryRenovationEstimateByIdData || {}

  useEffect(() => {
    if (siteData) {
      const {
        _links: {
          primaryLocanEstimate: { href },
        }
      } = siteData
      if (href) {
        dispatch(setPrimaryEstimateForModule(LOCAN, getLastPathPart(href)))
      }
    }
  }, [siteData, dispatch])

  useEffect(() => {
    if (buildingData) {
      const {
        _links: {
          primaryBuildingElementsEstimate: { href: buildingEstimateHref },
          primarySpacesEstimate: { href: spacesEstimateHref },
          primaryAbeEstimate: { href: wopEstimateHref },
          primaryProfitabilityEstimate: { href: profEstimateHref },
          primaryRenovationProgramEstimate: { href: renovationEstimateHref },
          primaryUsageMaintenanceEstimate: { href: usageMaintenanceEstimateHref },
        }
      } = buildingData
      dispatch(setPrimaryEstimateForModule(WOP, getLastPathPart(wopEstimateHref)))
      dispatch(setPrimaryEstimateForModule(SPACES, getLastPathPart(spacesEstimateHref)))
      dispatch(setPrimaryEstimateForModule(ELEMENTS, getLastPathPart(buildingEstimateHref)))
      dispatch(setPrimaryEstimateForModule(PROFITABILITY, getLastPathPart(profEstimateHref)))
      dispatch(setPrimaryEstimateForModule(RENOVATION_PROGRAM, getLastPathPart(renovationEstimateHref)))
      dispatch(setPrimaryEstimateForModule(USAGE_AND_MAINTENANCE, getLastPathPart(usageMaintenanceEstimateHref)))
    }
  }, [buildingData, dispatch])

  const UMAPrimaryEstimateId = primaryEstimates[USAGE_AND_MAINTENANCE]
  const isUMAModuleAllowed = allowedModuleStatuses[USAGE_AND_MAINTENANCE]

  useEffect(() => {
    if (UMAPrimaryEstimateId && isUMAModuleAllowed) {
      getEstimateWithEstimateIdRequest(
        {},
        (primaryUsageMaintenanceEstimate: TVDCalculation): void => {
          setPrimaryUsageMaintenanceData(primaryUsageMaintenanceEstimate)
        },
        null,
        { estimateId: UMAPrimaryEstimateId }
      )
    }
  }, [UMAPrimaryEstimateId, isUMAModuleAllowed])


  const openPrimarySelectionModal = () => {
    const modalId = MODAL_TYPE_PRIMARY_SELECTION
    dispatch(openModal({
      title: t('summaryView._PRIMARY_ESTIMATE_SELECTION_MODAL_'),
      id: modalId,
      type: MODAL_TYPE_PRIMARY_SELECTION,
      contentProps: {
        buildingId: buildingData.buildingId,
        siteId: siteData.siteId,
        onClose: () => {
          dispatch(closeModal(modalId))
        },
      }
    }, modalId))
  }

  useEffect(() => {
    if (primarySpacesEstimateByIdDataId) {
      getSpaceScheduleValuesWithEstimateIdRequest(
        {},
        (vals: Array<TVDSchemaValueProperty>) => {
          const desiredValuePropertyNames = [
            AREA_TOTAL_ALONG_EXTERIOR_WALL_OUTSIDES_NO_LARGE_OPENINGS_M2,
            PRICE_TOTAL_NEW_BUILD_IN_CURRENCY
          ]
          const values = vals.reduce((result: Array<ResultBoxProps>, val: TVDSchemaValueProperty) => {
            if (desiredValuePropertyNames.includes(val.propertyName)) {
              return [
                ...result,
                {
                  propertyName: val.propertyName,
                  value: parseFloat(val.value),
                  unit: val.unit || '',
                  title: val.localizedName
                }
              ]
            }
            return result
          }, [])
          setPrimarySpaceEstimateValues(values)
        },
        null,
        {
          estimateId: primarySpacesEstimateByIdDataId,
          spacesEstimateType: NEWCONSTRUCTION,
          spacesResultView: PRICE
        }
      )
      getSpaceScheduleWithEstimateIdRequest(
        { query: { listType: 'lazyload' } },
        {},
        (res: { [id: string]: TVDSchemaScheduleItem }) => {
          const items = Object.keys(res).reverse().map((itemId: string): TVDSchemaScheduleItem => res[itemId])
          setPrimarySpaceEstimateScheduleItems(items)
          setLoadingStatus(SPACES, false)
        },
        null,
        {
          estimateId: primarySpacesEstimateByIdDataId,
          spacesEstimateType: NEWCONSTRUCTION,
          spacesResultView: PRICE
        }
      )

      getSpaceScheduleColumnsWithEstimateIdRequest(
        {},
        (columns: Array<TVDSchemaValueProperty>) => {
          const desiredColumns = [
            DESCRIPTION,
            AREATOTALM2,
            PRICE_TOTAL_NEW_BUILD_IN_CURRENCY
          ]
          const filteredColumns = columns.filter((col: TVDSchemaValueProperty): boolean => desiredColumns.includes(col.propertyName))
          setPrimarySpaceEstimateColumns(filteredColumns)
        },
        null,
        {
          estimateId: primarySpacesEstimateByIdDataId,
          spacesEstimateType: NEWCONSTRUCTION,
          spacesResultView: PRICE
        }
      )
    }
  }, [
    t,
    primarySpacesEstimateByIdDataId,
    setPrimarySpaceEstimateColumns,
    // intentionally refreshing space module data if locan estimate changes (https://tools.haahtela.fi/jira/browse/PPR-317)
    primaryLocanEstimateByIdDataId
  ])

  useEffect(() => {
    if (primaryBuildingElementsEstimateByIdDataId && allowedModuleStatuses[ELEMENTS]) {
      getScheduleValuesWithEstimateIdRequest(
        {},
        {},
        (vals: Array<TVDSchemaValueProperty>) => {
          const desiredValuePropertyNames = [
            PRICE_TOTAL_IN_CURRENCY
          ]
          const values = vals.reduce((result: Array<ResultBoxProps>, val: TVDSchemaValueProperty) => {
            if (desiredValuePropertyNames.includes(val.propertyName)) {
              return [
                ...result,
                {
                  propertyName: val.propertyName,
                  value: parseFloat(val.value),
                  unit: val.unit || '',
                  title: val.localizedName
                }
              ]
            }
            return result
          }, [])
          setPrimaryBuildingElementsEstimateValues(values)
        },
        null,
        { estimateId: primaryBuildingElementsEstimateByIdDataId, mode: 'summary' }
      )
      getScheduleWithEstimateIdRequest(
        { query: { listType: 'lazyload' } },
        {},
        (res: { [id: string]: TVDSchemaScheduleItem }) => {
          const items = Object.keys(res).reverse().map((itemId: string): TVDSchemaScheduleItem => res[itemId])
          setPrimaryBuildingElementsEstimateScheduleItems(items)
          setLoadingStatus(ELEMENTS, false)
        },
        null,
        { estimateId: primaryBuildingElementsEstimateByIdDataId }
      )

      getScheduleColumnsWithEstimateIdRequest(
        {},
        (columns: Array<TVDSchemaValueProperty>) => {
          const desiredColumns = [
            DESCRIPTION,
            TOTAL_PRICE_IN_CURRENCY,
          ]
          const filteredColumns = columns.filter((column: TVDSchemaValueProperty): boolean => desiredColumns.includes(column.propertyName))
          setPrimaryBuildingElementsEstimateColumns(filteredColumns)
        },
        null,
        { estimateId: primaryBuildingElementsEstimateByIdDataId }
      )
    }
  }, [t, allowedModuleStatuses, primaryBuildingElementsEstimateByIdDataId])

  useEffect(() => {
    if (primaryWOPEstimateByIdDataId && allowedModuleStatuses[WOP]) {
      getWOPSpaceScheduleValuesWithEstimateIdRequest(
        {},
        {},
        (vals: Array<TVDSchemaValueProperty>) => {
          const desiredValuePropertyNames = [
            QUANTITY_SPACES_PCS
          ]
          const values = vals.reduce((result: Array<ResultBoxProps>, val: TVDSchemaValueProperty) => {
            if (desiredValuePropertyNames.includes(val.propertyName)) {
              return [
                ...result,
                {
                  propertyName: val.propertyName,
                  value: parseFloat(val.value),
                  unit: val.unit || '',
                  title: val.localizedName
                }
              ]
            }
            return result
          }, [])
          setPrimaryWOPEstimateValues(values)
        },
        null,
        { estimateId: primaryWOPEstimateByIdDataId }
      )
      getActivityScheduleWithEstimateIdRequest(
        { query: { listType: 'lazyload' } },
        {},
        (res: { [id: string]: TVDSchemaScheduleItem }) => {
          const items = Object.keys(res).reverse().map((itemId: string): TVDSchemaScheduleItem => res[itemId])
          setPrimaryWOPEstimateScheduleItems(items)
          setLoadingStatus(WOP, false)
        },
        null,
        { estimateId: primaryWOPEstimateByIdDataId }
      )

      getActivityScheduleColumnsWithEstimateIdRequest(
        {},
        (columns: Array<TVDSchemaValueProperty>) => {
          const desiredColumns = [
            DESCRIPTION,
            DRIVER
          ]
          const filteredColumns = columns.filter((column: TVDSchemaValueProperty): boolean => desiredColumns.includes(column.propertyName))
          setPrimaryWOPEstimateColumns(filteredColumns)
        },
        null,
        { estimateId: primaryWOPEstimateByIdDataId }
      )
    }
  }, [t, allowedModuleStatuses, primaryWOPEstimateByIdDataId])

  useEffect(() => {
    if (primaryLocanEstimateByIdDataId && siteData && primaryLocanLocationEstimateData) {
      getEstimateFunctionalSectorsWithEstimateIdRequest(
        { query: { listType: 'lazyload' } },
        {},
        (content: { [id: string]: TVDSchemaScheduleItem }) => {
          setPrimaryLOCANEstimateFunctionalSectorsUnits(content[Object.keys(content)[0]].columnUnits || {})
          const reducedItems = Object.keys(content).reduce((result: Array<TVDSchemaScheduleItem>, functionalSectorId: string) => {
            const functionalSector = content[functionalSectorId]
            if (functionalSector.columnData[PERMITTED_BUILDING_AREA_M2]) {
              return [
                ...result,
                {
                  ...functionalSector,
                  columnUnits: {}
                }
              ]
            }
            return result
          }, [])
          setPrimaryLOCANEstimateFunctionalSectors(reducedItems)
          const permittedAreasum = Object.keys(content).map((functionalSectorId: string): number => {
            const functionalSectorValue: any = content[functionalSectorId]
            return functionalSectorValue.columnData[PERMITTED_BUILDING_AREA_M2]
          }).reduce((a: number, b: number): number => a + b, 0)
          setPermittedBuildingAreaSum(permittedAreasum)
          const firstRowValue: any = Object.values(content)[0]
          setPermittedAreaUnit(firstRowValue.columnUnits[PERMITTED_BUILDING_AREA_M2])
          setLoadingStatus(LOCAN, false)
        },
        null,
        { estimateId: primaryLocanEstimateByIdDataId }
      )

      if (permittedBuildingAreaSum || permittedBuildingAreaSum === 0) {
        getEstimateSummaryWithEstimateIdRequest(
          {},
          {},
          (summaryValues: Array<TVDSchemaValueProperty>) => {
            const desiredValuePropertyNames = [
              TOTAL_PERMITTED_BUILDING_AREA_PRICE
            ]
            const values = summaryValues.reduce((result: Array<ResultBoxProps>, val: TVDSchemaValueProperty) => {
              if (desiredValuePropertyNames.includes(val.propertyName)) {
                return [
                  ...result,
                  {
                    propertyName: val.propertyName,
                    value: parseFloat(val.value),
                    unit: val.unit || '',
                    title: val.localizedName
                  }
                ]
              }
              return result
            }, [])
            getEstimatePropertiesWithEstimateIdRequest(
              {},
              (responseEstimateProperties: Array<TVDSchemaValueProperty>) => {
                const reducedEstimateProperties = responseEstimateProperties.reduce((
                  result: { [string]: TVDSchemaValueProperty},
                  nextProperty: TVDSchemaValueProperty
                ) =>
                  ({
                    ...result,
                    [nextProperty.propertyName]: nextProperty
                  }), {})
                setPrimaryLOCANEstimateSummaryValues([
                  {
                    propertyName: reducedEstimateProperties[LOT_AREA_M2].propertyName,
                    value: reducedEstimateProperties[LOT_AREA_M2].value,
                    unit: reducedEstimateProperties[LOT_AREA_M2].unit,
                    title: reducedEstimateProperties[LOT_AREA_M2].localizedName
                  },
                  {
                    propertyName: 'TotalPermittedBuildingAreaSum',
                    value: parseFloat(permittedBuildingAreaSum),
                    unit: permittedAreaUnit,
                    title: t('summaryView._TOTAL_PERMITTED_BUILDING_AREA_')
                  },
                  ...values
                ])
              },
              {},
              { estimateId: primaryLocanEstimateByIdDataId }
            )
          },
          null,
          { estimateId: primaryLocanEstimateByIdDataId, mode: 'summary' }
        )
      }

      const {
        country,
        municipality,
        postalcode,
        streetAddress
      } = primaryLocanLocationEstimateData
      const streetAddr = streetAddress === ' ' ? '' : `${streetAddress},`
      setPrimaryLOCANEstimateAddressStr([streetAddr, postalcode, `${municipality},`, country]
        .filter((strPart: string): boolean => !!strPart)
        .join(' '))
    }
  }, [
    t,
    primaryLocanEstimateByIdDataId,
    siteData,
    primaryLocanLocationEstimateData,
    permittedBuildingAreaSum,
    permittedAreaUnit
  ])

  useEffect(() => {
    if (primaryProfitabilityEstimateByIdData && primaryProfitabilityEstimateByIdDataId && allowedModuleStatuses[PROFITABILITY]) {
      getProfitabilitySpaceResultsScheduleFunctionalSectorsFunctionsWithEstimateId(
        {
          path: {
            estimateId: primaryProfitabilityEstimateByIdDataId
          },
          query: {
            listType: 'lazyload'
          }
        },
        {},
        ({ HALParsedData }: TVDParserParameters) => {
          const { [ALL_KEYS.HAL_EMBEDDED]: { [ALL_KEYS.ITEMS]: items } } = HALParsedData
          const reducedItems = items.reduce((result: Array<TVDSchemaScheduleItem>, item: TVDSchemaScheduleItem) => {
            if (
              !isNaN(item.columnData[PROF_INVESTMENT_VALUE_IN_CURRENCY]) ||
              !isNaN(item.columnData[PROF_TARGET_RENT]) ||
              !isNaN(item.columnData[PROF_RATE_OF_RETURN])
            ) {
              return [
                ...result,
                {
                  ...item,
                  columnUnits: {}
                }
              ]
            }
            return result
          }, [])
          setPrimaryProfitabilityEstimateItems(reducedItems)
          setLoadingStatus(PROFITABILITY, false)
        }
      )

      getProfitabilitySpaceScheduleKeyFiguresWithEstimateId(
        {
          path: {
            estimateId: primaryProfitabilityEstimateByIdDataId
          }
        },
        {},
        ({ HALParsedData }: TVDParserParameters) => {
          const { [ALL_KEYS.HAL_EMBEDDED]: { [ALL_KEYS.VALUES]: values } } = HALParsedData
          const desiredResults = [
            PROF_LEASABLE_AREA_M2,
            PROF_INVESTMENT_VALUE_IN_CURRENCY,
            PROF_RATE_OF_RETURN,
            PROF_TARGET_RENT
          ]
          const reducedResults = values.reduce((
            result: Array<TVDSchemaValueProperty>,
            property: TVDSchemaValueProperty,
            unit: string
          ) => {
            if (desiredResults.includes(property.propertyName)) {
              return [
                ...result,
                {
                  ...property,
                  ...unit
                }
              ]
            }
            return result
          }, [])

          const profValues = reducedResults.map((val: TVDSchemaValueProperty) => ({
            propertyName: val.propertyName,
            value: parseFloat(val.value), // Should be fixed later to solve "NaN" when value is empty
            unit: val.unit || '',
            title: val.localizedName
          }))
          const decim = (profValues[1].propertyName === PROF_INVESTMENT_VALUE_IN_CURRENCY) ? 0 : 1
          setPrimaryProfitabilityEstimateValues(profValues)
          setProfitabilityResultBarFormatOptions({ decimalsToShow: decim })
        }
      )

      getProfitabilitySpaceResultsScheduleColumnsWithEstimateId(
        {
          path: {
            estimateId: primaryProfitabilityEstimateByIdDataId
          }
        },
        {},
        ({ HALParsedData }: TVDParserParameters) => {
          const { [ALL_KEYS.HAL_EMBEDDED]: { [ALL_KEYS.PROPERTIES]: properties } } = HALParsedData
          const desiredColumns = [
            PROF_DESCRIPTION,
            PROF_TOTAL_AREA,
            PROF_INVESTMENT_VALUE_IN_CURRENCY,
            PROF_RATE_OF_RETURN,
            PROF_TARGET_RENT
          ]

          const reducedProperties = properties.reduce((result: Array<TVDSchemaValueProperty>, property: TVDSchemaValueProperty) => {
            if (desiredColumns.includes(property.propertyName)) {
              return [
                ...result,
                {
                  ...property,
                  unit: ''
                }
              ]
            }
            return result
          }, [])
          setPrimaryProfitabilityEstimateColumns(reducedProperties)
        }
      )
    }
  }, [t, primaryProfitabilityEstimateByIdDataId, primaryProfitabilityEstimateByIdData, allowedModuleStatuses])

  useEffect(() => {
    if (
      primaryProcurementElementsEstimateByIdDataId &&
      primaryProcurementElementsEstimateByIdData &&
      allowedModuleStatuses[PROCUREMENT_ELEMENTS]
    ) {
      getProcurementsSummary(
        {
          path: {
            estimateId: primaryProcurementElementsEstimateByIdDataId
          },
        },
        {},
        (content: PROCSummary) => {
          const results = content.keyFigures
          const lockedProcurements = ({
            propertyName: results[0].propertyName,
            title: t('summaryView._PROCUREMENTS_LOCKED_'),
            value: results[0].value ? `${formatResultBoxContent(results[0].value, 'integer')} - 
            ${formatResultBoxContent(results[1].value, 'integer')} ${results[1].unit}` : '-',
            unit: '',
            disableFormatting: true
          })

          const totalProcurements = ({
            propertyName: results[2].propertyName,
            title: t('summaryView._PROCUREMENTS_TOTAL_'),
            value: results[2].value ? `${formatResultBoxContent(results[2].value, 'integer')} - 
            ${formatResultBoxContent(results[3].value, 'integer')} ${results[3].unit}` : '-',
            unit: '',
            disableFormatting: true
          })

          setPrimaryProcurementsEstimateColumns([
            { localizedName: '', propertyName: 'Descr' },
            { localizedName: t('summaryView._PRICE_'), propertyName: 'PriceTotalInCurrency' },
          ])
          setPrimaryProcurementsEstimateValues([lockedProcurements, totalProcurements])
          setPrimaryProcurementsEstimateItems(content.summaryRows)
          setLoadingStatus(PROCUREMENT_ELEMENTS, false)
        }
      )
    }
  }, [t, primaryProcurementElementsEstimateByIdDataId, primaryProcurementElementsEstimateByIdData, allowedModuleStatuses])

  useEffect(() => {
    if (
      primaryRenovationEstimateByIdDataId &&
      primaryRenovationEstimateByIdData &&
      allowedModuleStatuses[RENOVATION_PROGRAM]
    ) {
      getRenovationSummary(
        {},
        (summaryValues: RenoSummary) => {
          setLoadingStatus(RENOVATION_PROGRAM, false)
          setPrimaryRenovationEstimateValues([{
            title: t('summaryView._AGGREGATED_RENOVATIONS_'),
            value: summaryValues.aggregatedPriceTotalRenovationsInCurrency,
            unit: '€'
          }])
          setPrimaryRenovationEstimateColumns([
            { localizedName: t('summaryView._DESCRIPTION_'), propertyName: 'Descr' },
            { localizedName: t('summaryView._YEAR_'), propertyName: 'Year' },
            { localizedName: t('summaryView._PRICE_'), propertyName: 'PriceTotalRenovationsInCurrency' }
          ])
          setPrimaryRenovationEstimateItems(summaryValues.renovationYears.slice(0, 5))
        },
        null,
        { estimateId: primaryRenovationEstimateByIdDataId }
      )
    }
  }, [t, primaryRenovationEstimateByIdDataId, primaryRenovationEstimateByIdData, allowedModuleStatuses])

  return (
    <div className={classes.root}>
      <div className={classes.headerBlock}>
        <div className={classes.subLeft}>
          {!!realEstateData &&
            <span className={classes.realEstateName}>
              {realEstateData?.description}
            </span>
          }
          {
            getIsFeatureEnabledInSet(FEATURE_LICENCE_TYPE_CHIP) &&
            <Chip type={licenseType} t={t} />
          }
        </div>
        <div className={classes.editButton}>
          <TextButton
            variant='toolButton'
            onClick={openPrimarySelectionModal}
            text={t('summaryView._EDIT_SOURCE_')} />
        </div>
      </div>
      <div className={classes.summaryViewRoot}>
        {allowedModuleStatuses[SPACES] &&
        <SummaryBlock
          testId='summary-block-spaces'
          subtitle={(<div className={classes.subtitleWrapper} />)}
          isLoading={loadingStatuses[SPACES]}
          title={t('apps._SPACES_')}
          titleImageSrc={imgSrcs.SPACES}
          titleSourceText={primarySpacesEstimateByIdData?.description}
          titleButtonText={t('summaryView._OPEN_SPACES_ESTIMATE_')}
          onTitleButtonClick={() => {
            setSelectedApp(SPACES)
          }}
          wrappedCellContents={{
            Descr: ({ rowIndex }: TVDWrappedCellCallbackParameters): React$Element<'div'> => {
              const {
                columnData = {}
              } = primarySpaceEstimateScheduleItems[rowIndex] || {}
              return (
                <div>{columnData[DESCRIPTION]}</div>
              )
            },
              [AREATOTALM2]: ({ rowIndex }: TVDWrappedCellCallbackParameters): React$Element<'div'> => {
                const {
                  [AREATOTALM2]: unformattedValue
                } = primarySpaceEstimateScheduleItems[rowIndex].columnData
                const value = unformattedValue ? thousandSeparator(dotsToCommas(showNDecimals(unformattedValue, 1))) : null
                return (
                  <div className={classes.bodyNumbers}>{value}</div>
                )
              },
              [PRICE_TOTAL_NEW_BUILD_IN_CURRENCY]: ({ rowIndex }: TVDWrappedCellCallbackParameters): React$Element<'div'> => {
                const {
                  [PRICE_TOTAL_NEW_BUILD_IN_CURRENCY]: unformattedValue
                } = primarySpaceEstimateScheduleItems[rowIndex].columnData
                const value = unformattedValue ? thousandSeparator(dotsToCommas(showNDecimals(unformattedValue, 1))) : null
                return (
                  <div className={classes.bodyNumbers}>{value}</div>
                )
              },
          }}
          columns={[
            {
              propertyName: 'Descr',
              localizedName: ''
            },
            {
              propertyName: AREATOTALM2,
              localizedName: primarySpaceEstimateColumns
              .find((col: TVDSchemaValueProperty): boolean => col.propertyName === AREATOTALM2)
              ?.unit
            },
            {
              propertyName: PRICE_TOTAL_NEW_BUILD_IN_CURRENCY,
              localizedName: primarySpaceEstimateColumns
              .find((col: TVDSchemaValueProperty): boolean => col.propertyName === PRICE_TOTAL_NEW_BUILD_IN_CURRENCY)
              ?.unit
            },
          ]}
          listItems={primarySpaceEstimateScheduleItems}
          results={primarySpaceEstimateValues} />
        }
        {allowedModuleStatuses[ELEMENTS] &&
        <SummaryBlock
          testId='summary-block-elements'
          subtitle={(<div className={classes.subtitleWrapper} />)}
          isLoading={loadingStatuses[ELEMENTS]}
          title={t('apps._ELEMENTS_')}
          titleImageSrc={imgSrcs.buildingElements}
          titleSourceText={primaryBuildingElementsEstimateByIdData?.description}
          titleButtonText={t('summaryView._OPEN_BUILDING_ELEMENTS_ESTIMATE_')}
          onTitleButtonClick={() => {
            setSelectedApp(ELEMENTS)
          }}
          wrappedCellContents={{
            Descr: ({ rowIndex }: TVDWrappedCellCallbackParameters): React$Element<'div'> => {
              const {
                columnData = {}
              } = primaryBuildingElementsEstimateScheduleItems[rowIndex] || {}
              return (
                <div>{columnData[DESCRIPTION]}</div>
              )
            },
              [TOTAL_PRICE_IN_CURRENCY]: ({ rowIndex }: TVDWrappedCellCallbackParameters): React$Element<'div'> => {
                const {
                  [TOTAL_PRICE_IN_CURRENCY]: unformattedValue
                } = primaryBuildingElementsEstimateScheduleItems[rowIndex].columnData
                const value = unformattedValue ? thousandSeparator(dotsToCommas(showNDecimals(unformattedValue, 1))) : null
                return (
                  <div className={classes.bodyNumbers}>{value}</div>
                )
              },
          }}
          columns={[
            {
              propertyName: 'Descr',
              localizedName: ''
            },
            {
              propertyName: TOTAL_PRICE_IN_CURRENCY,
              localizedName: primaryBuildingElementsEstimateColumns
              .find((col: TVDSchemaValueProperty): boolean => col.propertyName === TOTAL_PRICE_IN_CURRENCY)
              ?.unit
            },
          ]}
          listItems={primaryBuildingElementsEstimateScheduleItems}
          results={primaryBuildingElementsEstimateValues} />
        }
        {allowedModuleStatuses[WOP] &&
        <SummaryBlock
          testId='summary-block-wop'
          subtitle={(<div className={classes.subtitleWrapper} />)}
          isLoading={loadingStatuses[WOP]}
          title={t('apps._WOP_')}
          titleImageSrc={imgSrcs.WOP}
          titleSourceText={primaryWOPEstimateByIdData?.description}
          titleButtonText={t('summaryView._OPEN_WOP_ESTIMATE_')}
          onTitleButtonClick={() => {
            setSelectedApp(WOP)
          }}
          initialColumnWidths={{
            DriverValue: 200
          }}
          wrappedCellContents={{
            Descr: ({ rowIndex }: TVDWrappedCellCallbackParameters): React$Element<'div'> => {
              const {
                columnData = {}
              } = primaryWOPEstimateScheduleItems[rowIndex] || {}
              return (
                <div>{columnData[DESCRIPTION]}</div>
              )
            },
              DriverValue: ({ rowIndex }: TVDWrappedCellCallbackParameters): React$Element<'div'> => {
                const {
                  columnData: {
                    [DRIVER]: unformattedValue
                  },
                  columnUnits: {
                    [DRIVER]: driverUnit
                  } = {}
                } = primaryWOPEstimateScheduleItems[rowIndex]
                const value = unformattedValue && typeof unformattedValue === 'number' ?
                  thousandSeparator(dotsToCommas(showNDecimals(unformattedValue, 1, true))) :
                  null
                return (
                  <div><span className={classes.bodyNumbers}>{value}</span> {driverUnit}</div>
                )
              },
          }}
          columns={[
            {
              propertyName: 'Descr',
              localizedName: ''
            },
            {
              propertyName: 'DriverValue',
              localizedName: primaryWOPEstimateColumns.find((col: TVDSchemaValueProperty): boolean => col.propertyName === DRIVER)?.localizedName,
            },
          ]}
          listItems={primaryWOPEstimateScheduleItems}
          results={primaryWOPEstimateValues} />
        }
        {allowedModuleStatuses[LOCAN] &&
        <SummaryBlock
          testId='summary-block-locan'
          isLoading={loadingStatuses[LOCAN]}
          title={t('apps._LOCAN_')}
          subtitle={(
            <div className={classes.subtitleWrapper}>
              {primaryLOCANEstimateAddressStr && (
              <div className={classes.locationOnIconWrapper}>
                <LocationOnOutlinedIcon className={classes.locationOnIcon} />
              </div>
            )}
              {primaryLOCANEstimateAddressStr}
            </div>
          )}
          titleImageSrc={imgSrcs.LOCAN}
          titleSourceText={primaryLocanEstimateByIdData?.description || ''}
          titleButtonText={t('summaryView._OPEN_LOCAN_ESTIMATE_')}
          onTitleButtonClick={() => {
            setSelectedApp(LOCAN)
          }}
          wrappedCellContents={{
            Descr: ({ rowIndex }: TVDWrappedCellCallbackParameters): React$Element<'div'> => {
              const {
                columnData = {}
              } = primaryLOCANEstimateFunctionalSectors[rowIndex]
              return (
                <div>{columnData[DESCRIPTION]}</div>
              )
            },
              [PERMITTED_BUILDING_AREA_M2]: ({ rowIndex }: TVDWrappedCellCallbackParameters): React$Element<'div'> => {
                const {
                  [PERMITTED_BUILDING_AREA_M2]: unformattedValue
                } = primaryLOCANEstimateFunctionalSectors[rowIndex].columnData
                const value = unformattedValue ? thousandSeparator(dotsToCommas(showNDecimals(unformattedValue, 1))) : null
                return (
                  <div className={classes.bodyNumbers}>{value}</div>
                )
              },
              [PERMITTED_BUILDING_AREA_PRICE]: ({ rowIndex }: TVDWrappedCellCallbackParameters): React$Element<'div'> => {
                const {
                  [PERMITTED_BUILDING_AREA_PRICE]: unformattedValue
                } = primaryLOCANEstimateFunctionalSectors[rowIndex].columnData
                const value = unformattedValue ? thousandSeparator(dotsToCommas(showNDecimals(unformattedValue, 1))) : null
                return (
                  <div className={classes.bodyNumbers}>{value}</div>
                )
              },
          }}
          columns={[
            {
              propertyName: 'Descr',
              localizedName: ''
            },
            {
              propertyName: PERMITTED_BUILDING_AREA_M2,
              localizedName: primaryLOCANEstimateFunctionalSectorsUnits[PERMITTED_BUILDING_AREA_M2],
            },
            {
              propertyName: PERMITTED_BUILDING_AREA_PRICE,
              localizedName: primaryLOCANEstimateFunctionalSectorsUnits[PERMITTED_BUILDING_AREA_PRICE],
            },
          ]}
          listItems={primaryLOCANEstimateFunctionalSectors}
          results={primaryLOCANEstimateSummaryValues} />
          }
        {allowedModuleStatuses[PROFITABILITY] &&
        <SummaryBlock
          testId='summary-block-profitability'
          isLoading={loadingStatuses[PROFITABILITY]}
          title={t('apps._PROFITABILITY_')}
          subtitle={(<div className={classes.subtitleWrapper} />)}
          titleImageSrc={imgSrcs.PROFITABILITY}
          titleSourceText={primaryProfitabilityEstimateByIdData?.description || ''}
          titleButtonText={t('summaryView._OPEN_PROFITABILITY_ESTIMATE_')}
          className={classes.profitabilityBlock}
          initialColumnWidths={{
            [PROF_INVESTMENT_VALUE_IN_CURRENCY]: 150,
              [PROF_TARGET_RENT]: 200,
              [PROF_RATE_OF_RETURN]: 150
          }}
          onTitleButtonClick={() => {
            setSelectedApp(PROFITABILITY)
          }}
          wrappedCellContents={{
            Descr: ({ rowIndex }: TVDWrappedCellCallbackParameters): React$Element<'div'> => {
              const {
                columnData = {}
              } = primaryProfitabilityEstimateItems[rowIndex]
              return (
                <div>{columnData[DESCRIPTION]}</div>
              )
            },
              [PROF_INVESTMENT_VALUE_IN_CURRENCY]: ({ rowIndex }: TVDWrappedCellCallbackParameters): React$Element<'div'> => {
                const {
                  columnData: {
                    [PROF_INVESTMENT_VALUE_IN_CURRENCY]: unformattedValue
                  } = {}
                } = primaryProfitabilityEstimateItems[rowIndex]
                const value = unformattedValue ? thousandSeparator(dotsToCommas(showNDecimals(unformattedValue, 1))) : null
                return (
                  <div className={classes.bodyNumbers}>{value}</div>
                )
              },
              [PROF_TARGET_RENT]: ({ rowIndex }: TVDWrappedCellCallbackParameters): React$Element<'div'> => {
                const {
                  columnData: {
                    [PROF_TARGET_RENT]: unformattedValue
                  } = {}
                } = primaryProfitabilityEstimateItems[rowIndex]
                const value = unformattedValue ? thousandSeparator(dotsToCommas(showNDecimals(unformattedValue, 1))) : null
                return (
                  <div className={classes.bodyNumbers}>{value}</div>
                )
              },
              [PROF_RATE_OF_RETURN]: ({ rowIndex }: TVDWrappedCellCallbackParameters): React$Element<'div'> => {
                const {
                  columnData: {
                    [PROF_RATE_OF_RETURN]: unformattedValue
                  } = {}
                } = primaryProfitabilityEstimateItems[rowIndex]
                const value = unformattedValue ? thousandSeparator(dotsToCommas(showNDecimals(unformattedValue, 1))) : null
                return (
                  <div>{value}</div>
                )
              },
          }}
          columns={[
            {
              propertyName: 'Descr',
              localizedName: ''
            },
            ...primaryProfitabilityEstimateColumns.filter((col: TVDSchemaValueProperty): boolean => col.propertyName !== PROF_DESCRIPTION)
          ]
          }
          listItems={primaryProfitabilityEstimateItems}
          results={primaryProfitabilityEstimateValues}
          resultBarFormatOptions={profitabilityResultBarFormatOptions} />
        }
        {allowedModuleStatuses[PROCUREMENT_ELEMENTS] &&
          <SummaryBlock
            testId='summary-block-procurement-elements'
            isLoading={loadingStatuses[PROFITABILITY]}
            title={t('apps._PROCUREMENT_ELEMENTS_')}
            subtitle={(<div className={classes.subtitleWrapper} />)}
            titleImageSrc={imgSrcs.PROCUREMENT_ELEMENTS}
            titleSourceText={primaryProcurementElementsEstimateByIdData?.description || ''}
            titleButtonText={t('summaryView._OPEN_PROCUREMENT_ELEMENTS_ESTIMATE_')}
            className={classes.profitabilityBlock}
            initialColumnWidths={{
              [PRICE_TOTAL_IN_CURRENCY]: 250
            }}
            onTitleButtonClick={() => {
              setSelectedApp(PROCUREMENT_ELEMENTS)
            }}
            wrappedCellContents={{
              Descr: ({ rowIndex, row }: TVDWrappedCellCallbackParameters): React$Element<'div'> => {
                const {
                  columnData = {}
                } = primaryProcurementsEstimateItems[rowIndex]
                return (
                  <div style={{ paddingLeft: row.parentId ? 16 : 0 }}>{columnData[DESCRIPTION]}</div>
                )
              },
              [PRICE_TOTAL_IN_CURRENCY]: ({ rowIndex }: TVDWrappedCellCallbackParameters): React$Element<'div'> => {
                const {
                  columnData: {
                    [PRICE_TOTAL_IN_CURRENCY]: unformattedValue
                  } = {}
                } = primaryProcurementsEstimateItems[rowIndex]
                const value = unformattedValue ? thousandSeparator(dotsToCommas(showNDecimals(unformattedValue, 1))) : null
                return (
                  <div className={classes.bodyNumbers}>{value}</div>
                )
              }
            }}
            columns={primaryProcurementsEstimateColumns}
            listItems={primaryProcurementsEstimateItems}
            results={primaryProcurementsEstimateValues} />
        }
        {allowedModuleStatuses[RENOVATION_PROGRAM] &&
          <SummaryBlock
            testId='summary-block-renovation-program'
            isLoading={loadingStatuses[RENOVATION_PROGRAM]}
            title={t('apps._RENOVATION_')}
            subtitle={(<div className={classes.subtitleWrapper} />)}
            titleImageSrc={imgSrcs.RENOVATION_PROGRAM}
            titleSourceText={primaryRenovationEstimateByIdData?.description || ''}
            titleButtonText={t('summaryView._OPEN_RENOVATION_PROGRAM_ESTIMATE_')}
            className={classes.profitabilityBlock}
            onTitleButtonClick={() => {
              setSelectedApp(RENOVATION_PROGRAM)
            }}
            initialColumnWidths={{
              Year: 150,
              PriceTotalRenovationsInCurrency: 150
            }}
            wrappedCellContents={{
              Descr: ({ row }: TVDWrappedCellCallbackParameters): React$Element<'div'> =>
                <div>{row.description}</div>,
              Year: ({ row }: TVDWrappedCellCallbackParameters): React$Element<'div'> =>
                <div className={classes.bodyNumbers}>{row.year}</div>,
              PriceTotalRenovationsInCurrency: ({ row }: TVDWrappedCellCallbackParameters): React$Element<'div'> => {
                const value = row.priceTotalRenovationsInCurrency ? formatCellContent(row.priceTotalRenovationsInCurrency, 'integer') : null
                return (
                  <div>
                    {
                      value && (
                        <>
                          <span className={classes.bodyNumbers}>{value}</span>€
                        </>
                      )
                    }
                  </div>
                )
              }
            }}
            columns={
             primaryRenovationEstimateColumns
            }
            listItems={primaryRenovationEstimateItems}
            results={primaryRenovationEstimateValues} />
        }
        {allowedModuleStatuses[USAGE_AND_MAINTENANCE] &&
          <SummaryBlockContainer
            testId='summary-block-usage-and-maintenance'
            title={t('apps._USAGE_AND_MAINTENANCE_')}
            subtitle={(<div className={classes.subtitleWrapper} />)}
            titleImageSrc={imgSrcs.USAGE_AND_MAINTENANCE}
            titleSourceText={primaryUsageMaintenanceData?.description || ''}
            titleButtonText={t('summaryView._OPEN_USAGE_AND_MAINTENANCE_ESTIMATE_')}
            onTitleButtonClick={() => {
              setSelectedApp(USAGE_AND_MAINTENANCE)
            }}>
            <UsageMaintenanceSummary />
          </SummaryBlockContainer>
        }
      </div>
    </div>

  )
}

export default SummaryView
