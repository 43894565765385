// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useStore } from 'react-redux'
import { loadMFE } from '../../../utils/MFEUtils'
import { API_BASE_URL, ALLOW_WITH_CREDENTIALS, FEATURE_SET } from '../../../constants/envConstants'
import { headerHeight } from '../../../components/common/Header/Header'
import { openGenericErrorModal } from '../../../utils/modalUtils'
import getWebWorker from '../../../utils/getWebWorker'
import { refreshUserAccessToken } from '../../../actions/user'

const WebWorker = getWebWorker()

const useStyles = makeStyles(({ palette }: TVDTheme) => ({
  root: {
    height: '100%',
    width: '100%',
    paddingTop: headerHeight,
    background: palette.gray20
  }
}))

const CostTrackingMFEContainer = (): React$Element<'div'> => {
  const moduleOnUnmountRef = useRef<$PropertyType<TVDMFEFunctionality, 'onUnmount'>>()
  const classes = useStyles()
  const { dispatch } = useStore()
  const {
    realEstateId,
    accessToken,
    haahtelaApiUserToken,
    userId,
    languageCode,
    selectedAccountId,
    siteId,
    realEstateName,
    application,
    buildingId
  } = useSelector(({
    app,
    user
  }: TVDReduxStore): Object => ({
    application: app.application,
    languageCode: app.languageCode,
    selectedAccountId: app.selectedAccountId,
    realEstateId: app.realEstateId,
    realEstateName: app.realEstateName,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    siteId: app.siteId,
    buildingId: app.buildingId
  }))

  useEffect(() => () => {
    if (moduleOnUnmountRef.current) {
      moduleOnUnmountRef.current()
    }
  }, [application])

  useEffect(() => {
    loadMFE('cost_tracking', 'cost-tracking-module', (module: TVDMFEFunctionality) => {
      if (
        languageCode &&
        accessToken &&
        haahtelaApiUserToken &&
        userId &&
        selectedAccountId &&
        realEstateId &&
        siteId &&
        buildingId
      ) {
        const render = module.render || null
        if (render) {
          moduleOnUnmountRef.current = module.onUnmount
          render(
            'cost-tracking',
            {
              APIBaseURL: API_BASE_URL,
              languageCode,
              accessToken,
              haahtelaApiUserToken,
              userId,
              selectedAccountId,
              realEstateId,
              siteId,
              WebWorker,
              ALLOW_WITH_CREDENTIALS,
              openGenericErrorModal,
              FEATURE_SET,
              realEstateName,
              refreshUserAccessToken,
              buildingId
            }
          )
        }
      }
    })
  }, [
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    selectedAccountId,
    realEstateId,
    siteId,
    realEstateName,
    dispatch,
    buildingId
  ])
  return (
    <div className={classes.root} id='cost-tracking' />
  )
}

export default CostTrackingMFEContainer

