// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { debounce, isEqual, split, upperFirst } from 'lodash'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { ESTIMATE_TYPE_WOP } from '../../../../constants/moduleConstants'

import FeaturesHOC from '../../../hocs/FeaturesHOC/FeaturesHOC'
import LabeledInput from '../../../common/LabeledInput/LabeledInput'
import ModalForm from '../../../common/ModalForm/ModalForm'
import CheckBox from '../../../common/CheckBox/CheckBox'
import Feature from '../../Feature/Feature'
import InfoPopover, { ESTIMATE_IS_PRIVATE_INFO } from '../../../common/InfoPopover/InfoPopover'

import { closeModal, buildConfirmationModal } from '../../../../actions/modals'
import { buildingAndEstimateNameIgnoredCharacters } from '../../../../constants/validationConstants'
import { MODAL_TYPE_CALCULATION_INFORMATION } from '../../../../constants/modalConstants'
import { FEATURE_IS_ESTIMATE_PRIVATE_CHECKBOX } from '../../../../constants/features'
import { dateToday } from '../../../../utils/datepickerUtils'
import { getEstimatesIndexpointsRequest } from '../../../../utils/generated-api-requests/estimates'
import { deleteEstimateLockWithEstimateIdRequest } from '../../../../utils/generated-api-requests/spaces'
import { validateName } from '../../../../utils/validators'
import { type ValidatorInformation } from '../../../common/Validator/Validator'
import { commasToDots } from '../../../../utils/commonUtils'


const styles = ({ palette }: TVDTheme): Object => ({
  container: {
    width: '100%',
    padding: '16px 34px 22px 60px'
  },
  wrapper: {
    '&:hover [data-visible_on_hover]': {
      visibility: 'visible'
    },
    display: 'flex',
    alignItems: 'center'
  },
  checkboxWrapper: {
    '&:hover [data-visible_on_hover]': {
      visibility: 'visible'
    },
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px'
  },
  label: {
    color: palette.nevada,
    marginLeft: '17px'
  },
  infoPopover: {
    marginLeft: '10px'
  }
})

export type CalculationInformationProps = {
  estimateDescription: string, // string from estimateDescription field
  assessmentDate: string, // string from assessmentDate field
  priceLevelChangeP: number, // integer from priceLevelChangeP field
  indexNumberFinal: string, // string from indexNumberFinal field
  id: string, // calculation id
  testId: string, // id string for testing purposes
  hasLock?: boolean, // if the data in component is considered as locked by API and are not to be edited
  languageCode?: TVDSupportedLanguageCodes, // string value of a supported language code used by the application
  isFrozen?: boolean, // if the estimate is frozen
}

type HOCProps = {|
  t: Function, // translate
  classes: Object, // styles object
|}

type MappedProps = {|
  activeCalculation?: boolean, // flag that indicates if calculation is running
  buildingId: string, // selected building id
  languageCode: string, // user selected language code
|}

type DispatchProps = {|
  dispatchCloseModal: () => void, // function bound to cancel / close button in FooterButtons
  dispatchBuildConfirmationModal: (Object) => void, // function to create confirmationDialog if needed
|}

type ReceivedProps = {|
  hideCancelButton?: boolean, // flag to hide cancelButton
  onSave: Function, // function bound to save button in FooterButtons,
  isEditing: boolean, // indicates if editing or creating a new estimate
  isExpanded?: boolean, // indicates if component is expanded when used in Expandable-component
  estimateType: string, // name of the application the estimate is for
  showIsPrivateCheckbox?: boolean, // flag to show check box
|}

type Props = {
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
  ...ReceivedProps,
  ...$Exact<CalculationInformationProps>,
}

type State = {
  estimateDescription: string, // string from estimateDescription field
  estimateDescriptionValid: boolean, // whether the estimateDescription is valid
  assessmentDate: string, // string from assessmentDate field
  assessmentDateValid: boolean, // whether the assessmentDate is valid
  priceLevelChangeP: number, // price level change integer
  priceLevelChangePValid: boolean, // whether the priceLevelChangeP is valid
  indexPoints: string, // index points integer
  isPrivate: boolean, // boolean to make estimate private so only the estimate creator can see it
}

const INDEX_NUMBER_FINAL = 'indexNumberFinal'
const PRICE_LEVEL_CHANGE_P = 'priceLevelChangeP'
const ASSESSMENT_DATE = 'assessmentDate'
const IS_PRIVATE = 'isPrivate'

export class CalculationInformation extends Component<Props, State> {
  static defaultProps = {
    isExpanded: false,
    estimateDescription: '',
    assessmentDate: dateToday(),
    priceLevelChangeP: 0,
    indexNumberFinal: '',
    hideCancelButton: false,
    activeCalculation: false,
    hasLock: false,
    isFrozen: false
  }

  state = {
    estimateDescription: this.props.estimateDescription,
    estimateDescriptionValid: true,
    assessmentDate: this.props.assessmentDate,
    assessmentDateValid: true,
    priceLevelChangeP: this.props.priceLevelChangeP,
    priceLevelChangePValid: true,
    indexPoints: this.props.indexNumberFinal,
    isPrivate: false
  }

  _mounted: boolean

  debouncedGetIndexPoints = debounce(() => {
    if (this._mounted) this.getIndexPoints()
  }, 500)

  componentDidMount() {
    this._mounted = true
    if (!this.props.indexNumberFinal) {
      this.getIndexPoints()
    }
    if (!this.props.estimateDescription) {
      const { t } = this.props
      this.setState({ estimateDescription: t('createCalculation._NEW_ESTIMATE_') })
    }
  }

  componentDidUpdate(prevProps: Object, prevState: Object) {
    if (prevState.priceLevelChangePValid !== this.state.priceLevelChangePValid || prevState.assessmentDateValid !== this.state.assessmentDateValid) {
      this.debouncedGetIndexPoints()
    }
  }

  componentWillUnmount() {
    this._mounted = false
  }

  setValidity = (key: string, validity: boolean) => {
    const validitykey = `${key}Valid`
    if (this.state[validitykey] !== validity) {
      this.setState({ [validitykey]: validity })
    }
  }

  checkValidity = () => {
    const { hasLock, isFrozen } = this.props
    if (hasLock || isFrozen) return false

    const { estimateDescriptionValid, assessmentDateValid, priceLevelChangePValid } = this.state
    return Boolean(estimateDescriptionValid && assessmentDateValid && priceLevelChangePValid)
  }


  handleChange = (eventID: string, eventValue: string) => {
    const stateKey = split(eventID, '-')[1]
    this.setState({ [stateKey]: eventValue })
  }

  handleUserInput = (event: SyntheticInputEvent<any>) => {
    this.handleChange(event.target.id, event.target.value)

    const shouldUpdateIndexPoints = !event.target.id.endsWith('estimateDescription')
      && this.state.assessmentDateValid
      && this.state.priceLevelChangePValid

    if (shouldUpdateIndexPoints) {
      this.debouncedGetIndexPoints()
    }
  }

  handleClose = () => {
    const { dispatchBuildConfirmationModal, dispatchCloseModal } = this.props
    const props = [this.props.estimateDescription, this.props.priceLevelChangeP, this.props.assessmentDate]
    const state = [this.state.estimateDescription, this.state.priceLevelChangeP, this.state.assessmentDate]
    const modified = !isEqual(props, state)

    if (modified) {
      dispatchBuildConfirmationModal({ onSave: () => dispatchCloseModal() })
    } else {
      dispatchCloseModal()
    }
  }

  getIndexPoints = () => {
    const { buildingId, estimateType } = this.props
    const { assessmentDate, priceLevelChangeP } = this.state
    if (!this.state.priceLevelChangePValid || !this.state.assessmentDateValid) {
      return
    }
    if (estimateType !== ESTIMATE_TYPE_WOP) {
      getEstimatesIndexpointsRequest({
        query: {
          assessmentDate,
          priceLevelChangeP: Number(commasToDots(priceLevelChangeP)),
          buildingId,
        }
      }, {}, ({ points: indexPoints }: Object) => {
        this.setState({ indexPoints })
      })
    }
  }

  estimateDescription(): React$Element<typeof LabeledInput> {
    const {
      classes,
      t,
      hasLock,
      isFrozen,
      isExpanded,
      isEditing
    } = this.props

    const inputProps: TVDLabeledInput = {
      dataType: 'string',
      value: this.state.estimateDescription,
      handleChange: this.handleUserInput,
      size: 'XL',
      disabled: hasLock || isFrozen
    }

    const render = isExpanded || isEditing
    const focused = isExpanded && !isEditing

    return (
      <div className={classes.wrapper}>
        { render && <LabeledInput
          {...inputProps}
          focused={focused}
          ignoreCharacters={buildingAndEstimateNameIgnoredCharacters}
          label={t('createCalculation._ESTIMATE_NAME_')}
          id={`${this.props.testId}-estimateDescription`}
          required
          isValidCb={(isValid: boolean) => this.setValidity('estimateDescription', isValid)}
          customValidator={({ value }: ValidatorInformation) => validateName(value)} />
        }
      </div>
    )
  }

  assessmentDate(): React$Element<typeof LabeledInput> {
    const {
      classes,
      t,
      hasLock,
      languageCode,
      isFrozen
    } = this.props

    const inputProps: TVDLabeledInput = {
      dataType: 'date',
      value: this.state.assessmentDate,
      handleChange: this.handleUserInput,
      size: 'L',
      disabled: hasLock || isFrozen
    }

    return (
      <div className={classes.wrapper}>
        <LabeledInput
          {...inputProps}
          languageCode={languageCode}
          showError={!this.state.indexPoints}
          label={t('createCalculation._INDEX_DATE_')}
          id={`${this.props.testId}-${ASSESSMENT_DATE}`}
          required
          isValidCb={(isValid: boolean) => { this.setValidity(ASSESSMENT_DATE, isValid) }}
          date />
        <div data-visible_on_hover>
          <InfoPopover
            infoData={{
              propertyName: upperFirst(ASSESSMENT_DATE),
              type: MODAL_TYPE_CALCULATION_INFORMATION
            }}
            id={`${this.props.testId}-${ASSESSMENT_DATE}-InfoPopover`} />
        </div>
      </div>
    )
  }

  priceLevelChangeP(): React$Element<typeof LabeledInput> {
    const {
      classes,
      t,
      hasLock,
      isFrozen
    } = this.props

    const inputProps: TVDLabeledInput = {
      dataType: 'string',
      value: this.state.priceLevelChangeP.toString(),
      handleChange: this.handleUserInput,
      size: 'M',
      disabled: hasLock || isFrozen
    }

    return (
      <div className={classes.wrapper}>
        <LabeledInput
          {...inputProps}
          customValidator={({ value }: ValidatorInformation) =>
              !isNaN(Number(commasToDots(value))) && (
                value.toString().split(',').length - 1 < 2 ||
                value.toString().split('.').length - 1 < 2
              )
          }
          label={t('createCalculation._INDEX_ADJUSTMENT_')}
          id={`${this.props.testId}-${PRICE_LEVEL_CHANGE_P}`}
          adornment='%'
          isValidCb={(isValid: boolean) => this.setValidity(PRICE_LEVEL_CHANGE_P, isValid)}
          required />
        <div data-visible_on_hover style={{ marginTop: '15px' }}>
          <InfoPopover
            infoData={{
              propertyName: upperFirst(PRICE_LEVEL_CHANGE_P),
              type: MODAL_TYPE_CALCULATION_INFORMATION
            }}
            id={`${this.props.testId}-${PRICE_LEVEL_CHANGE_P}-InfoPopover`} />
        </div>
      </div>
    )
  }

  indexPoints(): React$Element<typeof LabeledInput> {
    const { classes, t, testId } = this.props

    const inputProps: TVDLabeledInput = {
      dataType: this.state.priceLevelChangePValid ? 'number' : 'string',
      value: this.state.priceLevelChangePValid ? this.state.indexPoints : 'NaN',
      size: 'M',
    }

    return (
      <div className={classes.wrapper}>
        <LabeledInput
          {...inputProps}
          label={t('createCalculation._INDEX_POINTS_')}
          id={`${testId}-${INDEX_NUMBER_FINAL}`}
          disableValidation
          disabled />
        <div data-visible_on_hover style={{ marginTop: '15px' }}>
          <InfoPopover
            infoData={{
              propertyName: upperFirst(INDEX_NUMBER_FINAL),
              type: MODAL_TYPE_CALCULATION_INFORMATION
            }}
            id={`${testId}-${INDEX_NUMBER_FINAL}`} />
        </div>
      </div>
    )
  }

  isPrivateCheckBox(): React$Element<'div'> {
    const {
      classes, t, testId, showIsPrivateCheckbox
    } = this.props
    return (
      <div className={classes.checkboxWrapper}>
        {showIsPrivateCheckbox &&
        <Feature name={FEATURE_IS_ESTIMATE_PRIVATE_CHECKBOX}>
          <CheckBox
            testid={`checkbox-${IS_PRIVATE}`}
            checked={this.state.isPrivate}
            name={IS_PRIVATE}
            onChange={() => { this.setState((prevState: State): $Shape<State> => ({ isPrivate: !prevState.isPrivate })) }} />
          <div className={classes.label}>{t('createCalculation._IS_PRIVATE_')}</div>
          <div data-visible_on_hover className={classes.infoPopover}>
            <InfoPopover
              infoData={{
              propertyName: upperFirst(IS_PRIVATE),
              type: ESTIMATE_IS_PRIVATE_INFO
            }}
              id={`${testId}-${IS_PRIVATE}`} />
          </div>
        </Feature>}
      </div>
    )
  }

  inputs(): LabeledInput {
    const { estimateType } = this.props

    return estimateType !== ESTIMATE_TYPE_WOP
      ? [this.estimateDescription(), this.assessmentDate(), this.priceLevelChangeP(), this.indexPoints(), this.isPrivateCheckBox()]
      : [this.estimateDescription(), this.isPrivateCheckBox()]
  }

  modalForm(): React$Element<typeof ModalForm> {
    const fixedState = {
      ...this.state,
      priceLevelChangeP: Number(commasToDots(this.state.priceLevelChangeP))
    }
    return ((
      <ModalForm
        testId={this.props.testId}
        items={this.inputs()}
        onSave={() => this.props.onSave(fixedState, this.props.id)}
        onClose={this.handleClose}
        valid={this.checkValidity()}
        hideCancel={this.props.hideCancelButton}
        activeCalculation={this.props.activeCalculation} />
    ))
  }

  render(): React$Element<ModalForm> {
    return (
      <div className={this.props.classes.container}>
        {this.modalForm()}
      </div>
    )
  }
}

type ownProps = {
  id: string
}

const mapStateToProps = ({ app: { activeCalculation, buildingId, languageCode } }: TVDReduxStore): MappedProps => ({
  activeCalculation,
  buildingId,
  languageCode
})

const mapDispatchToProps = (dispatch: Function, { id }: ownProps) => ({
  dispatchBuildConfirmationModal: (content: Object) => { dispatch(buildConfirmationModal(content)) },
  dispatchCloseModal: () => {
    deleteEstimateLockWithEstimateIdRequest({}, null, null, { estimateId: id })
    dispatch(closeModal(id))
  }
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation('translations'),
  FeaturesHOC
)(CalculationInformation)
