// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { loadMFE, registeredMFEs } from '../../../utils/MFEUtils'
import { API_BASE_URL, ALLOW_WITH_CREDENTIALS, FEATURE_SET } from '../../../constants/envConstants'
import { openGenericErrorModal } from '../../../utils/modalUtils'
import { dispatch } from '../../../store'
import { refreshUserAccessToken } from '../../../actions/user'
import { postPolling } from '../../../actions/postPolling'
import getWebWorker from '../../../utils/getWebWorker'
import { setActiveEstimate } from '../../../actions/activeEstimate'
import { setMFEStates } from '../../../actions/MFEStates'
import {
  BUILDING_ELEMENTS_ASSEMBLY_PRICE_LIST_MFENAME,
  BUILDING_ELEMENTS_ASSEMBLY_PRICE_LIST_COMPONENTNAME,
  BUILDING_ELEMENTS_PRODUCT_ASSEMBLY_MFENAME,
  BUILDING_ELEMENTS_PRODUCT_ASSEMBLY_COMPONENTNAME,
  CUSTOM_BUILDING_ELEMENT_ASSEMBLY_MODAL_MFENAME,
  CUSTOM_BUILDING_ELEMENT_ASSEMBLY_MODAL_COMPONENTNAME
} from '../../../constants/MFEStateConstants'

const WebWorker = getWebWorker()
export const buildingElementsScheduleMFERootId = 'building-elements-schedule'

const BuildingElementsScheduleListMFEContainer = (): React$Element<'div'> => {
  const {
    application,
    languageCode,
    selectedAccountId,
    realEstateId,
    realEstateName,
    accessToken,
    haahtelaApiUserToken,
    userId,
    activeEstimate: activeBuildingElementsEstimate,
    postPollingTS,
    activeEdit,
    activeCalculation,
    isEstimateLockedToCurrentUser,
  } = useSelector(({
    activeEstimate,
    app,
    user
  }: TVDReduxStore): Object => ({
    application: app.application,
    languageCode: app.languageCode,
    selectedAccountId: app.selectedAccountId,
    realEstateId: app.realEstateId,
    realEstateName: app.realEstateName,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    activeEstimate,
    postPollingTS: app.postPollingTS,
    activeEdit: app.activeEdit,
    activeCalculation: app.activeCalculation,
    isEstimateLockedToCurrentUser: app.isEstimateLockedToCurrentUser
  }))

  const moduleOnUnmountRef = useRef<$PropertyType<TVDMFEFunctionality, 'onUnmount'>>()

  useEffect(() => () => {
    if (moduleOnUnmountRef.current) {
      moduleOnUnmountRef.current()
    }
  }, [application])

  const registeredBuildingElementsAPIModule = registeredMFEs['building-elements-api-module']

  const updatePostPollingTS = (cb?: () => void) => {
    dispatch(postPolling(undefined, cb))
  }

  useEffect(() => {
    if (
      languageCode &&
      selectedAccountId &&
      realEstateId &&
      accessToken &&
      haahtelaApiUserToken &&
      userId
    ) {
      const setActiveEstimateToStore = (estimate: TVDCalculation): void => {
        dispatch(setActiveEstimate(estimate))
      }

      const openBEAssemblyPriceListWidget = (openedFromId: string) => {
        dispatch(setMFEStates(
          BUILDING_ELEMENTS_ASSEMBLY_PRICE_LIST_MFENAME,
          true,
          BUILDING_ELEMENTS_ASSEMBLY_PRICE_LIST_COMPONENTNAME,
          { openedFromId }
        ))
      }

      const openBEProductAssemblyWidget = (buildingElementAssemblyId: string, buildingElementAssemblyDescription: string) => {
        dispatch(setMFEStates(
          BUILDING_ELEMENTS_PRODUCT_ASSEMBLY_MFENAME,
          true,
          BUILDING_ELEMENTS_PRODUCT_ASSEMBLY_COMPONENTNAME,
          {
            buildingElementAssemblyId,
            buildingElementAssemblyDescription
          }
        ))
      }

      const openCustomBEAssemblyModal = (buildingElementClassificationId: string) => {
        dispatch(setMFEStates(
          CUSTOM_BUILDING_ELEMENT_ASSEMBLY_MODAL_MFENAME,
          true,
          CUSTOM_BUILDING_ELEMENT_ASSEMBLY_MODAL_COMPONENTNAME,
          {
            buildingElementClassificationId
          }
        ))
      }

      const render = (module: TVDMFEFunctionality): void => {
        const moduleRun = module.run || null
        if (moduleRun) {
          moduleOnUnmountRef.current = module.onUnmount
          moduleRun(
            'renderBuildingElementsSchedule',
            [
              buildingElementsScheduleMFERootId,
              {
                languageCode,
                selectedAccountId,
                realEstateId,
                realEstateName,
                accessToken,
                haahtelaApiUserToken,
                userId,
                estimateId: activeBuildingElementsEstimate.id,
                activeEstimate: activeBuildingElementsEstimate,
                APIBaseURL: API_BASE_URL,
                ALLOW_WITH_CREDENTIALS,
                FEATURE_SET,
                WebWorker,
                openGenericErrorModal,
                refreshUserAccessToken,
                postPollingTS,
                updatePostPollingTS,
                activeEdit,
                activeCalculation,
                isEstimateLockedToCurrentUser,
                setActiveEstimateToStore,
                openBEAssemblyPriceListWidget,
                openBEProductAssemblyWidget,
                openCustomBEAssemblyModal
              }
            ]
          )
        }
      }
      if (registeredBuildingElementsAPIModule) {
        render(registeredBuildingElementsAPIModule)
      } else {
        loadMFE('building_elements', 'building-elements-api-module', (module: TVDMFEFunctionality): void => {
          render(module)
        })
      }
    }
  }, [
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    activeBuildingElementsEstimate,
    selectedAccountId,
    realEstateId,
    realEstateName,
    registeredBuildingElementsAPIModule,
    postPollingTS,
    activeEdit,
    activeCalculation,
    isEstimateLockedToCurrentUser
  ])
  return (
    <div id={buildingElementsScheduleMFERootId} style={{ height: '100%' }} />
  )
}

export default BuildingElementsScheduleListMFEContainer
