/* eslint-disable react-hooks/exhaustive-deps */
// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import qs from 'query-string'
import { loadMFE } from '../../../utils/MFEUtils'
import { API_BASE_URL, ALLOW_WITH_CREDENTIALS } from '../../../constants/envConstants'
import { ACCOUNT_INFORMATION_VIEW_VALUE_ESTATE_MANAGEMENT } from '../../../constants/searchParams'
import { headerHeight } from '../../../components/common/Header/Header'
import { openGenericErrorModal } from '../../../utils/modalUtils'
import { dispatch } from '../../../store'
import { closeModal, openModal } from '../../../actions/modals'
import { MODAL_TYPE_USER_MODAL, RENAME_PROJECT } from '../../../constants/modalConstants'
import { getIsFeatureEnabledInSet } from '../../../utils/features'
import { FEATURE_ORGANIZATIONS } from '../../../constants/features'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    marginTop: headerHeight + 138,
    paddingRight: '8px',
  }
}))

const AccountInformation = (): React$Element<'div'> => {
  const classes = useStyles()
  const { t } = useTranslation()
  const moduleOnUnmountRef = useRef<$PropertyType<TVDMFEFunctionality, 'onUnmount'>>()
  const { location: { search } } = useHistory()
  const { accountInformationView } = qs.parse(search)
  const {
    accessToken,
    haahtelaApiUserToken,
    userId,
    languageCode,
    selectedAccountId,
    isEstimateLockedToCurrentUser,
    isEstimateFrozen,
    userPrivileges,
    selectedAccountName,
    realEstateLastUpdatedTS,
    isPausingEnabledForUseCaseProjectLicenses
  } = useSelector(({
    app,
    user,
  }: TVDReduxStore): {|
    languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
    selectedAccountId: $PropertyType<TVDApplicationStore, 'selectedAccountId'>,
    accessToken: $PropertyType<TVDUserAuthorization, 'accessToken'>,
    haahtelaApiUserToken: $PropertyType<TVDUserClaims, 'haahtelaApiUserToken'>,
    userId: $PropertyType<TVDUserClaims, 'userId'>,
    isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>,
    isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>,
    userPrivileges: $PropertyType<TVDUserStore, 'userPrivileges'>,
    selectedAccountName: $PropertyType<TVDApplicationStore, 'selectedAccountName'>,
    realEstateLastUpdatedTS: $PropertyType<TVDApplicationStore, 'realEstateLastUpdatedTS'>,
    isPausingEnabledForUseCaseProjectLicenses: $PropertyType<TVDApplicationStore, 'isPausingEnabledForUseCaseProjectLicenses'>,
  |} => ({
    languageCode: app.languageCode,
    selectedAccountId: app.selectedAccountId,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    isEstimateLockedToCurrentUser: app.isEstimateLockedToCurrentUser,
    isEstimateFrozen: app.isEstimateFrozen,
    userPrivileges: user.userPrivileges,
    selectedAccountName: app.selectedAccountName,
    realEstateLastUpdatedTS: app.realEstateLastUpdatedTS,
    isPausingEnabledForUseCaseProjectLicenses: app.isPausingEnabledForUseCaseProjectLicenses
  }))

  const openUserInfoModal = (description: string, id: string, isAccountManager: boolean, callback: Function) => {
    dispatch(openModal({
      type: MODAL_TYPE_USER_MODAL,
      containerType: 'dialog',
      disableEdit: !isAccountManager,
      estimateDescription: description,
      userId: id,
      showCheckBoxes: true,
      patchUserSuccessCallBack: () => { callback() }
    }, MODAL_TYPE_USER_MODAL))
  }

  const openRenameModal = (realEstateId: string, nameToRename: string): void => {
    dispatch(openModal(
      {
        type: RENAME_PROJECT,
        title: t('buttons._RENAME_'),
        contentProps: {
          realEstateId,
          nameToRename
        },
        onClose: () => dispatch(closeModal(RENAME_PROJECT))
      },
      RENAME_PROJECT
    ))
  }

  useEffect(() => {
    loadMFE('license_management', 'account-information-module', (module: TVDMFEFunctionality): void => {
      if (
        languageCode &&
        accessToken &&
        haahtelaApiUserToken &&
        userId &&
        selectedAccountId &&
        userPrivileges &&
        selectedAccountName
      ) {
        moduleOnUnmountRef.current = module.onUnmount
        const render = module.render || null
        if (render) {
          render(
            'account-information',
            {
              APIBaseURL: API_BASE_URL,
              languageCode,
              accessToken,
              haahtelaApiUserToken,
              userId,
              selectedAccountId,
              isEstimateFrozen,
              isEstimateLockedToCurrentUser,
              openGenericErrorModal,
              userPrivileges,
              selectedAccountName,
              openUserInfoModal,
              ALLOW_WITH_CREDENTIALS,
              openRenameModal,
              realEstateLastUpdatedTS,
              defaultToRealEstateManagementView: accountInformationView === ACCOUNT_INFORMATION_VIEW_VALUE_ESTATE_MANAGEMENT,
              isPausingEnabledForUseCaseProjectLicenses,
              isOrganizationsFeatureEnabled: getIsFeatureEnabledInSet(FEATURE_ORGANIZATIONS)
            }
          )
        }
      }
    })
    return () => {
      if (moduleOnUnmountRef.current) {
        moduleOnUnmountRef.current()
      }
    }
  }, [
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    selectedAccountId,
    isEstimateLockedToCurrentUser,
    isEstimateFrozen,
    userPrivileges,
    selectedAccountName,
    realEstateLastUpdatedTS,
    accountInformationView,
    isPausingEnabledForUseCaseProjectLicenses
  ])
  return (
    <div className={classes.root} id='account-information' />
  )
}

export default AccountInformation
