// @flow
// Copyright © 2010–2025 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import type { Node, Element } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { Chip } from 'frontend-components'
import { borderRadiuses } from 'frontend-assets'
// $FlowFixMe
import { ReactComponent as SVGIconAlertLockedFilled } from '../../../../node_modules/frontend-assets/static/assets/images/icons/Data Table Locked.svg'
import CalculationManagerModal from '../../scenes/LandingPage/CalculationManagerModal'
import { appTypeByName } from '../../../utils/commonUtils'
import OverflowTooltip from '../../../components/common/OverflowTooltip/OverflowTooltip'
import theme from '../../../styles/theme'
import { combineStyleClassNames } from '../../../utils/styleUtils'
import { getEstimateLockWithEstimateIdRequest } from '../../../utils/generated-api-requests/spaces'
import { getIsFeatureEnabledInSet } from '../../../utils/features'
import { FEATURE_LICENCE_TYPE_CHIP } from '../../../constants/features'
import { SPACES } from '../../../constants/moduleConstants'

const { borderRadiusLarge } = borderRadiuses

export const moduleHeaderHeight = 46

const styles = ({ palette }: Object): Object => ({
  widgetHeader: {
    flexDirection: 'column',
    boxShadow: 'none',
    position: 'absolute',
    top: 56,
    left: 90,
    paddingTop: 5,
    backgroundColor: 'transparent',
    minHeight: moduleHeaderHeight,
    width: '50%',
  },
  subHeader: {
    ...theme.typography.classes.bodyBigBold,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '8px',
    padding: '5px 8px 5px 32px',
    color: palette.dark80,
    borderRadius: borderRadiusLarge,
    position: 'absolute',
  },
  subLeft: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    gap: 16,
    minHeight: moduleHeaderHeight,
    marginRight: 32
  },
  estimateName: {
    color: palette.primary100,
    cursor: 'pointer'
  },
  lockedTextWrapper: {
    ...theme.typography.classes.bodySmall,
    color: palette.dark80,
    alignItems: 'center',
    display: 'flex',
    overflow: 'hidden'
  },
  lockIcon: {
    fontSize: '18px',
    color: palette.dark80,
    marginLeft: '16px',
    marginRight: '8px',
  },
  realEstateName: {
    color: palette.dark80,
  },
  estimateNotLockedText: {
    width: '100%'
  },
  disabled: {
    pointerEvents: 'none',
    color: palette.grey80,
  },
  chipsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  }
})

type HOCProps = {|
  t: Function, // translation function
  classes: Object, // classes-object created by withstyles function
|}

type MappedProps = {|
  disabled: boolean, // flag to disable header content if view is beign edited
  estimateName: string, // name of chosen estimate
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // if the user owns the lock for the estimate
  application: string, // the active module
  realEstateName: $PropertyType<TVDApplicationStore, 'realEstateName'>, // current selected real estate name
  userId: string, // id of the user
  licenseType: $PropertyType<TVDApplicationStore, 'licenseType'>, // type of TVD License, project, legacyProject or scenario
  indexNumberOnAssessmentDate: $PropertyType<TVDApplicationStore, 'indexNumberOnAssessmentDate'> // index number on assessment date of chosen estimate
|}

type DispatchProps = {|
|}

type Props = {|
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps
|}

type State = {|
  openApp: boolean,
  intervalId?: IntervalID, // an interval id to check the lock status
  isLockedStatus: boolean, // to check if the current module is used by another user or not
|}

const REFRESH_STATUS_MILLISECONDS = 30000 // 30 seconds

export class ModuleHeader extends Component<Props, State> {
  static defaultProps = {
    isEstimateLockedToCurrentUser: false,
  }
  state = {
    openApp: false,
    intervalId: undefined,
    isLockedStatus: false,
  }

  componentDidMount = () => {
    const { isEstimateLockedToCurrentUser } = this.props
    if (!isEstimateLockedToCurrentUser) {
      this.setState({ isLockedStatus: true })
      this.createRefreshInterval()
    }
  }

  componentWillUnmount = () => {
    const { intervalId } = this.state
    if (intervalId) this.removeRefreshInterval()
  }

  fetchModuleHeaderStatus = () => {
    getEstimateLockWithEstimateIdRequest(
      {},
      (res: TVDResourceLock) => {
        const isLockedStatus = res.secondsRemaining > 0 && res.userId !== this.props.userId
        this.setState({ isLockedStatus })
      }
    )
  }

  createRefreshInterval = () => {
    const intervalId = setInterval(this.fetchModuleHeaderStatus, REFRESH_STATUS_MILLISECONDS)
    this.setState({ intervalId })
  }

  removeRefreshInterval = () => {
    const { intervalId } = this.state
    clearInterval(intervalId)
    this.setState({ intervalId: undefined })
  }

  getInfoBar = (): Element<typeof Toolbar> | null => {
    const {
      realEstateName,
      classes,
      t,
      estimateName,
      disabled,
      licenseType,
      indexNumberOnAssessmentDate
    } = this.props

    const appOpen = appTypeByName(this.props.application)

    return (
      <Toolbar
        classes={{ root: classes.subHeader }}>
        <div className={classes.subLeft}>
          <div
            data-testid='moduleHeader-realEstateName'
            className={classes.realEstateName}>
            {realEstateName}
          </div>
          {appOpen === SPACES &&
          <div
            role='button'
            data-testid='moduleHeader-calculationLink'
            tabIndex={0}
            className={combineStyleClassNames(classes.estimateName, disabled && classes.disabled)}
            onClick={() => this.setState({ openApp: !this.state.openApp })}>
            {estimateName}
          </div>
          }
          <div className={classes.chipsContainer}>
            { indexNumberOnAssessmentDate !== undefined &&
            <Chip type='index' t={t} label={indexNumberOnAssessmentDate} />
          }
            {getIsFeatureEnabledInSet(FEATURE_LICENCE_TYPE_CHIP) &&
            <Chip type={licenseType} t={t} />
          }
          </div>
          {this.state.isLockedStatus &&
            <div data-testid='moduleHeader-lock' className={classes.lockedTextWrapper}>
              <SVGIconAlertLockedFilled />
              <OverflowTooltip tooltipText={t('locks._NO_EDIT_SOMEONE_IS_USING_THE_ESTIMATE_')}>
                {t('locks._NO_EDIT_SOMEONE_IS_USING_THE_ESTIMATE_')}
              </OverflowTooltip>
            </div>
          }
        </div>
      </Toolbar>
    )
  }

  render(): Node {
    const appOpen = SPACES
    const { classes } = this.props
    return (
      <AppBar classes={{ root: classes.widgetHeader }}>
        {this.getInfoBar()}
        {
          this.state.openApp &&
          <CalculationManagerModal
            closeModal={() => this.setState({ openApp: false })}
            app={appOpen}
            onClose={() => this.setState({ openApp: false })} />
        }
      </AppBar>
    )
  }
}

const mapStateToProps = ({ app, user }: Object): MappedProps => {
  const {
    application,
    estimateName,
    activeEdit,
    isEstimateLockedToCurrentUser,
    realEstateName,
    licenseType,
    indexNumberOnAssessmentDate
  } = app
  const { claims: { userId } } = user

  return {
    application,
    estimateName,
    disabled: activeEdit,
    isEstimateLockedToCurrentUser,
    realEstateName,
    userId,
    licenseType,
    indexNumberOnAssessmentDate
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps),
  withTranslation('translations'),
  withStyles(styles)
)(ModuleHeader)
